import React from 'react';
import {
    Box,
    Button, Card, CardContent, CardHeader,
    Chip, Grid, List, ListItem, ListItemText,
    Paper, Typography,
} from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {helper} from "../../../utils/helper";
import Constants from "../../../utils/constants";
import './InterventionView.css'
import '../layout/PhotoCarousel.css'
import PhotoCarousel from "../layout/PhotoCarousel";
import BadgeTypeLavage from "../layout/BadgeTypeLavage.js";

function InterventionView({intervention, conteneur, patrimoine, onPDFClick, ...props}) {
    const [lavageImpossible, setLavageImpossible] = React.useState(!intervention?.isAccessible)
    const [hasMaintenance, setHasMaintenance] = React.useState((intervention?.isAccessible && intervention?.problemes.length > 0))

    const getNombreLaves = () => {
        let data = intervention.nombreLaves + " / ";
        if (patrimoine?.isUnipatrimoine) {
            data += patrimoine?.nombreDeBacs;
        } else {
            data += conteneur?.quantite
        }

        return data
    }

    return (
        <React.Fragment>
            <Card>
                <CardHeader
                    avatar={
                        <Box>
                            <Typography variant="body1">
                                Date d'intervention: {helper.getFormattedDate(intervention.dateEvenement)}
                            </Typography>
                            <Grid container spacing={2} alignItems="center" className={"mt-1"}>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="body1" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                                        Status:
                                        <Chip
                                            label={helper.getInterventionStatus(intervention)}
                                            sx={{ ...helper.getInterventionStatusColor(intervention), ml: 1 }}
                                            size="small"
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="body1" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                                        Prestation:
                                        <BadgeTypeLavage type={intervention.typeLavage} sx={{ ml: 1 }} />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    action={
                        <Button
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => onPDFClick(intervention.id, intervention.dateEvenement)}
                            startIcon={<PictureAsPdfIcon />}
                            sx={{ mt: { xs: 2, sm: 0 } }}
                        >
                            Télécharger la fiche PDF
                        </Button>
                    }
                />
                <CardContent>
                    <Typography>Nombre de bac(s) lavé(s) : {getNombreLaves()}</Typography>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        {lavageImpossible ? (
                            <>
                                <Grid item xs={12} sm={6}>
                                    <Paper sx={{ p: 2 }}>
                                        <Typography variant="h6" className="carousel-label carousel-red">
                                            Raison du lavage impossible
                                        </Typography>
                                        <List>
                                            {intervention.problemes && intervention.problemes.map((p) => (
                                                <ListItem key={p.id}>
                                                    <ListItemText primary={`- ${p.libelle}`} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <PhotoCarousel
                                        photoArray={intervention.photosInaccessible}
                                        label="Photo lavage impossible"
                                        headerStyle="carousel-red"
                                        assetsUrl={`${Constants.API_ASSETS_PHOTO_INTERVENTION}/`}
                                    />
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12} sm={6}>
                                    <PhotoCarousel
                                        photoArray={intervention.photosAvant}
                                        label="Photo avant lavage"
                                        headerStyle="carousel-blue"
                                        assetsUrl={`${Constants.API_ASSETS_PHOTO_INTERVENTION}/`}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <PhotoCarousel
                                        photoArray={intervention.photosApres}
                                        label="Photo après lavage"
                                        headerStyle="carousel-green"
                                        assetsUrl={`${Constants.API_ASSETS_PHOTO_INTERVENTION}/`}
                                    />
                                </Grid>
                            </>
                        )}

                        {hasMaintenance && (
                            <>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="h6" className="carousel-label carousel-orange">
                                        Raison de la maintenance
                                    </Typography>
                                    <List>
                                        {intervention.problemes && intervention.problemes.map((p) => (
                                            <ListItem key={p.id}>
                                                <ListItemText primary={`- ${p.libelle}`} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <PhotoCarousel
                                        photoArray={intervention.photosProblem}
                                        label="Photo maintenance"
                                        headerStyle="carousel-orange"
                                        assetsUrl={`${Constants.API_ASSETS_PHOTO_INTERVENTION}/`}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </CardContent>
            </Card>
        </React.Fragment>
    )
}

export default InterventionView;
