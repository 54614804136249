export const patrimoinesConstants = {
    PATRIMOINE_CREATE_REQUEST: "PATRIMOINE_CREATE_REQUEST",
    PATRIMOINE_CREATE_SUCCESS: "PATRIMOINE_CREATE_SUCCESS",
    PATRIMOINE_CREATE_FAILURE: "PATRIMOINE_CREATE_FAILURE",
    
    PATRIMOINE_UPDATE_REQUEST: "PATRIMOINE_UPDATE_REQUEST",
    PATRIMOINE_UPDATE_SUCCESS: "PATRIMOINE_UPDATE_SUCCESS",
    PATRIMOINE_UPDATE_FAILURE: "PATRIMOINE_UPDATE_FAILURE",
    
    PATRIMOINE_FETCH_REQUEST: "PATRIMOINE_FETCH_REQUEST",
    PATRIMOINE_FETCH_SUCCESS: "PATRIMOINE_FETCH_SUCCESS",
    PATRIMOINE_FETCH_FAILURE: "PATRIMOINE_FETCH_FAILURE",

    PATRIMOINES_FETCH_REQUEST: "PATRIMOINES_FETCH_REQUEST",
    PATRIMOINES_FETCH_SUCCESS: "PATRIMOINES_FETCH_SUCCESS",
    PATRIMOINES_FETCH_FAILURE: "PATRIMOINES_FETCH_FAILURE",

    PATRIMOINE_DELETE_REQUEST: "PATRIMOINES_DELETE_REQUEST",
    PATRIMOINE_DELETE_SUCCESS: "PATRIMOINES_DELETE_SUCCESS",
    PATRIMOINE_DELETE_FAILURE: "PATRIMOINES_DELETE_FAILURE",
}