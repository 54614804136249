import React, {useEffect, useState} from 'react';
import {Chip, Container, Grid, ListItemIcon, Paper, Typography} from "@mui/material";
import {connect} from "react-redux";
import Loader from "./layout/loading/loader";
import {Link, useParams} from "react-router-dom";
import {patrimoinesActions} from "../../modules/patrimoines/patrimoines.actions";
import PatrimoineMap from "./patrimoine/PatrimoineMap";
import PatrimoineDetailItem from "./patrimoine/PatrimoineDetailItem";
import {FolderDelete} from "@mui/icons-material";
import PageTitle from "./layout/PageTitle";
import {helper} from "../../utils/helper";
import Button from "@mui/material/Button";
import MapIcon from '@mui/icons-material/Map';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PatrimoineDetailList from "./patrimoine/PatrimoineDetailList";
import Constants from "../../utils/constants";
import AodIcon from "@mui/icons-material/Aod";


const getMode = () => {
    const storedMode = window.localStorage.getItem("patrimoine_view_mode");
    return storedMode || Constants.MODE_LIST;
};

function PatrimoineDetailPage({dispatch, ...props}) {
    const params = useParams();
    const [patrimoineId, setPatrimoineId] = useState(null);
    const [currentConteneurs, setCurrentConteneurs] = useState(null)
    const [mode, setMode] = useState(getMode())


    useEffect(() => {
        if (params.hasOwnProperty("id")) {
            setPatrimoineId(params.id);
        }

        return () => {
            setPatrimoineId(null);
        };
    }, [params]);

    useEffect(() => {
        if (patrimoineId && (props.patrimoine?.id !== parseInt(patrimoineId)) && !props.loading && !props.error) {
            dispatch(patrimoinesActions.fetchPatrimoine(patrimoineId));
        }
    }, [dispatch, patrimoineId, props.patrimoine, props.loading, props.error]);

    const handleModeChange = (value) => {
        window.localStorage.setItem("patrimoine_view_mode", value);
        setMode(value)
    }

    if (props.loading || !props.patrimoine) {
        return <Loader />;
    }

    return (
        <>
            <PageTitle title={`Patrimoine ${props.patrimoine?.libelle}`} icon={<FolderDelete/>}/>
            <Grid container justifyContent={"center"} spacing={2} className={"mb-2"}>
                <Grid item>
                    <Button
                        variant={getMode() === Constants.MODE_LIST ? "contained" : "outlined"}
                        color="primary"
                        startIcon={<FormatListNumberedIcon/>}
                        onClick={() => handleModeChange(Constants.MODE_LIST)}
                    >
                        Affichage liste
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant={getMode() === Constants.MODE_MAP ? "contained" : "outlined"}
                        color="primary"
                        startIcon={<MapIcon/>}
                        onClick={() => handleModeChange(Constants.MODE_MAP)}
                    >
                        Affichage carte
                    </Button>
                </Grid>
            </Grid>
            <Grid container>
                {mode === Constants.MODE_MAP &&
                    <>
                        <Grid item xs={8}>
                            <PatrimoineMap conteneurs={props.patrimoine?.conteneurs}
                                           patrimoine={props.patrimoine}
                                           onConteneurClick={(c) => setCurrentConteneurs(c)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container>
                                <Paper className={"conteneur-detail-container"}>
                                    <Grid item container>
                                        <Grid item xs={12}>
                                            <Typography variant={'h5'}>{props.patrimoine?.libelle}</Typography>
                                        </Grid>
                                        {props.patrimoine?.isUnipatrimoine &&
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography variant={'body2'}>{props.patrimoine?.lieu}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={3}>
                                                            <img
                                                                src={helper.getConteneurStatusIcon(props.patrimoine?.status)}/>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Grid container>
                                                                <Grid item xs={12} sx={{padding: '.5rem'}}>
                                                                    Status
                                                                    : {helper.getConteneurStatusName(props.patrimoine?.status)} ({props.patrimoine?.nombreDeBacs} bacs)
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Link
                                                                        to={Constants.APP_ROUTE_INTERVENTIONS_UNIPATRIMOINE.replace(":id", props.patrimoine?.id)}>
                                                                        <Button color="primary" size="small" variant={"contained"}
                                                                                startIcon={<AodIcon/>}>
                                                                            Rapport d'intervention
                                                                        </Button>
                                                                    </Link>
                                                                    {/*}*/}

                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        }

                                        {currentConteneurs && currentConteneurs.map((c, i) => (
                                            <React.Fragment key={i}>
                                                <PatrimoineDetailItem conteneur={c}/>
                                            </React.Fragment>
                                        ))}

                                    </Grid>
                                </Paper>


                            </Grid>
                        </Grid>
                    </>
                }
                {mode === Constants.MODE_LIST &&
                    <>
                        <Grid item xs={12}>
                            <PatrimoineDetailList conteneurs={props.patrimoine?.conteneurs} patrimoine={props.patrimoine}/>
                        </Grid>
                    </>
                }
            </Grid>

        </>
    )
}

const mapStateToProps = function (state) {
    return {
        patrimoine: state.patrimoines.patrimoine,
        loading: state.patrimoines.loading,
        error: state.patrimoines.error
    }
}

export default connect(mapStateToProps)(PatrimoineDetailPage);
