import React from 'react';
import {Field} from 'formik';
import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import Constants from "../../../../utils/constants";


function ProblemDataField({...props}) {
    const {values, errors, handleChange, handleBlur} = props;


    return (
        <Grid container spacing={1} className={"sectionContainer"}>
            <Grid item xs={12}>
                <Typography variant={"h5"} className={"sectionHeader"}>Informations</Typography>
            </Grid>

            {/*LIBELLE FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Libellé <span className="asterix">*</span>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        value={values.libelle}>
                        {({field, form}) => (
                            <TextField
                                error={
                                    Boolean(form.errors.libelle && form.touched.libelle)
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.libelle}
                                variant="outlined"
                                margin="normal"
                                size="small"
                                fullWidth
                                name="libelle"
                                label="Libellé"
                                autoFocus
                                helperText={
                                    form.errors.libelle &&
                                    form.touched.libelle &&
                                    String(form.errors.libelle)
                                }/>
                        )}
                    </Field>
                </Grid>
            </Grid>

            {/*IMPORTANCE FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Importance (sévérité) <span className="asterix">*</span>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        value={values.importance}>
                        {({field, form}) => (
                            <ToggleButtonGroup
                                color={"primary"}
                                exclusive
                                size={"medium"}
                                name="importance"
                                value={values.importance}
                                onChange={(event, newValue) => {
                                    if (newValue !== null) {
                                        form.setFieldValue('importance', newValue);
                                    }
                                }}
                                aria-label={"Importance"}
                            >
                                {Constants.PROBLEMS_IMPORTANCE && Constants.PROBLEMS_IMPORTANCE.map((importance, i) => {
                                    const isSelected = values.importance === importance.code;

                                    const buttonStyles = {
                                        backgroundColor: isSelected ? importance.color : 'transparent',
                                        color: isSelected ? 'white' : 'black'
                                    }

                                    return (
                                        <ToggleButton key={i} value={importance.code} style={buttonStyles}>
                                            {importance.libelle}
                                        </ToggleButton>
                                    )
                                })}
                                {form.errors.importance &&
                                    form.touched.importance &&
                                    <FormHelperText error={true}>{form.errors.importance}</FormHelperText>}
                            </ToggleButtonGroup>

                        )}
                    </Field>

                </Grid>
            </Grid>

            {/*CONTENEUR TYPE FIELD*/}
            <Grid item container alignItems={"center"} className={"mt-2"}>
                <Grid item xs={3}>
                    Type de conteneur <span className="asterix">*</span>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        name="exploitant"
                        value={values.conteneurType}>
                        {({field, form}) => (
                            <FormControl fullWidth error={Boolean(errors.conteneurType)}>
                                <InputLabel id="label-exploitant">Sélectionner le type de conteneur :</InputLabel>
                                <Select
                                    labelId="label-exploitant"
                                    label="Sélectionner le type de conteneur :"
                                    variant="outlined"
                                    name="conteneurType"
                                    value={values.conteneurType}
                                    onChange={handleChange}
                                >
                                    {Constants.CONTENEURS_TYPE && Constants.CONTENEURS_TYPE.map((ct) => {
                                        return (
                                            <MenuItem key={ct.code} value={ct.code}>
                                                {ct.libelle}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                                {form.errors.conteneurType &&
                                    form.touched.conteneurType &&
                                    <FormHelperText error={true}>{form.errors.conteneurType}</FormHelperText>}
                            </FormControl>
                        )
                        }
                    </Field>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ProblemDataField;
