import {formsConstants} from './forms.constants';
import {authenticationConstants} from "../authentication/auth.constants";

const initialState = {forms: null, form: null, loading: false, error: null}

export function forms(state = initialState, action) {
    switch (action.type) {
        case formsConstants.FORMS_FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case formsConstants.FORMS_FETCH_SUCCESS:
            return {
                ...state,
                forms: action.forms,
                form: null,
                loading: false,
                error: null
            };
        case formsConstants.FORMS_FETCH_ONE_REQUEST:
            return {
                ...state,
                form: null,
                loading: true,
                error: null
            };
        case formsConstants.FORMS_FETCH_ONE_SUCCESS:
            return {
                ...state,
                form: action.form,
                forms: null,
                loading: false,
                error: null
            };
        case formsConstants.FORMS_FETCH_ONE_FAILURE:
            return {
                ...state,
                form: null,
                loading: false,
                error: action.error
            };
        case formsConstants.FORMS_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case formsConstants.FORMS_DELETE_SUCCESS:
        case formsConstants.FORMS_CREATE_SUCCESS:
        case formsConstants.FORMS_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                forms: null,
                form: null
            };
        case formsConstants.FORMS_FETCH_FAILURE:
        case formsConstants.FORMS_CREATE_FAILURE:
        case formsConstants.FORMS_UPDATE_FAILURE:
        case formsConstants.FORMS_DELETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case formsConstants.FORMS_CLEAR_REQUEST:
            return {
                ...initialState
            }
        case authenticationConstants.LOGOUT:
            return {
                initialState
            }
        default:
            return state
    }
}
