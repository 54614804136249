import React, {useState} from 'react';
import {useFormikContext} from 'formik';
import {
    Card, Chip,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";


function CampaignOrderField({...props}) {
    const {values, errors, patrimoines, handleChange, handleBlur} = props;
    const {setFieldValue} = useFormikContext();

    const findPatrimoineObject = (patrimoineId) => {
        if (patrimoineId && patrimoines) {
            return patrimoines?.find((patrimoine) => patrimoine['@id'] === patrimoineId);
        }
        return null;
    }

    const findContainersByIri = (iris) => {
        if (selectedPatrimoine && selectedPatrimoine.conteneurs) {
            let containers = [];
            for (let iri of iris) {
                const container = selectedPatrimoine.conteneurs.find((c) => c['@id'] === iri);
                if (container) {
                    containers.push(container);
                }
            }

            if (values.ordre?.length > 0 && values.ordre.length === containers.length) {
                containers.sort((a, b) => {
                    const indexA = values.ordre.indexOf(a.id);
                    const indexB = values.ordre.indexOf(b.id);
                    return indexA - indexB;
                });
            } else {
                containers.sort((a, b) => {
                    return parseInt(a.ordreCsv) - parseInt(b.ordreCsv);
                });
            }
            return containers;
        }
        return [];
    }


    const [selectedPatrimoine, setSelectedPatrimoine] = useState(findPatrimoineObject(values.patrimoine))
    const [selectedConteneurs, setSelectedConteneurs] = useState(values.conteneurs.length > 0 ? findContainersByIri(values.conteneurs) : [])

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const reorderedContainers = reorder(
            selectedConteneurs,
            result.source.index,
            result.destination.index
        );

        const reorderedIds = reorderedContainers.map(c => c.id);

        setFieldValue("ordre", reorderedIds);

        setSelectedConteneurs(reorderedContainers);
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const getCardStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        padding: "16px",
        margin: "0 0 8px 0",
        background: isDragging ? "var(--thm-base)" : "white",
        borderRadius: "4px",
        paddingLeft: '2rem',
        boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        ...draggableStyle
    });

    const getChipStyle = (isDragging) => {
        return {
            background: isDragging ? "var(--thm-black)" : "var(--thm-black)",
            color: "white",
            marginRight: "1rem"
        }
    }

    return (
        <Grid container spacing={1} className={"sectionContainer"}>
            <Grid item xs={12}>
                <Typography variant={"h5"} className={"sectionHeader"}>Gestion de l'ordre de la tournée</Typography>
            </Grid>

            <Grid item container className={"mt-2"}>
                <Grid item xs={12}>
                    {selectedPatrimoine &&
                        <Typography sx={{fontStyle: 'italic', textAlign: 'center'}}>
                            {selectedPatrimoine.isUnipatrimoine ? "L'ordre de la tournée n'est pas disponible pour un unipatrimoine" : "Ajustez l'ordre de la tournée en glissant/déposant les éléments dans la liste"}
                        </Typography>
                    }
                </Grid>
                {selectedPatrimoine &&
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <Grid item container xs={12}
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                >
                                    {selectedConteneurs.map((c, i) => {
                                            return (
                                                <Grid item xs={12}>
                                                    <Draggable key={c['@id'] ?? c.id} draggableId={c['@id'] ?? c.id}
                                                               index={i}>
                                                        {(provided, snapshot) => (
                                                            <Card
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getCardStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}
                                                            >
                                                                <Chip label={"Point numéro " + (i + 1)}
                                                                      style={getChipStyle(snapshot.isDragging)}/>
                                                                {c?.nomenclature}
                                                                &nbsp;-&nbsp;
                                                                {c?.adresse} - {c?.commune}
                                                            </Card>
                                                        )}
                                                    </Draggable>
                                                </Grid>
                                            )
                                        }
                                    )}
                                    {provided.placeholder}
                                </Grid>
                            )}
                        </Droppable>
                    </DragDropContext>
                }
            </Grid>
        </Grid>
    );
}

export default CampaignOrderField;
