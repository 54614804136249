import {TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import React from "react";


function TableHeader({...props}) {
    const {headCells, order, orderBy, onRequestSort, noAction = false} = props;
    const createSortHandler = (property) => (event) => {
        if (onRequestSort) {
            onRequestSort(event, property);
        }
    };

    return (
      <TableHead>
          <TableRow className={"tableHeader-row"}>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                  className={headCell.className}
                >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                        {headCell.label}
                    </TableSortLabel>
                </TableCell>
              ))}
              {!noAction &&
                  <TableCell align="center" className="tableCell-md">Actions</TableCell>
              }
          </TableRow>
      </TableHead>
    )
}

export default TableHeader;
