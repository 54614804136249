export const formsConstants = {
    FORMS_FETCH_REQUEST: "FORMS_FETCH_REQUEST",
    FORMS_FETCH_SUCCESS: "FORMS_FETCH_SUCCESS",
    FORMS_FETCH_FAILURE: "FORMS_FETCH_FAILURE",

    FORMS_FETCH_ONE_REQUEST: "FORMS_FETCH_ONE_REQUEST",
    FORMS_FETCH_ONE_SUCCESS: "FORMS_FETCH_ONE_SUCCESS",
    FORMS_FETCH_ONE_FAILURE: "FORMS_FETCH_ONE_FAILURE",

    FORMS_CREATE_REQUEST: "FORMS_CREATE_REQUEST",
    FORMS_CREATE_SUCCESS: "FORMS_CREATE_SUCCESS",
    FORMS_CREATE_FAILURE: "FORMS_CREATE_FAILURE",

    FORMS_UPDATE_REQUEST: "FORMS_UPDATE_REQUEST",
    FORMS_UPDATE_SUCCESS: "FORMS_UPDATE_SUCCESS",
    FORMS_UPDATE_FAILURE: "FORMS_UPDATE_FAILURE",

    FORMS_DELETE_REQUEST: "FORMS_DELETE_REQUEST",
    FORMS_DELETE_SUCCESS: "FORMS_DELETE_SUCCESS",
    FORMS_DELETE_FAILURE: "FORMS_DELETE_FAILURE",



    FORMS_CLEAR_REQUEST: "FORMS_CLEAR_REQUEST",

};
