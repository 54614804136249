import React, {useState} from 'react';
import {
    Avatar,
    Button,
    Chip,
    FormControl,
    Grid,
    Input,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer, TablePagination,
    TableRow,
} from "@mui/material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import "./UsersTable.css"
import TableHeader from "../layout/table/TableHeader";
import {getRole} from "../../../utils/roles";
import {SearchOutlined} from "@mui/icons-material";
import Constants from "../../../utils/constants";
import {useNavigate} from "react-router-dom";
import ModalDelete from "../layout/ModalDelete";
import {helper} from "../../../utils/helper";

function UsersTable({...props}) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState(props.users)
    const [currentUser, setCurrentUser] = useState(false);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [label, setLabel] = useState("")
    const [objectToDelete, setObjectToDelete] = useState(null)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);

    const openDeleteConfirmModal = (u) => {
        setCurrentUser(u)
        setLabel("Etes vous sûr de vouloir supprimer l'utilisateur : ")
        setObjectToDelete(u.email)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentUser(null)
    };

    const handleEditClick = (type, id) => {
        navigate(Constants.APP_ROUTE_ADMIN_USERS_EDIT.replace(":type", type).replace(':id', id))
    }

    const handleDeleteUser = () => {
        props.onDelete(currentUser)
        handleClose()
    }

    // Table head cells
    const headCells = [
        {id: 'role', label: 'Role'},
        {id: 'libelle', label: 'Libellé'},
        {id: 'logo', label: 'Logo'},
        {id: 'email', label: 'Email'},
        {id: 'typologie', label: 'Informations'},
        {id: 'accredited', label: 'Accréditation', className: "th-small"},
    ];

    // filter and sort
    const filterTable = (e) => {
        let value = e.target.value.toLowerCase();
        setFilteredUsers(props.users.filter((user) => {
            return (user.libelle.toLowerCase().indexOf(value) !== -1 ||
                user.email.toLowerCase().indexOf(value) !== -1 ||
                user.roles.some((role) => role.toLowerCase().indexOf(value) !== -1)
            );
        }))
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedData = () => {
        return filteredUsers.sort((a, b) => {
            if (order === 'asc') {
                return (getItemComparator(a, orderBy) > getItemComparator(b, orderBy)) ? 1 : (getItemComparator(b, orderBy) > getItemComparator(a, orderBy)) ? -1 : 0;
            }
            return (getItemComparator(a, orderBy) < getItemComparator(b, orderBy)) ? 1 : (getItemComparator(b, orderBy) < getItemComparator(a, orderBy)) ? -1 : 0
        });
    }

    /**
     * Handle field property for comparator.
     * @param item
     * @param comparator
     * @returns {*}
     */
    const getItemComparator = (item, comparator) => {
        if (comparator === "accredited") {
            return item.isAccredited;
        }
        if (comparator === "role") {
            return item.roles
        }
        return item[comparator];
    }

    const canDeleteUser = (user) => {
        return !user.roles?.includes("ROLE_ADMIN");
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getRowsNumber = () => {
        let totalUtilisateurs = props.users?.length;
        let totalOperateurs = 0;
        let totalClients = 0;

        props.users?.forEach(user => {
            totalOperateurs += user.operateurs?.length ?? 0;
            totalClients += user.clients?.length ?? 0;
        });

        return (totalUtilisateurs + totalOperateurs + totalClients) ?? 0
    }


    return (
        <React.Fragment>
            <FormControl className="search-container">
                <Input
                    id="search-table"
                    placeholder={"Rechercher..."}
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchOutlined/>
                        </InputAdornment>
                    }
                    onChange={filterTable}
                />
            </FormControl>
            <TableContainer component={Paper} className={"mt-2"}>
                <Table aria-label="users tables" size="small">
                    <TableHeader headCells={headCells} order={order} orderBy={orderBy}
                                 onRequestSort={handleRequestSort}/>
                    <TableBody>
                        {filteredUsers && sortedData()
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((user) => {
                                return (
                                    <React.Fragment key={user.id}>
                                        <TableRow hover className={"tableRow-level1"}
                                                  onClick={() => handleEditClick("user", user.id)}
                                                  style={{cursor: 'pointer'}}>
                                            <TableCell>
                                                <Chip label={getRole(user.roles)}
                                                      className={"chip-role-" + getRole(user.roles)}/>
                                            </TableCell>
                                            <TableCell component="th">
                                                {user.libelle}
                                            </TableCell>
                                            <TableCell className={"tableCell-logo"}>
                                                <Avatar src={Constants.API_ASSETS_USER_LOGO + user.logo?.contentUrl}/>
                                            </TableCell>

                                            <TableCell>{user.email}</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell>
                                                {user.isAccredited ? <Button size="small" disabled
                                                                             className="toggle-button-small button-selected-green">Oui</Button> :
                                                    <Button disabled
                                                            className="toggle-button-small button-selected-red">Non</Button>}
                                            </TableCell>
                                            <TableCell>
                                                <Grid container justify={"flex-end"} spacing={2}>
                                                    <Grid item>
                                                        <Button color="secondary" size="small"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleEditClick("user", user.id)
                                                                }}
                                                                startIcon={<ModeEditOutlineOutlinedIcon/>}>
                                                            Editer
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button color="error" size="small"
                                                                disabled={!canDeleteUser(user)}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    openDeleteConfirmModal(user)
                                                                }}
                                                                startIcon={<CloseOutlinedIcon/>}>
                                                            Supprimer
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                        {user.clients && user.clients.map((client) => {
                                            return (
                                                <React.Fragment key={client.id}>
                                                    <TableRow hover className="table-level-2"
                                                              onClick={() => handleEditClick("client", client.id)}
                                                              style={{cursor: 'pointer'}}>
                                                        <TableCell>
                                                            <Chip label={getRole(client.roles)}
                                                                  className={"chip-role-" + getRole(client.roles)}/>
                                                        </TableCell>

                                                        <TableCell component="th">
                                                            {client.libelle}
                                                        </TableCell>

                                                        <TableCell className={"tableCell-logo"}>
                                                            <Avatar
                                                                src={Constants.API_ASSETS_USER_LOGO + client.logo?.contentUrl}/>
                                                        </TableCell>
                                                        <TableCell>{client.email}</TableCell>
                                                        <TableCell>
                                                            Typologie &nbsp;
                                                            {client.typologieConteneurs &&
                                                                client.typologieConteneurs.map((type, index) => (
                                                                    <Chip size={'small'} label={type} key={index} sx={{
                                                                        marginRight: "1rem"
                                                                    }}/>
                                                                ))
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {client.isAccredited ? <Button size="small" disabled
                                                                                           className="toggle-button-small button-selected-green">Oui</Button> :
                                                                <Button disabled
                                                                        className="toggle-button-small button-selected-red">Non</Button>}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Grid container justify={"flex-end"} spacing={2}>
                                                                <Grid item>
                                                                    <Button color="secondary" size="small"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                handleEditClick("client", client.id)
                                                                            }}
                                                                            startIcon={<ModeEditOutlineOutlinedIcon/>}>
                                                                        Editer
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Button color="error" size="small"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                openDeleteConfirmModal(client)
                                                                            }}
                                                                            startIcon={<CloseOutlinedIcon/>}>
                                                                        Supprimer
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            )
                                        })}
                                        {user.operateurs && user.operateurs.map((operateur) => {
                                            return (
                                                <React.Fragment key={operateur.id}>
                                                    <TableRow hover className="table-level-2"
                                                              onClick={() => handleEditClick("operateur", operateur.id)}
                                                              style={{cursor: 'pointer'}}>
                                                        <TableCell>
                                                            <Chip label={getRole(operateur.roles)}
                                                                  className={"chip-role-" + getRole(operateur.roles)}/>
                                                        </TableCell>
                                                        <TableCell component="th">
                                                            {operateur.libelle}
                                                        </TableCell>
                                                        <TableCell className={"tableCell-logo"}>
                                                            <Avatar
                                                                src={Constants.API_ASSETS_USER_LOGO + operateur.logo?.contentUrl}/>
                                                        </TableCell>
                                                        <TableCell>{operateur.email}</TableCell>
                                                        <TableCell>
                                                            {operateur.laveuse &&
                                                                "Laveuse " + operateur.laveuse?.immatriculation
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {operateur.isAccredited ? <Button size="small" disabled
                                                                                              className="toggle-button-small button-selected-green">Oui</Button> :
                                                                <Button disabled
                                                                        className="toggle-button-small button-selected-red">Non</Button>}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Grid container justify={"flex-end"} spacing={2}>
                                                                <Grid item>
                                                                    <Button color="secondary" size="small"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                handleEditClick("operateur", operateur.id)
                                                                            }}
                                                                            startIcon={<ModeEditOutlineOutlinedIcon/>}>
                                                                        Editer
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Button color="error" size="small"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                openDeleteConfirmModal(operateur)
                                                                            }}
                                                                            startIcon={<CloseOutlinedIcon/>}>
                                                                        Supprimer
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            )
                                        })}
                                    </React.Fragment>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[15, 25, 50]}
                component="div"
                count={getRowsNumber()}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={"Nombre de lignes :"}
                labelDisplayedRows={({from, to, count, page}) => {
                    return `${from} - ${to} / ${count !== -1 ? count : `Plus de ${to}`}`;
                }}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <ModalDelete label={label} onClose={handleClose} open={open} objectToDelete={objectToDelete}
                         selectedValue={currentUser} onDelete={handleDeleteUser}/>
        </React.Fragment>
    )
}

export default UsersTable;
