import {problemsConstants} from './problems.constants';
import {authenticationConstants} from "../authentication/auth.constants";

const initialState = {problems: null, problem: null, loading: false, error: null}

export function problems(state = initialState, action) {
    switch (action.type) {
        case problemsConstants.PROBLEMS_FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case problemsConstants.PROBLEMS_FETCH_SUCCESS:
            return {
                ...state,
                problems: action.problems,
                problem: null,
                loading: false,
                error: null
            };
        case problemsConstants.PROBLEMS_FETCH_ONE_REQUEST:
            return {
                ...state,
                problem: null,
                loading: true,
                error: null
            };
        case problemsConstants.PROBLEMS_FETCH_ONE_SUCCESS:
            return {
                ...state,
                problem: action.problem,
                problems: null,
                loading: false,
                error: null
            };
        case problemsConstants.PROBLEMS_FETCH_ONE_FAILURE:
            return {
                ...state,
                problem: null,
                loading: false,
                error: action.error
            };
        case problemsConstants.PROBLEMS_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case problemsConstants.PROBLEMS_DELETE_SUCCESS:
        case problemsConstants.PROBLEMS_CREATE_SUCCESS:
        case problemsConstants.PROBLEMS_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                problems: null,
                problem: null
            };
        case problemsConstants.PROBLEMS_FETCH_FAILURE:
        case problemsConstants.PROBLEMS_CREATE_FAILURE:
        case problemsConstants.PROBLEMS_UPDATE_FAILURE:
        case problemsConstants.PROBLEMS_DELETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case problemsConstants.PROBLEMS_CLEAR_REQUEST:
            return {
               ...initialState
            }
        case authenticationConstants.LOGOUT:
            return {
                initialState
            }
        default:
            return state
    }
}
