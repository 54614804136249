import React from 'react';
import './Footer.css';
import {useNavigate} from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    Avatar,
    Box, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid, Menu, MenuItem,
    Typography
} from "@mui/material";
import {useLocation} from 'react-router-dom';
import {helper} from "../../../utils/helper";
import Constants from "../../../utils/constants";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import {authActions} from "../../../modules/authentication/auth.actions";
import {useDispatch} from "react-redux";

function ContentHeader() {
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const userMenuOpen = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorEl(null);
    };

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const currentUser = helper.getCurrentUser();

    const isHomePage = location.pathname === '/';

    const userHasLogo = () => {
        return currentUser?.logo?.hasOwnProperty("filePath");
    }

    const openLogoutModal = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false)
    }
    const logout = () => {
        dispatch(authActions.logout()).then(() => {
            navigate(Constants.APP_ROUTE_LOGIN)
        })
    }

    return (
        <Grid container justifyContent={"space-between"} alignItems={"center"} className={"content-header"}>
            <Grid item xs={10}>
                {!isHomePage &&
                    <Box display="flex" alignItems="center" sx={{cursor: 'pointer'}} onClick={() => {
                        navigate(-1)
                    }}>
                        <ArrowBackIcon/>
                        <Box ml={1} className={"navigation-back-label"}>Retour à la page précédente</Box>
                    </Box>
                }
                {isHomePage &&
                    <Box display="flex" alignItems="center">
                        <Typography variant={"h4"}
                                    className={"navigation-back-label"}>Bonjour {currentUser?.libelle || currentUser?.username}</Typography>
                    </Box>
                }
            </Grid>

            <Grid item xs={2} container justifyContent={"end"} flexDirection={"row"}>
                <Button
                    sx={{padding: 0}}
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                    {userHasLogo() &&
                        <img src={Constants.API_ASSETS_USER_LOGO + "/users/logo/" + currentUser?.logo?.filePath}
                             className={"content-header-logo"}/>
                        // <Avatar src={Constants.API_ASSETS_USER_LOGO + "/users/logo/" + currentUser.logo?.filePath} variant={"rounded"} sx={{ width: 64, height: 64 }}/>
                    }
                    {!userHasLogo() &&
                        <Avatar></Avatar>
                    }
                </Button>
                <Button variant="filled" color="primary" className="logout-button"
                        endIcon={<LogoutIcon/>} onClick={openLogoutModal}>
                </Button>
                <Menu
                    open={userMenuOpen}
                    anchorEl={anchorEl}
                    onClose={handleCloseUserMenu}
                >
                    <MenuItem onClick={handleCloseUserMenu}><Typography
                        variant={"body2"}>{currentUser?.libelle}</Typography></MenuItem>
                    <MenuItem onClick={handleCloseUserMenu}><Typography
                        variant={"caption"}>{currentUser?.email}</Typography></MenuItem>
                    <MenuItem>
                        <Button variant="outlined" color="primary" fullWidth className="logout-button"
                                endIcon={<LogoutIcon/>} onClick={openLogoutModal}>
                            Déconnexion
                        </Button>
                    </MenuItem>
                </Menu>
            </Grid>
            <Dialog
                className={"modalLogout"}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{minWidth: "450px"}}>
                    Déconnexion
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Vous allez être déconnecté, continuer ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={"error"} variant={"contained"}>Annuler</Button>
                    <Button onClick={logout} color={"success"} variant={"contained"}>
                        Me déconnecter
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

export default ContentHeader;
