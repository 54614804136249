import {patrimoinesConstants} from "./patrimoines.constants";
import {authenticationConstants} from "../authentication/auth.constants";

const initialState = {loading: null, error: null, patrimoine: null, patrimoines: null}

export function patrimoines(state = initialState, action) {
    switch (action.type) {
        case patrimoinesConstants.PATRIMOINE_CREATE_REQUEST:
        case patrimoinesConstants.PATRIMOINE_UPDATE_REQUEST:
        case patrimoinesConstants.PATRIMOINE_FETCH_REQUEST:
        case patrimoinesConstants.PATRIMOINES_FETCH_REQUEST:
        case patrimoinesConstants.PATRIMOINE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case patrimoinesConstants.PATRIMOINE_CREATE_SUCCESS:
        case patrimoinesConstants.PATRIMOINE_UPDATE_SUCCESS:
        case patrimoinesConstants.PATRIMOINE_DELETE_SUCCESS:
            return {
                ...state,
                patrimoine: null,
                patrimoines: null,
                loading: false,
                error: null
            }
        case patrimoinesConstants.PATRIMOINE_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                patrimoine: action.patrimoine
            }
        case patrimoinesConstants.PATRIMOINES_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                patrimoines: action.patrimoines
            }
        case patrimoinesConstants.PATRIMOINE_CREATE_FAILURE:
        case patrimoinesConstants.PATRIMOINE_UPDATE_FAILURE:
        case patrimoinesConstants.PATRIMOINE_FETCH_FAILURE:
        case patrimoinesConstants.PATRIMOINE_DELETE_FAILURE:
        case patrimoinesConstants.PATRIMOINES_FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case authenticationConstants.LOGOUT:
            return {
                initialState
            }
        default:
            return {...state}
    }
}