export const interventionsConstants = {
    INTERVENTIONS_FETCH_REQUEST: "INTERVENTIONS_FETCH_REQUEST",
    INTERVENTIONS_FETCH_SUCCESS: "INTERVENTIONS_FETCH_SUCCESS",
    INTERVENTIONS_FETCH_FAILURE: "INTERVENTIONS_FETCH_FAILURE",

    INTERVENTIONS_FETCH_ONE_REQUEST: "INTERVENTIONS_FETCH_ONE_REQUEST",
    INTERVENTIONS_FETCH_ONE_SUCCESS: "INTERVENTIONS_FETCH_ONE_SUCCESS",
    INTERVENTIONS_FETCH_ONE_FAILURE: "INTERVENTIONS_FETCH_ONE_FAILURE",

    INTERVENTIONS_CREATE_REQUEST: "INTERVENTIONS_CREATE_REQUEST",
    INTERVENTIONS_CREATE_SUCCESS: "INTERVENTIONS_CREATE_SUCCESS",
    INTERVENTIONS_CREATE_FAILURE: "INTERVENTIONS_CREATE_FAILURE",

    INTERVENTIONS_UPDATE_REQUEST: "INTERVENTIONS_UPDATE_REQUEST",
    INTERVENTIONS_UPDATE_SUCCESS: "INTERVENTIONS_UPDATE_SUCCESS",
    INTERVENTIONS_UPDATE_FAILURE: "INTERVENTIONS_UPDATE_FAILURE",

    INTERVENTIONS_DELETE_REQUEST: "INTERVENTIONS_DELETE_REQUEST",
    INTERVENTIONS_DELETE_SUCCESS: "INTERVENTIONS_DELETE_SUCCESS",
    INTERVENTIONS_DELETE_FAILURE: "INTERVENTIONS_DELETE_FAILURE",



    INTERVENTIONS_CLEAR_REQUEST: "INTERVENTIONS_CLEAR_REQUEST",

};
