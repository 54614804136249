import React from 'react';
import {Typography} from "@mui/material";

function NoDataLabel({label, ...props}) {
    return (
        <Typography className={"nodata-label"}>{label}</Typography>
    );
}

export default NoDataLabel;
