import React, {useEffect} from 'react';
import {Field} from 'formik';
import {
    FormControl, FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem, Radio, RadioGroup,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {RRuleHelper} from "../../../../utils/RRuleHelper";
import {parseISO, format} from "date-fns";
import {helper} from "../../../../utils/helper";


function CampaignFrequencyField({...props}) {
    const {values, errors, handleChange, handleBlur} = props;
    const [dates, setDates] = React.useState([])
    const getIntervalLabel = () => {
        if (values.freq === RRuleHelper.FREQ[0].id) return " semaine(s)";
        if (values.freq === RRuleHelper.FREQ[1].id) return " mois(s)";
    }

    const isWeekly = () => {
        return values.freq === RRuleHelper.FREQ[0].id;
    }
    const isMonthly = () => {
        return values.freq === RRuleHelper.FREQ[1].id;
    }

    useEffect(() => {
        if (values.dateDebut && values.rrule) {
            let occurences = RRuleHelper.getNextOccurences(values.dateDebut, values.rrule)
            setDates(occurences)
        }
    }, [values])

    const getOccurences = () => {
        if (values) {
            let rrule = RRuleHelper.buildRRuleString(values)
            return RRuleHelper.getNextOccurences(values.dateDebut, rrule)
        }
        return []
    }

    return (
        <>
            {/*DATE DEBUT FIELD*/}
            <Grid item container alignItems={"center"} className={'mt-1 mb-1'}>
                <Grid item xs={3}>
                    Début d'affichage de la tournée <span className="asterix">*</span>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        name={"dateDebut"}
                        value={values.dateDebut}>
                        {({field, form}) => (
                            <FormControl fullWidth
                                         errors={errors.dateDebut}>
                                <DatePicker value={parseISO(values.dateDebut)}
                                            onChange={(value) => {
                                                form.setFieldValue(field.name, format(value, 'yyyy-MM-dd'));
                                            }}
                                />
                            </FormControl>
                        )}
                    </Field>
                </Grid>
            </Grid>

            {/* FREQUENCE RECUREENCE FIELD*/}
            <Grid item container alignItems={"center"} className={""}>
                <Grid item xs={3}>
                    <Typography>Sélectionner la fréquence *</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        name={"freq"}
                        value={values.freq}>
                        {({form, push, remove}) => (
                            <FormControl fullWidth error={Boolean(errors.freq)}>
                                <InputLabel id="label-client">Sélectionner la fréquence :</InputLabel>
                                <Select
                                    labelId="label-client"
                                    label="Sélectionner la fréquence :"
                                    variant="outlined"
                                    name="freq"
                                    value={values.freq}
                                    onChange={handleChange}
                                >
                                    {RRuleHelper.FREQ.map((j, i) => {
                                        return (
                                            <MenuItem key={j.id} value={j.id}>
                                                {j.libelle}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                                {errors.freq &&
                                    String(errors.freq) &&
                                    <FormHelperText error={true}>{errors.freq}</FormHelperText>}
                            </FormControl>
                        )}
                    </Field>
                </Grid>
            </Grid>

            {/*INTERVAL FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    {/*Interval <span className="asterix">*</span>*/}
                </Grid>
                <Grid item xs={1}>
                    <Typography>Tout les :</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Field
                        type="text"
                        value={values.interval}>
                        {({field, form}) => (
                            <TextField
                                error={
                                    Boolean(form.errors.interval && form.touched.interval)
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.interval}
                                variant="outlined"
                                margin="normal"
                                type={"number"}
                                fullWidth
                                name="interval"
                                label="interval"
                                autoFocus
                                inputProps={{min: 1}}
                                helperText={
                                    form.errors.interval &&
                                    form.touched.interval &&
                                    String(form.errors.interval)
                                }/>
                        )}
                    </Field>
                </Grid>
                <Grid item xs={6}>
                    <Typography className={"pl-2"}>
                        {getIntervalLabel()}
                    </Typography>
                </Grid>
            </Grid>

            {/* JOURS RECUREENCE FIELD*/}
            {isWeekly() &&
                <Grid item container alignItems={"center"} className={"mt-2"}>
                    <Grid item xs={3}>
                        {/*<Typography>Sélectionner les jours *</Typography>*/}
                    </Grid>
                    <Grid item xs={9}>
                        <Field
                            type="text"
                            name={"byDay"}
                            value={values.byDay}>
                            {({form, push, remove}) => (
                                <FormControl fullWidth error={Boolean(errors.byDay)}>
                                    <InputLabel id="label-client">Sélectionner les jours :</InputLabel>
                                    <Select
                                        labelId="label-client"
                                        label="Sélectionner les jours :"
                                        variant="outlined"
                                        name="byDay"
                                        multiple
                                        value={values.byDay}
                                        onChange={(e) => {
                                            form.setFieldValue(
                                                "byDay",
                                                e.target.value
                                            );
                                        }}
                                    >
                                        {RRuleHelper.DAYS.map((j, i) => {
                                            return (
                                                <MenuItem key={j.id} value={j.id}>
                                                    {j.libelle}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                    {errors.byDay &&
                                        String(errors.byDay) &&
                                        <FormHelperText error={true}>{errors.byDay}</FormHelperText>}
                                </FormControl>
                            )}
                        </Field>
                    </Grid>
                </Grid>
            }

            {/*MOIS RECCURENCE FIELD*/}
            {isMonthly() &&
                <>
                    <Grid item container alignItems={"center"} className={"mt-2"}>
                        <Grid item xs={3}>

                        </Grid>
                        <Grid item xs={9}>
                            <RadioGroup
                                row
                                name="recurrenceType"
                                value={values.recurrenceType}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="dayOfMonth" control={<Radio/>} label="Jour du mois"/>
                                <FormControlLabel value="weekdayOfMonth" control={<Radio/>}
                                                  label="Jour de la semaine du mois"/>
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    {values.recurrenceType === 'dayOfMonth' &&
                        <Grid item container alignItems={"center"} className={"mt-2"}>
                            <Grid item xs={3}>
                            </Grid>
                            <Grid item xs={9}>
                                <Field
                                    type="text"
                                    name={"byMonthDay"}
                                    value={values.byMonthDay}>
                                    {({form, push, remove}) => (
                                        <FormControl fullWidth error={Boolean(errors.byMonthDay)}>
                                            <InputLabel id="label-client">Sélectionner les jours :</InputLabel>
                                            <Select
                                                labelId="label-client"
                                                label="Sélectionner les jours :"
                                                variant="outlined"
                                                name="byMonthDay"
                                                multiple
                                                value={values.byMonthDay}
                                                onChange={(e) => {
                                                    form.setFieldValue(
                                                        "byMonthDay",
                                                        Array.from(e.target.value)
                                                    );
                                                }}
                                            >
                                                {[...new Array(31)].map((day, i) => {
                                                    return (
                                                        <MenuItem key={i} value={i + 1}>
                                                            {i + 1}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                            {errors.byDay &&
                                                String(errors.byDay) &&
                                                <FormHelperText error={true}>{errors.byDay}</FormHelperText>}
                                        </FormControl>
                                    )}
                                </Field>
                            </Grid>
                        </Grid>
                    }
                    {values.recurrenceType === 'weekdayOfMonth' &&
                        <>
                            <Grid item container alignItems={"center"} className={"mt-2"}>
                                <Grid item xs={3}>
                                </Grid>
                                <Grid item xs={9}>
                                    <Grid item container spacing={2}>
                                        <Grid item xs={4}>
                                            <Field
                                                type="text"
                                                name={"bySetPos"}
                                                value={values.bySetPos}>
                                                {({form, push, remove}) => (
                                                    <FormControl fullWidth error={Boolean(errors.bySetPos)}>
                                                        <InputLabel id="label-client">Sélectionner la fréquence
                                                            :</InputLabel>
                                                        <Select
                                                            labelId="label-client"
                                                            label="Sélectionner la fréquence :"
                                                            variant="outlined"
                                                            name="bySetPos"
                                                            value={values.bySetPos}
                                                            onChange={(e) => {
                                                                form.setFieldValue(
                                                                    "bySetPos",
                                                                    e.target.value
                                                                );
                                                            }}
                                                        >
                                                            {RRuleHelper.SETPOS.map((j, i) => {
                                                                return (
                                                                    <MenuItem key={j.id} value={j.id}>
                                                                        {j.libelle}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                        {errors.byDay &&
                                                            String(errors.byDay) &&
                                                            <FormHelperText
                                                                error={true}>{errors.byDay}</FormHelperText>}
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Field
                                                type="text"
                                                name={"byDay"}
                                                value={values.byDay}>
                                                {({form, push, remove}) => (
                                                    <FormControl fullWidth error={Boolean(errors.byDay)}>
                                                        <InputLabel id="label-client">Sélectionner les jours
                                                            :</InputLabel>
                                                        <Select
                                                            labelId="label-client"
                                                            label="Sélectionner les jours :"
                                                            variant="outlined"
                                                            name="byDay"
                                                            multiple
                                                            value={values.byDay}
                                                            onChange={(e) => {
                                                                form.setFieldValue(
                                                                    "byDay",
                                                                    Array.from(e.target.value)
                                                                );
                                                            }}
                                                        >
                                                            {RRuleHelper.DAYS.map((j, i) => {
                                                                return (
                                                                    <MenuItem key={j.id} value={j.id}>
                                                                        {j.libelle}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                        {errors.byDay &&
                                                            String(errors.byDay) &&
                                                            <FormHelperText
                                                                error={true}>{errors.byDay}</FormHelperText>}
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    }
                </>
            }

            {/*DATE PREVISUALISATION*/}
            <Grid item container alignItems={"center"} className={'mt-2'}>
                <Grid item xs={3}>
                    Prévisualisation des 5 prochaines dates
                </Grid>
                <Grid item xs={9}>
                    {getOccurences() && getOccurences().map((item, index) => {
                        if (index < 5) {
                            return (
                                <span>{helper.formatDate(item)},&nbsp;</span>
                            )
                        }
                    })
                    }
                </Grid>
            </Grid>

            {/*DATE FIN FIELD*/}
            <Grid item container alignItems={"center"} className={'mt-2'}>
                <Grid item xs={3}>
                    Fin d'affichage de la tournée <span className="asterix">*</span>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        name={"dateFin"}
                        value={values.dateFin}>
                        {({field, form}) => (
                            <FormControl fullWidth
                                         errors={errors.dateFin}>
                                <DatePicker value={parseISO(values.dateFin)}
                                            onChange={(value) => {
                                                form.setFieldValue(field.name, format(value, 'yyyy-MM-dd'));
                                            }}
                                            minDate={parseISO(values.dateDebut)}/>
                            </FormControl>
                        )}
                    </Field>
                </Grid>
            </Grid>

        </>
    );
}

export default CampaignFrequencyField;
