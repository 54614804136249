import {interventionsConstants} from "./interventions.constants";
import {interventionsService} from "./interventions.service";
import {toastHelper} from "../../utils/toast-helper";
import Constants from "../../utils/constants";
import history from "../../utils/history";

export const interventionsActions = {
    fetchInterventions,
    fetchInterventionsForPatrimoine,
    fetchIntervention,
    deleteIntervention,
    createIntervention,
    updateIntervention,
    clearInterventions
};

function fetchInterventions(containerId) {
    return dispatch => {
        dispatch(request());

        interventionsService.fetchInterventions(containerId)
            .then(
                interventions => {
                    dispatch(success(interventions));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: interventionsConstants.INTERVENTIONS_FETCH_REQUEST}
    }

    function success(interventions) {
        return {type: interventionsConstants.INTERVENTIONS_FETCH_SUCCESS, interventions}
    }

    function failure(error) {
        return {type: interventionsConstants.INTERVENTIONS_FETCH_FAILURE, error}
    }
}

function fetchInterventionsForPatrimoine(patrimoineId) {
    return dispatch => {
        dispatch(request());

        interventionsService.fetchInterventionsForPatrimoine(patrimoineId)
            .then(
                interventions => {
                    dispatch(success(interventions));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: interventionsConstants.INTERVENTIONS_FETCH_REQUEST}
    }

    function success(interventions) {
        return {type: interventionsConstants.INTERVENTIONS_FETCH_SUCCESS, interventions}
    }

    function failure(error) {
        return {type: interventionsConstants.INTERVENTIONS_FETCH_FAILURE, error}
    }
}

function fetchIntervention(id) {
    return dispatch => {
        dispatch(request());
        interventionsService.fetchIntervention(id)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: interventionsConstants.INTERVENTIONS_FETCH_ONE_REQUEST}
    }

    function success(intervention) {
        return {type: interventionsConstants.INTERVENTIONS_FETCH_ONE_SUCCESS, intervention}
    }

    function failure(error) {
        return {type: interventionsConstants.INTERVENTIONS_FETCH_ONE_FAILURE, error}
    }
}

function deleteIntervention(id, navigate) {
    return dispatch => {
        dispatch(request());

        interventionsService.deleteIntervention(id)
            .then(
                id => {
                    dispatch(success(id));
                    history.replace(Constants.APP_ROUTE_ADMIN_INTERVENTIONS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };


    function request() {
        return {type: interventionsConstants.INTERVENTIONS_DELETE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("Le interventionulaire a bien été supprimé.")
        return {type: interventionsConstants.INTERVENTIONS_DELETE_SUCCESS, id}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: interventionsConstants.INTERVENTIONS_DELETE_FAILURE, error}
    }
}

function createIntervention(values, navigate) {
    return dispatch => {
        dispatch(request());

        interventionsService.createIntervention(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_INTERVENTIONS_FORM);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: interventionsConstants.INTERVENTIONS_CREATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("Le interventionulaire a bien été créé.")
        return {type: interventionsConstants.INTERVENTIONS_CREATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: interventionsConstants.INTERVENTIONS_CREATE_FAILURE, error}
    }
}

function updateIntervention(values, navigate) {
    return dispatch => {
        dispatch(request());
        interventionsService.updateIntervention(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_INTERVENTIONS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: interventionsConstants.INTERVENTIONS_UPDATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("Le interventionulaire a bien été modifié.")
        return {type: interventionsConstants.INTERVENTIONS_UPDATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: interventionsConstants.INTERVENTIONS_UPDATE_FAILURE, error}
    }
}

function clearInterventions() {
    return dispatch => {
        dispatch(request());
    };

    function request() {
        return {type: interventionsConstants.INTERVENTIONS_CLEAR_REQUEST}
    }
}
