export const laveusesConstants = {
    LAVEUSES_FETCH_REQUEST: "LAVEUSES_FETCH_REQUEST",
    LAVEUSES_FETCH_SUCCESS: "LAVEUSES_FETCH_SUCCESS",
    LAVEUSES_FETCH_FAILURE: "LAVEUSES_FETCH_FAILURE",

    LAVEUSES_FETCH_ONE_REQUEST: "LAVEUSES_FETCH_ONE_REQUEST",
    LAVEUSES_FETCH_ONE_SUCCESS: "LAVEUSES_FETCH_ONE_SUCCESS",
    LAVEUSES_FETCH_ONE_FAILURE: "LAVEUSES_FETCH_ONE_FAILURE",

    LAVEUSES_CREATE_REQUEST: "LAVEUSES_CREATE_REQUEST",
    LAVEUSES_CREATE_SUCCESS: "LAVEUSES_CREATE_SUCCESS",
    LAVEUSES_CREATE_FAILURE: "LAVEUSES_CREATE_FAILURE",

    LAVEUSES_UPDATE_REQUEST: "LAVEUSES_UPDATE_REQUEST",
    LAVEUSES_UPDATE_SUCCESS: "LAVEUSES_UPDATE_SUCCESS",
    LAVEUSES_UPDATE_FAILURE: "LAVEUSES_UPDATE_FAILURE",

    LAVEUSES_DELETE_REQUEST: "LAVEUSES_DELETE_REQUEST",
    LAVEUSES_DELETE_SUCCESS: "LAVEUSES_DELETE_SUCCESS",
    LAVEUSES_DELETE_FAILURE: "LAVEUSES_DELETE_FAILURE",



    LAVEUSES_CLEAR_REQUEST: "LAVEUSES_CLEAR_REQUEST",

};
