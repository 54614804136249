import {interventionsConstants} from './interventions.constants';
import {authenticationConstants} from "../authentication/auth.constants";

const initialState = {interventions: null, intervention: null, loading: false, error: null}

export function interventions(state = initialState, action) {
    switch (action.type) {
        case interventionsConstants.INTERVENTIONS_FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case interventionsConstants.INTERVENTIONS_FETCH_SUCCESS:
            return {
                ...state,
                interventions: action.interventions,
                intervention: null,
                loading: false,
                error: null
            };
        case interventionsConstants.INTERVENTIONS_FETCH_ONE_REQUEST:
            return {
                ...state,
                intervention: null,
                loading: true,
                error: null
            };
        case interventionsConstants.INTERVENTIONS_FETCH_ONE_SUCCESS:
            return {
                ...state,
                intervention: action.intervention,
                interventions: null,
                loading: false,
                error: null
            };
        case interventionsConstants.INTERVENTIONS_FETCH_ONE_FAILURE:
            return {
                ...state,
                intervention: null,
                loading: false,
                error: action.error
            };
        case interventionsConstants.INTERVENTIONS_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case interventionsConstants.INTERVENTIONS_DELETE_SUCCESS:
        case interventionsConstants.INTERVENTIONS_CREATE_SUCCESS:
        case interventionsConstants.INTERVENTIONS_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                interventions: null,
                intervention: null
            };
        case interventionsConstants.INTERVENTIONS_FETCH_FAILURE:
        case interventionsConstants.INTERVENTIONS_CREATE_FAILURE:
        case interventionsConstants.INTERVENTIONS_UPDATE_FAILURE:
        case interventionsConstants.INTERVENTIONS_DELETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case interventionsConstants.INTERVENTIONS_CLEAR_REQUEST:
            return {
                ...initialState
            }
        case authenticationConstants.LOGOUT:
            return {
                initialState
            }
        default:
            return state
    }
}
