export const usersConstants = {
    USERS_FETCH_REQUEST: "USERS_FETCH_REQUEST",
    USERS_FETCH_SUCCESS: "USERS_FETCH_SUCCESS",
    USERS_FETCH_FAILURE: "USERS_FETCH_FAILURE",

    EXPLOITANTS_FETCH_REQUEST: "EXPLOITANTS_FETCH_REQUEST",
    EXPLOITANTS_FETCH_SUCCESS: "EXPLOITANTS_FETCH_SUCCESS",
    EXPLOITANTS_FETCH_FAILURE: "EXPLOITANTS_FETCH_FAILURE",

    CLIENTS_FETCH_REQUEST: "CLIENTS_FETCH_REQUEST",
    CLIENTS_FETCH_SUCCESS: "CLIENTS_FETCH_SUCCESS",
    CLIENTS_FETCH_FAILURE: "CLIENTS_FETCH_FAILURE",

    USERS_FETCH_ONE_REQUEST: "USERS_FETCH_ONE_REQUEST",
    USERS_FETCH_ONE_SUCCESS: "USERS_FETCH_ONE_SUCCESS",
    USERS_FETCH_ONE_FAILURE: "USERS_FETCH_ONE_FAILURE",

    USERS_CREATE_REQUEST: "USERS_CREATE_REQUEST",
    USERS_CREATE_SUCCESS: "USERS_CREATE_SUCCESS",
    USERS_CREATE_FAILURE: "USERS_CREATE_FAILURE",

    USERS_UPDATE_REQUEST: "USERS_UPDATE_REQUEST",
    USERS_UPDATE_SUCCESS: "USERS_UPDATE_SUCCESS",
    USERS_UPDATE_FAILURE: "USERS_UPDATE_FAILURE",

    USERS_DELETE_REQUEST: "USERS_DELETE_REQUEST",
    USERS_DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
    USERS_DELETE_FAILURE: "USERS_DELETE_FAILURE",

    OPERATEURS_FETCH_REQUEST: "OPERATEURS_FETCH_REQUEST",
    OPERATEURS_FETCH_SUCCESS: "OPERATEURS_FETCH_SUCCESS",
    OPERATEURS_FETCH_FAILURE: "OPERATEURS_FETCH_FAILURE",

    USER_CLEAR_REQUEST: "USERS_CLEAR_REQUEST",

    LOGO_CREATE_REQUEST: "LOGO_CREATE_REQUEST",
    LOGO_CREATE_SUCCESS: "LOGO_CREATE_SUCCESS",
    LOGO_CREATE_FAILURE: "LOGO_CREATE_FAILURE",

};
