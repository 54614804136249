export const Role = {
    ROLE_ADMIN: 'ROLE_ADMIN',
    ROLE_EXPLOITANT: 'ROLE_EXPLOITANT',
    ROLE_CLIENT: 'ROLE_CLIENT',
    ROLE_OPERATEUR: 'ROLE_OPERATEUR',
}

export const getRole = (roles) => {
    if (roles && Array.isArray(roles)) {
        if (roles.includes(Role.ROLE_ADMIN)) {
            return "Administrateur";
        }
        if (roles.includes(Role.ROLE_EXPLOITANT)) {
            return "Exploitant";
        }
        if (roles.includes(Role.ROLE_CLIENT)) {
            return "Client";
        }
        if (roles.includes(Role.ROLE_OPERATEUR)) {
            return "Opérateur";
        }

    }
    return "";
}

export const isAdmin = () => {
    let user = JSON.parse(localStorage.getItem("user"))
    if (user) {
        if (user.hasOwnProperty("roles")) {
            if (user.roles.includes(Role.ROLE_ADMIN)) {
                return true;
            }
        }
    }

    return false;
}

export const isExploitant = () => {
    let user = JSON.parse(localStorage.getItem("user"))
    if (user) {
        if (user.hasOwnProperty("roles")) {
            if (user.roles.includes(Role.ROLE_EXPLOITANT)) {
                return true;
            }
        }
    }

    return false;
}

