import {conteneursConstants} from './conteneurs.constants';
import {authenticationConstants} from "../authentication/auth.constants";

const initialState = {conteneurs: null, conteneur: null, loading: false, error: null}

export function conteneurs(state = initialState, action) {
    switch (action.type) {
        case conteneursConstants.CONTENEURS_FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case conteneursConstants.CONTENEURS_FETCH_SUCCESS:
            return {
                ...state,
                conteneur: null,
                conteneurs: action.conteneurs,
                loading: false,
                error: null
            };
        case conteneursConstants.CONTENEURS_FETCH_ONE_REQUEST:
            return {
                ...state,
                conteneur: null,
                loading: true,
                error: null
            };
        case conteneursConstants.CONTENEURS_FETCH_ONE_SUCCESS:
            return {
                ...state,
                conteneur: action.conteneur,
                conteneurs: null,
                loading: false,
                error: null
            };
        case conteneursConstants.CONTENEURS_FETCH_ONE_FAILURE:
            return {
                ...state,
                conteneur: null,
                loading: false,
                error: action.error
            };
        case conteneursConstants.CONTENEURS_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case conteneursConstants.CONTENEURS_DELETE_SUCCESS:
        case conteneursConstants.CONTENEURS_CREATE_SUCCESS:
        case conteneursConstants.CONTENEURS_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                conteneurs: null,
                conteneur: null
            };
        case conteneursConstants.CONTENEURS_FETCH_FAILURE:
        case conteneursConstants.CONTENEURS_CREATE_FAILURE:
        case conteneursConstants.CONTENEURS_UPDATE_FAILURE:
        case conteneursConstants.CONTENEURS_DELETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case conteneursConstants.CONTENEURS_CLEAR_REQUEST:
            return {
               ...initialState
            }
        case authenticationConstants.LOGOUT:
            return {
                initialState
            }
        default:
            return state
    }
}
