import Constants from "../../utils/constants";
import history from "../../utils/history";

const mockup = import.meta.env.REACT_APP_MOCKUP
export const authService = {
    login,
    logout,
    askResetPassword,
    resetPassword,
    refreshToken
};

function refreshToken(token) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json',},
        body: JSON.stringify({refresh_token: token})
    };
    return new Promise((resolve, reject) => {
        fetch(Constants.API_ROUTE_TOKEN_REFRESH, requestOptions)
            .then(handleResponse)
            .then(user => {
                resolve(user);
            }).catch(reason => {
            reject(reason)
        });
    });
}

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({email: username, password: password})
    };

    return new Promise((resolve, reject) => {
        fetch(Constants.API_ROUTE_LOGIN, requestOptions)
            .then(handleResponse)
            .then(user => {
                resolve(user);
            }).catch(reason => {
            reject(reason)
        });
    });

}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    history.replace(Constants.APP_ROUTE_LOGIN)
}

function askResetPassword(username) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json',},
        body: JSON.stringify({email: username})
    };

    return fetch(Constants.API_ROUTE_ASK_RESET_PASSWORD, requestOptions)
        .then(handleResponse)
        .then(resp => {
            //TODO. toast
            return "ok";
        });
}

function resetPassword(token, newPassword) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json',},
        body: JSON.stringify({resetToken: token, password: newPassword})
    };

    if (mockup === "true") {
        return new Promise(resolve => {
            //OK //TODO. toast
            resolve();
        })

    }
    return fetch(Constants.API_ROUTE_RESET_PASSWORD, requestOptions)
        .then(handleResponse)
        .then(resp => {
            //TODO. toast
            return "ok";
        });

}

function handleResponse(response) {
    return response.text().then(text => {
        if (text.indexOf('Accréditation refusée') > -1) {
            return Promise.reject('Accréditation refusée');
        }
        if (text.indexOf('Invalid credentials') > -1) {
            return Promise.reject('Identifiants invalides');
        }
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                // window.location.reload(true);
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
