import {format, parseISO} from "date-fns";
import {RRule, rrulestr} from 'rrule';

export const RRuleHelper = {
    FREQ: [
        {id: "WEEKLY", libelle: "Hebdomadaire"},
        {id: "MONTHLY", libelle: "Mensuelle"}
    ],

    DAYS: [
        {id: "MO", libelle: "lundi"},
        {id: "TU", libelle: "mardi"},
        {id: "WE", libelle: "mercredi"},
        {id: "TH", libelle: "jeudi"},
        {id: "FR", libelle: "vendredi"},
        {id: "SA", libelle: "samedi"},
        {id: "SU", libelle: "dimanche"}
    ],

    SETPOS: [
        {id: 1, libelle: "Le premier"},
        {id: 2, libelle: "Le deuxième"},
        {id: 3, libelle: "Le troisième"},
        {id: 4, libelle: "Le quatrième"},
        {id: -1, libelle: "Le dernier"}
    ],

    RECURRINGTYPE: {
        DAYOFMONTH: "dayOfMonth",
        WEEKDAYOFMONTH: "weekdayOfMonth"
    },

    /** MAPING FROM RRULE to FORM VALUES */
    DAYMAP: {
        0: 'MO', // Lundi
        1: 'TU', // Mardi
        2: 'WE', // Mercredi
        3: 'TH', // Jeudi
        4: 'FR', // Vendredi
        5: 'SA', // Samedi
        6: 'SU', // Dimanche
    },
    MONTHMAP: {
        1: 'JAN', // Janvier
        2: 'FEB', // Février
        3: 'MAR', // Mars
        4: 'APR', // Avril
        5: 'MAY', // Mai
        6: 'JUN', // Juin
        7: 'JUL', // Juillet
        8: 'AUG', // Août
        9: 'SEP', // Septembre
        10: 'OCT', // Octobre
        11: 'NOV', // Novembre
        12: 'DEC', // Décembre
    },

    FREQMAP: {
        0: 'YEARLY',
        1: 'MONTHLY',
        2: 'WEEKLY',
        3: 'DAILY',
    },

    /** FOR i18n */
    FRENCH: {
        dayNames: [
            "Dimanche",
            "Lundi",
            "Mardi",
            "Mercredi",
            "Jeudi",
            "Vendredi",
            "Samedi"
        ],
        monthNames: [
            "Janvier",
            "Février",
            "Mars",
            "Avril",
            "Mai",
            "Juin",
            "Juillet",
            "Août",
            "Septembre",
            "Octobre",
            "Novembre",
            "Décembre"
        ]
    },

    FRENCHSTRINGS: {
        'every': 'chaque',
        'until': 'jusqu\'au',
        'day': 'jour',
        'days': 'jours',
        'week': 'semaine',
        'weeks': 'semaines',
        'on': 'le',
        'months': 'mois',
        'month': 'mois',
        "weekday": "jour de la semaine"
    },

    getText: (id) => {
        // Return fr. string, default to english.
        return RRuleHelper.FRENCHSTRINGS[id] || id;
    },

    buildRRuleString: (formFields) => {
        let rrule = 'RRULE:FREQ=' + formFields.freq + ';INTERVAL=' + formFields.interval;

        // Si la récurrence est hebdomadaire et qu'il y a des jours sélectionnés.
        if (formFields.freq === RRuleHelper.FREQ[0].id && formFields.byDay.length > 0) {
            rrule += ';BYDAY=' + formFields.byDay.join(',');
        }
        // Si la récurrence est mensuelle et qu'il y a un jour du mois sélectionné.
        else if (formFields.freq === RRuleHelper.FREQ[1].id && formFields.recurrenceType === RRuleHelper.RECURRINGTYPE.DAYOFMONTH && formFields.byMonthDay) {
            rrule += ';BYMONTHDAY=' + formFields.byMonthDay;
        }
        // Si la récurrence est mensuelle et qu'il y a un jour de la semaine et une position de set sélectionnés.
        else if (formFields.freq === RRuleHelper.FREQ[1].id && formFields.recurrenceType === RRuleHelper.RECURRINGTYPE.WEEKDAYOFMONTH && formFields.byDay.length > 0 && formFields.bySetPos) {
            rrule += ';BYDAY=' + formFields.byDay.join(',');
            rrule += ';BYSETPOS=' + formFields.bySetPos;
        }

        rrule += ';UNTIL=' + format(new Date(formFields.dateFin), 'yyyyMMdd\'T\'HHmmss\'Z\'');

        return rrule;
    },

    rruleToFrench: (rruleStr) => {
        const rruleObj = RRule.fromString(rruleStr);

        let frequency;
        switch (rruleObj.options.freq) {
            case RRule.DAILY:
                frequency = 'tous les jours';
                break;
            case RRule.WEEKLY:
                frequency = 'toutes les semaines';
                break;
            case RRule.MONTHLY:
                frequency = 'tous les mois';
                break;
            case RRule.YEARLY:
                frequency = 'tous les ans';
                break;
            default:
                frequency = '';
        }

        let interval = rruleObj.options.interval > 1 ? `tous les ${rruleObj.options.interval}` : '';

        let result = `Se répète ${frequency} ${interval}`;
        return result.trim();
    },

    rruleToText: (rrule) => {
        if (rrule) {
            return rrulestr(rrule).toText(RRuleHelper.getText, RRuleHelper.FRENCH)
        }
        return null;
    },

    isOccurrenceToday: (dateDebut, ruleStr) => {
        let date = new Date(dateDebut);
        date.setHours(6); //Fix UTC wrong calc
        let now = new Date();

        if (date > now) return false

        let dtstart = "DTSTART:" + RRuleHelper.formatDateToUTC(date) + "\n";
        let rule = RRule.fromString(dtstart + ruleStr);
        // let rule = RRule.fromString(ruleStr)

        let start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        let end = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);

        // Obtenir les occurrences pour aujourd'hui
        let occurrences = rule.between(start, end);

        return occurrences.length > 0
    },

    getNextOccurences: (dateDebut, rrule) => {
        let date = new Date(dateDebut);
        date.setHours(6); //Fix UTC wrong calc
        let dtstart = "DTSTART:" + RRuleHelper.formatDateToUTC(date) + "\n";
        // let rule = RRule.fromString(campaign.rrule)
        let rule = RRule.fromString(dtstart + rrule)
        let now = new Date();
        let start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        let end = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 365);

        return rule.between(start, end);
    },

    getLastOccurences: (dateDebut, rrule) => {
        let date = new Date(dateDebut);
        date.setHours(6); // Fix UTC wrong calc
        let dtstart = "DTSTART:" + RRuleHelper.formatDateToUTC(date) + "\n";
        let rule = RRule.fromString(dtstart + rrule);

        let now = new Date();
        let start = date;
        let end = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);

        // Trouver toutes les occurrences jusqu'à aujourd'hui
        return rule.between(start, end);
    },

    formatDateToUTC: (date) => {
        // Padding function to add leading zero if needed
        function pad(number) {
            if (number < 10) {
                return '0' + number;
            }
            return number;
        }

        return date.getUTCFullYear() + "" +
            pad(date.getUTCMonth() + 1) + "" +
            pad(date.getUTCDate()) + "" +
            'T' +
            pad(date.getUTCHours()) + "" +
            pad(date.getUTCMinutes()) + "" +
            pad(date.getUTCSeconds()) + "" +
            'Z';
    },
}
