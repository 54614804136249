import React from 'react';
import './NotFound.css';
import {Container} from "@mui/material";

function NotFound() {
  return (
    <Container className="pageContainer">
      <h1>
       Coming Soon
      </h1>
    </Container>
  );
}

export default NotFound;
