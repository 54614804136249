import React from 'react';
import {Field} from 'formik';
import {
    FormControl,
    Grid, InputLabel, MenuItem, Select,
    TextField, ToggleButton, ToggleButtonGroup,
    Typography
} from "@mui/material";
import PlaceAutocomplete from "./PlaceAutocomplete";


function PatrimoineDataField({...props}) {
    const {values, errors, mode, clients, handleChange, handleBlur} = props;

    return (
        <Grid container spacing={1} className={"sectionContainer"}>
            <Grid item xs={12}>
                <Typography variant={"h5"} className={"sectionHeader"}>Informations Patrimoine</Typography>
            </Grid>
            {mode === "edit" &&
                <Grid item container alignItems={"center"}>
                    <Grid item xs={3}>
                        Client <span className="asterix">*</span>
                    </Grid>
                    <Grid item xs={9}>
                        <Field
                            type="text"
                            name="client"
                            value={values.client}
                        >
                            {({field, form}) => (
                                <FormControl fullWidth>
                                    <InputLabel id="label-client">Sélectionner le client :</InputLabel>
                                    <Select
                                        labelId="label-client"
                                        label="Sélectionner le client :"
                                        variant="outlined"
                                        value={values.client ?? ""}
                                        onChange={(event) => {
                                            form.setFieldValue("client", event.target.value)
                                            // handleChange(event.target.value)
                                        }}
                                    >
                                        {clients && clients.map((e) => {
                                            return (
                                                <MenuItem key={e.id} value={e['@id']}>
                                                    {e.libelle}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            )}
                        </Field>
                    </Grid>
                </Grid>
            }

            {/* LIBELLE FIELD */}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Libellé <span className="asterix">*</span>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        name="libelle"
                        value={values.libelle}
                    >
                        {({field, form}) => (
                            <TextField
                                error={Boolean(form.errors.libelle && form.touched.libelle)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.libelle}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="libelle"
                                label="Libellé"
                                helperText={
                                    form.errors.libelle &&
                                    form.touched.libelle &&
                                    String(form.errors.libelle)
                                }
                            />
                        )}
                    </Field>
                </Grid>
            </Grid>

            {/* ISUNIPATRIMOINE FIELD */}
            <Grid item container alignItems={"center"} className={"mb-1"}>
                <Grid item xs={3}>
                    Type de patrimoine <span className="asterix">*</span>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        value={values.isUnipatrimoine ? values.isUnipatrimoine : false}>
                        {({field, form}) => (
                            <ToggleButtonGroup
                                color={"primary"}
                                exclusive
                                size={"medium"}
                                name="isUnipatrimoine"
                                value={values.isUnipatrimoine}
                                onChange={(event, newValue) => {
                                    form.setFieldValue('isUnipatrimoine', newValue);
                                }}
                                aria-label={"Unipatrimoine"}
                            >
                                <ToggleButton value={false}
                                              className={values.isUnipatrimoine === false ? "toggle-button button-selected-primary" : "toggle-button"}>Normal</ToggleButton>
                                <ToggleButton value={true} className={values.isUnipatrimoine === true ? "toggle-button button-selected-secondary" : "toggle-button"}>Unipatrimoine</ToggleButton>
                            </ToggleButtonGroup>
                        )}
                    </Field>
                </Grid>
            </Grid>

            {values.isUnipatrimoine &&
                <>
                    {/* LIEU FIELD */}
                    <PlaceAutocomplete values={values} errors={errors} handleChange={handleChange}
                                       handleBlur={handleBlur}/>

                    {/* NOMBRE DE BACS FIELD */}
                    <Grid item container alignItems={"center"}>
                        <Grid item xs={3}>
                            Nombre de bacs <span className="asterix">*</span>
                        </Grid>
                        <Grid item xs={9}>
                            <Field
                                type="number"
                                name="nombreDeBacs"
                                value={values.nombreDeBacs}
                            >
                                {({field, form}) => (
                                    <TextField
                                        error={Boolean(form.errors.nombreDeBacs && form.touched.nombreDeBacs)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.nombreDeBacs}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="nombreDeBacs"
                                        label="Nombre de Bacs"
                                        helperText={
                                            form.errors.nombreDeBacs &&
                                            form.touched.nombreDeBacs &&
                                            String(form.errors.nombreDeBacs)
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                    </Grid>

                    {/* COMMENTAIRE FIELD */}
                    <Grid item container alignItems={"center"}>
                        <Grid item xs={3}>
                            Commentaire
                        </Grid>
                        <Grid item xs={9}>
                            <Field
                                type="text"
                                name="commentaire"
                                value={values.commentaire}
                            >
                                {({field, form}) => (
                                    <TextField
                                        error={Boolean(form.errors.commentaire && form.touched.commentaire)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.commentaire}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="commentaire"
                                        label="Commentaire"
                                        helperText={
                                            form.errors.commentaire &&
                                            form.touched.commentaire &&
                                            String(form.errors.commentaire)
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                </>

            }
        </Grid>
    );
}

export default PatrimoineDataField;
