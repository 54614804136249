import React from 'react';
import {connect} from "react-redux";
import {authActions} from '../../../modules/authentication/auth.actions'
import {useForm} from "react-hook-form";
import {makeStyles} from "@mui/styles";
import {Button, Paper, CircularProgress, Container, CssBaseline, TextField, Typography, Grid} from "@mui/material";

let useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function ForgotPassword({dispatch, ...props}) {
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const classes = useStyles();
    const {register, handleSubmit, errors} = useForm();

    const onSubmit = data => {
        setIsSubmitting(true);
        dispatch(authActions.askResetPassword(data.email))
    }
    return (
        <Container component="main" maxWidth="xs" className={"loginContainer"}
                   sx={{backgroundImage: `url(/images/laveuse_background.webp)`}}>
            <CssBaseline/>
            <Paper elevation={24} className={"loginFormContainer"}>
                <div className={"logoWrapper mt-1"}>
                    <img src={`/images/logo.png`} alt="Logo Les Eugene"/>
                </div>
                <Typography component="h1" variant="h5" sx={{textAlign: 'center', marginTop: '3rem'}}>
                    Mot de passe oublié
                </Typography>
                <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        <Grid item md={1}></Grid>
                        <Grid item xs={12} md={10}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Email"
                                name="email"
                                autoComplete="username"
                                autoFocus
                                {...register("email", {required: true})}
                            />
                            {errors && errors.email && <span className="form-error">ce champ est requis</span>}
                        </Grid>
                    </Grid>

                    <Grid container className={"mt-2"}>
                        <Grid item md={1}></Grid>
                        <Grid item xs={12} md={10}>
                            <Button
                                type="submit"
                                fullWidth
                                disabled={isSubmitting && props.error === false}
                                variant="contained"
                                color="secondary"
                                className={classes.submit}
                            >
                                <Typography sx={{color: 'white', padding: '.5rem'}}>
                                    Réinitialiser mon mot de passe
                                </Typography>
                                {(isSubmitting && props.error === false) &&
                                    <CircularProgress size={24} className="button-progress"/>}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
    );
}

const mapStateToProps = function (state) {
    return {
        error: state.authentication.error,
    }
}


const ForgotPasswordPage = connect(mapStateToProps)(ForgotPassword)

export default ForgotPasswordPage;
