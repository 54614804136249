import {
    deleteHeaders,
    fetchHeaders,
    patchHeaders,
    postHeaders,
} from "../../utils/headers";
import Constants from "../../utils/constants";

export const interventionsService = {
    fetchInterventions,
    fetchInterventionsForPatrimoine,
    fetchIntervention,
    deleteIntervention,
    createIntervention,
    updateIntervention,
};

function fetchInterventions(containerId) {
    const requestOptions = fetchHeaders()

    let url = Constants.API_ROUTE_INTERVENTIONS_BY_CONTAINERID.replace(':id', containerId);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(interventions => {
            return interventions["hydra:member"]
        });
}

function fetchInterventionsForPatrimoine(patrimoineId) {
    const requestOptions = fetchHeaders()

    let url = Constants.API_ROUTE_INTERVENTIONS_BY_PATRIMOINEID.replace(':id', patrimoineId);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(interventions => {
            return interventions["hydra:member"]
        });
}

function fetchIntervention(id) {
    const requestOptions = fetchHeaders()

    let url = Constants.API_ROUTE_INTERVENTION.replace(':id', id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(intervention => {
            return intervention
        });
}

function createIntervention(values) {
    const requestOptions = postHeaders(values)

    let url = Constants.API_ROUTE_INTERVENTIONS;

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function updateIntervention(values) {
    const requestOptions = patchHeaders(values)
    let url = Constants.API_ROUTE_INTERVENTION.replace(':id', values.id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function deleteIntervention(id) {
    const requestOptions = deleteHeaders()

    let url = Constants.API_ROUTE_INTERVENTION.replace(':id', id);
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return id
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {

            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
