import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {helper} from "../../../utils/helper";
import {dashboardActions} from "../../../modules/dashboard/dashboard.actions";
import {
    Paper, Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import TableHeader from "../layout/table/TableHeader";
import moment from "moment-timezone";
import Constants from "../../../utils/constants";
import Loader from "../layout/loading/loader";

function DashboardLoader({dispatch, ...props}) {


    return (
        <React.Fragment>
            <TableRow key={1}>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
            </TableRow>
            <TableRow key={2}>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
            </TableRow>
            <TableRow key={3}>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
                <TableCell><Skeleton animation="wave" variant="text"/></TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default DashboardLoader;