import React from 'react';
import './Footer.css';

function Footer() {
    return (
      <footer>
          {/*<Typography variant={"body1"}>*/}
          {/*    Extranet Les Eugene ({new Date().getFullYear()})*/}
          {/*</Typography>*/}
      </footer>
    );
}

export default Footer;
