import {helper} from "../utils/helper";
import moment from "moment";
import {authService} from "./authentication/auth.service";

let isRefreshing = false;

export const jwtMiddleware = store => next => action => {
    let token = null;
    let refreshToken = null;

    try {
        token = helper.parseJwt(JSON.parse(localStorage.getItem("token")));
        refreshToken = JSON.parse(localStorage.getItem("refresh_token"));
    } catch (e) {

    }


    if (token && token.hasOwnProperty("exp")) {
        // console.log("NOW + 5 : ", moment().add(5, 'm'))
        // console.log("isBefore ? ", moment.unix(token.exp).isBefore(moment().add(5, 'm').toDate()))
        // console.log("isAfter ? ", moment.unix(token.exp).isAfter(moment().add(5, 'm').toDate()))
        if (moment.unix(token.exp).isBefore(moment().add(5, 'm').toDate()) && !isRefreshing) {
            //Token expires 5min from now, so refresh it.
            isRefreshing = true;
            authService.refreshToken(refreshToken).then(user => {
                helper.storeJwt(user);

                isRefreshing = false;
                return next(action)
            }).catch(error => {
                isRefreshing = false;
                return next(action)
            })
        } else {
            return next(action)
        }
    } else {
        return next(action)
    }

}
