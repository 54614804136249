import React, {useEffect, useRef, useState} from 'react';
import {Container, Grid} from "@mui/material";
import {connect} from "react-redux";
import Loader from "./layout/loading/loader";
import Button from "@mui/material/Button";
import CSVHandler from "../../utils/CSVHandler";
import {Done, Error, HourglassTop} from "@mui/icons-material";
import {useParams} from "react-router-dom";
import {patrimoinesActions} from "../../modules/patrimoines/patrimoines.actions";
import * as iconv from "iconv-lite";
import * as jschardet from "jschardet";
import Constants from "../../utils/constants";

function PatrimoineImportPage({dispatch, ...props}) {
    const inputFileRef = useRef(null);
    const params = useParams();
    const [patrimoineId, setPatrimoineId] = useState(null);

    const [checkMessages, setCheckMessages] = useState(null);
    const [checkError, setCheckError] = useState(null);
    const [consoleMessages, setConsoleMessages] = useState(null);
    const [errorMessages, setErrorMessages] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [containers, setContainers] = useState([]);

    useEffect(() => {
        if (params.hasOwnProperty("id")) {
            setPatrimoineId(params.id);
        }
    }, [params]);

    useEffect(() => {
        if (patrimoineId && (props.patrimoine?.id !== parseInt(patrimoineId)) && !props.loading && !props.error) {
            dispatch(patrimoinesActions.fetchPatrimoine(patrimoineId));
        }
    }, [props, dispatch, patrimoineId]);

    if (props.loading) {
        return <Loader/>;
    }

    const publishProcessMessage = (message) => {
        setConsoleMessages(message);
    }

    const publishErrorMessage = (message) => {
        setErrorMessages(prevMessages => [...prevMessages, message]);
    }

    /**
     * D'abord on check l'encodage avec jschardet
     * Pour ocnvertir les ISO-88XXX et Windows-1252 en utf8
     * @param csv
     */
    const handleCSVUpload = async (csv) => {
        if (!csv) {
            // Fichier Non Présent faire alerte.
            return;
        }

        let detectedEncoding = 'UTF-8';  // Fallback to UTF-8

        // Utilisation de readAsBinaryString pour la détection de l'encodage
        await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (e) {
                const binaryString = e.target.result;
                const detectResult = jschardet.detect(binaryString);
                detectedEncoding = detectResult.encoding || 'UTF-8';  // Fallback to UTF-8
                resolve();
            };
            reader.onerror = reject;
            reader.readAsBinaryString(csv.slice(0, 1024));  // Lire seulement les premiers octets
        });

        try {
            let content;

            await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = function (e) {
                    const arrayBuffer = e.target.result;
                    const textDecoder = new TextDecoder(detectedEncoding);
                    content = textDecoder.decode(new Uint8Array(arrayBuffer));
                    resolve();
                };
                reader.onerror = reject;
                reader.readAsArrayBuffer(csv);
            });

            // Your existing code for processing CSV content
            setCurrentStep(1);
            const csvLines = content.split('\n');
            const headers = csvLines[0].split(';');
            const headersValidation = CSVHandler.checkHeaders(headers, Constants.EXPECTED_PATRIMOINE_HEADER);

            if (headersValidation !== true) {
                if (headersValidation.missingHeaders.length > 0) {
                    const missingHeadersMessage = `En-tête(s) manquant(s) dans le fichier : ${headersValidation.missingHeaders.join(', ')}`;
                    setCheckMessages(missingHeadersMessage);
                    setCheckError(true);
                }
                return;
            }
            setCheckMessages("Le fichier est conforme");

            setCurrentStep(2);

            let containers = await CSVHandler.processCSVLines(props.patrimoine?.libelle, csvLines, publishProcessMessage, publishErrorMessage);

            if (containers.length > 0) {
                setCurrentStep(3);
                setContainers(containers);
            }

        } catch (err) {
            console.error('An error occurred:', err);
            // Handle the error as needed.
        }
    };


    const handleResetFileInput = () => {
        if (inputFileRef.current) {
            inputFileRef.current.value = null;
        }
        setCheckError(null);
        setCheckMessages(null);
        setConsoleMessages([])
        setErrorMessages([])
        setCurrentStep(0);
        setContainers([]);
    };

    const handleSubmit = () => {
        let values = {
            conteneurs: containers
        }

        dispatch(patrimoinesActions.updatePatrimoine(values, patrimoineId));
    }

    return (
        <>
            <h1>Import du patrimoine : {props.patrimoine?.libelle}</h1>

            <Grid container spacing={1} className={"sectionContainer"}>
                <Grid item xs={3}>
                    Sélection du fichier à importer
                </Grid>
                <Grid item xs={9}>
                    <Grid container>
                        <form>
                            <input
                                id="csv-input"
                                type="file"
                                accept="text/csv"
                                ref={inputFileRef}
                                style={{display: 'none'}}
                                onChange={(event) => {
                                    handleCSVUpload(event.target.files[0])
                                }}
                            />
                            <label htmlFor="csv-input">
                                <Button variant="contained" component="span" onClick={handleResetFileInput}>
                                    Charger le fichier
                                </Button>
                            </label>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={1} className={"sectionContainer mt-2"}>
                {currentStep >= 1 &&
                    <Grid container>
                        <Grid item xs={1} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            {currentStep <= 1 && !checkError && <HourglassTop/>}
                            {currentStep > 1 && !checkError && <Done color={"secondary"}/>}
                            {checkError && <Error color={"error"}/>}
                        </Grid>
                        <Grid item xs={11}>
                            <p>
                                Vérification du fichier... &nbsp;
                                {checkMessages && <span>{checkMessages}</span>}
                            </p>
                        </Grid>
                    </Grid>
                }
                {currentStep >= 2 &&
                    <Grid container>
                        <Grid item xs={1} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            {currentStep <= 2 && <HourglassTop/>}
                            {currentStep > 2 && errorMessages.length === 0 && <Done color={"secondary"}/>}
                            {errorMessages.length > 0 && <Error color={"error"}/>}
                        </Grid>
                        <Grid item xs={11}>
                            {errorMessages.length > 0 && errorMessages.map((msg, i) => (
                                    <p key={i}>{msg}</p>
                                )
                            )}
                            {consoleMessages &&
                                <p>{consoleMessages}</p>
                            }
                        </Grid>
                    </Grid>
                }
                {currentStep >= 3 && errorMessages.length === 0 &&
                    <Grid container justifyContent={"center"} className={"mt-2"}>
                        <Grid item xs={6}>
                            <Button color={'primary'} variant={"contained"} onClick={handleSubmit}>Valider
                                l'import</Button>
                        </Grid>
                    </Grid>
                }

                {/*<Grid item xs={12}>*/}
                {/*    <ConsoleOutput messages={consoleMessages}/>*/}
                {/*</Grid>*/}
            </Grid>
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        patrimoine: state.patrimoines.patrimoine,
        loading: state.patrimoines.loading,
        error: state.patrimoines.error
    }
}

export default connect(mapStateToProps)(PatrimoineImportPage);
