import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Loader from "./layout/loading/loader";
import ButtonCreate from "./layout/ButtonCreate";
import Constants from "../../utils/constants";
import LaveusesTable from "./laveuse/LaveusesTable";
import {laveusesActions} from "../../modules/laveuses/laveuses.actions";
import {LocalShipping} from "@mui/icons-material";
import PageTitle from "./layout/PageTitle";
import {Grid} from "@mui/material";
import Button from "@mui/material/Button";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered.js";
import MapIcon from "@mui/icons-material/Map.js";
import LaveuseMap from "./laveuse/LaveuseMap.js";

const getMode = () => {
    const storedMode = window.localStorage.getItem("laveuse_view_mode");
    return storedMode || Constants.MODE_LIST;
};

function LaveusesPage({dispatch, ...props}) {
    const [mode, setMode] = useState(getMode())

    useEffect(() => {
        if (!props.laveuses && !props.loading && !props.error) {
            dispatch(laveusesActions.fetchLaveuses())
        }
    }, [props.laveuses, props.loading, props.error]);

    useEffect(() => {
        return () => {
            // dispatch(conteneursActions.clearLaveuses());
        };
    }, [dispatch]);

    const handleModeChange = (value) => {
        window.localStorage.setItem("laveuse_view_mode", value);
        setMode(value)
    }

    const handleDelete = (laveuse) => {
        dispatch(laveusesActions.deleteLaveuse(laveuse.id));
    }

    return (
        <>
            <PageTitle title={"Gestion des laveuses"} icon={<LocalShipping/>}/>
            {props.loading &&
                <Loader/>
        }
            {!props.loading &&
                <>
                    <Grid container justifyContent={"center"} spacing={2} className={"mb-2"}>
                        <Grid item>
                            <Button
                                variant={getMode() === Constants.MODE_LIST ? "contained" : "outlined"}
                                color="primary"
                                startIcon={<FormatListNumberedIcon/>}
                                onClick={() => handleModeChange(Constants.MODE_LIST)}
                            >
                                Affichage liste
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant={getMode() === Constants.MODE_MAP ? "contained" : "outlined"}
                                color="primary"
                                startIcon={<MapIcon/>}
                                onClick={() => handleModeChange(Constants.MODE_MAP)}
                            >
                                Affichage carte
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid container>
                        {mode === Constants.MODE_MAP &&
                            <>
                                <LaveuseMap laveuses={props.laveuses} />
                            </>
                        }

                        {mode === Constants.MODE_LIST &&
                            <>
                                <ButtonCreate route={Constants.APP_ROUTE_ADMIN_LAVEUSES_NEW}
                                              label="Ajouter une laveuse"/>
                                <LaveusesTable laveuses={props.laveuses} onDelete={handleDelete}/>
                            </>
                        }
                    </Grid>
                </>
            }
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        laveuses: state.laveuses.laveuses,
        loading: state.laveuses.loading,
        error: state.laveuses.error
    }
}

export default connect(mapStateToProps)(LaveusesPage);
