import React, {useState} from 'react';
import {Field} from 'formik';
import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField, ToggleButton, ToggleButtonGroup,
    Typography
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {parseISO} from "date-fns";
import CampaignFrequencyField from "./CampaignFrequencyField";


function CampaignDataField({...props}) {
    const {values, errors, clients, handleChange, handleBlur, onClientSelected} = props;

    const findClientById = (clientId) => {
        return clients?.find((client) => client['@id'] === clientId);

    }

    const [selectedClient, setSelectedClient] = useState(findClientById(values.client))

    const handleClientSelected = (clientId) => {
        setSelectedClient(findClientById(clientId))
        onClientSelected(clientId)
    }


    return (
        <Grid container spacing={1} className={"sectionContainer"}>
            <Grid item xs={12}>
                <Typography variant={"h5"} className={"sectionHeader"}>Informations</Typography>
            </Grid>

            {/*LIBELLE FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Libellé <span className="asterix">*</span>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        value={values.libelle}>
                        {({field, form}) => (
                            <TextField
                                error={
                                    Boolean(form.errors.libelle && form.touched.libelle)
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.libelle}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="libelle"
                                label="libelle"
                                autoFocus
                                helperText={
                                    form.errors.libelle &&
                                    form.touched.libelle &&
                                    String(form.errors.libelle)
                                }/>
                        )}
                    </Field>
                </Grid>
            </Grid>

            <CampaignFrequencyField values={values} errors={errors} handleChange={handleChange}
                                    handleBlur={handleBlur}/>


            {/*CLIENT FIELD*/}
            <Grid item container alignItems={"center"} className={"mt-2"}>
                <Grid item xs={3}>
                    Client <span className="asterix">*</span>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        name="client"
                        value={values.client}>
                        {({field, form}) => (
                            <FormControl fullWidth error={Boolean(errors.client)}>
                                <InputLabel id="label-client">Sélectionner le client :</InputLabel>
                                <Select
                                    labelId="label-client"
                                    label="Sélectionner le client:"
                                    variant="outlined"
                                    name="client"
                                    value={values.client}
                                    onChange={(e) => {
                                        handleClientSelected(e.target.value)
                                        handleChange(e)
                                    }}
                                >
                                    {clients && clients.map((c) => {
                                        return (
                                            <MenuItem key={c.id} value={c["@id"]}>
                                                {c.libelle}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                                {errors.client &&
                                    String(errors.client) &&
                                    <FormHelperText error={true}>{errors.client}</FormHelperText>}
                            </FormControl>
                        )
                        }
                    </Field>
                </Grid>
            </Grid>

            {/*FORMS FIELD*/}
            <Grid item container alignItems={"center"} className={"mt-2"}>
                <Grid item xs={3}>
                    Formulaire à utiliser <span className="asterix">*</span>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        name="client"
                        value={values.formulaire}>
                        {({field, form}) => (
                            <FormControl fullWidth error={Boolean(errors.formulaire)}>
                                <InputLabel id="label-formulaire">Sélectionner le formulaire :</InputLabel>
                                <Select
                                    labelId="label-formulaire"
                                    label="Sélectionner le formulaire :"
                                    variant="outlined"
                                    name="formulaire"
                                    value={values.formulaire}
                                    onChange={handleChange}
                                >

                                    {selectedClient && selectedClient.forms.map((f) => {
                                        return (
                                            <MenuItem key={f.id} value={f["@id"]}>
                                                {f.libelle}
                                            </MenuItem>
                                        )
                                    })}

                                </Select>
                                {errors.formulaire &&
                                    String(errors.formulaire) &&
                                    <FormHelperText error={true}>{errors.formulaire}</FormHelperText>}
                            </FormControl>
                        )
                        }
                    </Field>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CampaignDataField;
