import React from 'react';
import './NotFound.css';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";

function ModalDelete({label, onClose, open, selectedValue, onDelete, objectToDelete, ...props}) {

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Confirmer la suppression
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {label} <b>{objectToDelete}</b>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color={"error"} variant={"contained"}>Annuler</Button>
                <Button onClick={onDelete} color={"success"} variant={"contained"}>
                    Supprimer
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ModalDelete;
