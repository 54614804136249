import {
    deleteHeaders,
    fetchHeaders,
    patchHeaders,
    postHeaders,
} from "../../utils/headers";
import Constants from "../../utils/constants";

export const campaignsService = {
    fetchCampaigns,
    fetchCampaign,
    deleteCampaign,
    createCampaign,
    updateCampaign,
};

function fetchCampaigns() {
    const requestOptions = fetchHeaders()

    return fetch(Constants.API_ROUTE_CAMPAIGNS, requestOptions)
        .then(handleResponse)
        .then(campaigns => {
            return campaigns["hydra:member"]
        });
}

function fetchCampaign(id) {
    const requestOptions = fetchHeaders()

    let url = Constants.API_ROUTE_CAMPAIGN.replace(':id', id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(campaign => {
            return campaign
        });
}

function createCampaign(values) {
    const requestOptions = postHeaders(values)

    let url = Constants.API_ROUTE_CAMPAIGNS;

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function updateCampaign(values) {
    const requestOptions = patchHeaders(values)
    let url = Constants.API_ROUTE_CAMPAIGN.replace(':id', values.id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function deleteCampaign(id) {
    const requestOptions = deleteHeaders()

    let url = Constants.API_ROUTE_CAMPAIGN.replace(':id', id);
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return id
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {

            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
