import React, {useEffect} from 'react';
import {connect} from "react-redux";
import Loader from "./layout/loading/loader";
import AodIcon from '@mui/icons-material/Aod';
import PageTitle from "./layout/PageTitle";
import {Link, useLocation, useParams} from "react-router-dom";
import {conteneursActions} from "../../modules/conteneurs/conteneurs.actions";
import InterventionTable from "./interventions/InterventionTable";
import Constants from "../../utils/constants";
import {synthesesActions} from "../../modules/syntheses/syntheses.actions";
import InterventionView from "./interventions/InterventionView";
import {Button, Card, CardContent, CardHeader, Chip, Grid, Typography} from "@mui/material";
import {interventionsActions} from "../../modules/interventions/interventions.actions";
import {patrimoinesActions} from "../../modules/patrimoines/patrimoines.actions";
import {helper} from "../../utils/helper";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

function InterventionsPage({dispatch, ...props}) {
    const params = useParams();
    const location = useLocation();
    const isForPatrimoine = location.pathname.includes("patrimoine");

    const sourceInterventions = isForPatrimoine ? props.patrimoine?.interventions : props.conteneur?.interventions;
    const [firstIntervention, ...restInterventions] = sourceInterventions || [];


    useEffect(() => {
        if (!isForPatrimoine) {
            if (!props.conteneur && !props.loading && !props.error) {
                dispatch(conteneursActions.fetchConteneur(params.id))
            }
        } else {
            if (!props.patrimoine && !props.loading && !props.error) {
                dispatch(patrimoinesActions.fetchPatrimoineWithInterventions(params.id))
            }
        }
    }, [props.conteneur, props.loading, props.error]);

    useEffect(() => {
        return () => {
            dispatch(conteneursActions.clearConteneurs());
        };

    }, [dispatch]);

    const onPDFClick = (interventionId, interventionDate) => {
        dispatch(synthesesActions.fetchInterventionPDF(interventionId, interventionDate))

    }


    return (
        <React.Fragment>
            <PageTitle title={"Liste des interventions " + props.conteneur?.nomenclature} icon={<AodIcon/>}/>

            {props.loading &&
                <Loader />
            }
            {!props.loading &&
                <>
                    {!isForPatrimoine &&
                        <h3>{props.conteneur?.nomenclature} : {props.conteneur?.adresse} {props.conteneur?.commune}</h3>
                    }
                    {isForPatrimoine &&
                        <h3>{props.patrimoine?.libelle} : {props.patrimoine?.lieu}</h3>
                    }

                    <Grid container sx={{marginTop: "4rem"}} spacing={2}>
                        <Grid item xs={12} md={6}>
                            <h3>Détail du conteneur</h3>
                            <Card>
                                <CardContent>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant={'body'}>{props.conteneur?.adresse}</Typography>
                                        </Grid>
                                        <Grid item xs={12} className={"mt-2"}>
                                            <Grid container>
                                                <Grid item xs={8}>
                                                    <Grid container>
                                                        <Grid item xs={2}>
                                                            <Chip size={'small'} label={props.conteneur?.dechetType}
                                                                  sx={helper.getColorForDechetType(props.conteneur?.dechetType)}/>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Chip size={'small'}
                                                                  label={props.conteneur?.conteneurType}/>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{padding: '.5rem'}}>
                                                            <Typography>
                                                                Nomenclature {props.conteneur?.nomenclature}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{padding: '.5rem'}}>
                                                            <Typography>
                                                                Nombre
                                                                de {helper.getConteneurTypeLibelle(props.conteneur?.conteneurType)} : {props.conteneur?.quantite}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{padding: '.5rem'}}>
                                                            {helper.formatDate(props.conteneur?.lastInterventionDate) !== '' &&
                                                                <Typography>Dernier lavage
                                                                    le {helper.formatDate(props.conteneur?.lastInterventionDate)}</Typography>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {firstIntervention &&
                                <>
                                    <h3>Rapport de dernière intervention</h3>
                                    <InterventionView intervention={firstIntervention} onPDFClick={onPDFClick}
                                                      patrimoine={props.patrimoine}
                                                      conteneur={props.conteneur}
                                                      className={"mt-2"}/>
                                </>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {restInterventions &&
                                <>
                                    <h3>Historique des interventions</h3>
                                    <InterventionTable interventions={restInterventions} onPDFClick={onPDFClick}/>
                                </>
                            }
                        </Grid>
                    </Grid>


                </>

            }
        </React.Fragment>
    )
}

const mapStateToProps = function (state) {
    return {
        conteneur: state.conteneurs.conteneur,
        patrimoine: state.patrimoines.patrimoine,
        loading: state.conteneurs.loading || state.patrimoines.loading,
        error: state.conteneurs.error || state.patrimoines.error
    }
}

export default connect(mapStateToProps)(InterventionsPage);
