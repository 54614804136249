import React, {useEffect, useMemo, useRef} from 'react';
import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet';
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import Constants from "../../../utils/constants";
import {helper} from "../../../utils/helper";
import {Icon, divIcon} from 'leaflet';
import localforage from "localforage";
import CustomTileLayer from "../layout/map/CustomTileLayer.js";

function LaveuseMap({laveuses, ...props}) {
    const [center, setCenter] = React.useState([46.603354, 1.888334]); // Centre France
    const mapRef = React.useRef(null);

    useEffect(() => {
        if (mapRef.current) {
            const mapInstance = mapRef.current;
            mapInstance.setView(center, 13);
        }
    }, [center]);

    function getIcon() {
        let imgSrc = "/images/mini_laveuse.webp";
        return divIcon({
            className: 'my-div-icon',
            html: `<img class="marker-image" src="${imgSrc}"/>`,
            iconSize: [(2284 / 15), (918 / 15)]
        });
    }

    const onMarkerClick = (c) => {

    }

    return (
        <MapContainer
            ref={mapRef}
            center={center}
            zoom={8}
            style={{height: "85vh", width: "100%"}}>
            <CustomTileLayer
                maxZoom={19}
            />
            {
                laveuses && laveuses.map((laveuse, i) => {
                    let lat = laveuse.live?.Latitude;
                    let lng = laveuse.live?.Longitude;

                    if (!lat && !lng) return;

                    return (
                        <Marker
                            key={`${i}-${lat}-${lng}`}
                            position={[lat, lng]}
                            icon={getIcon()}
                            eventHandlers={{
                                click: () => {
                                    onMarkerClick(laveuse);
                                },
                            }}
                        >
                            <Popup>
                                <div>
                                    <h3>{laveuse.immatriculation}</h3>
                                    <p><strong>Description: </strong>{laveuse.description}</p>
                                    <p><strong>Opérateur: </strong>{laveuse.operateur?.libelle || "Non affectée"}</p>
                                    <p>
                                        <strong>Démarrage moteur : </strong>
                                        {helper.getFormattedTime(laveuse.ignitionOn, false) || "Pas encore démarré"}
                                    </p>
                                    <p>
                                        <strong>Arrêt moteur : </strong>
                                        {helper.getFormattedTime(laveuse.ignitionOff, false)}
                                    </p>
                                    <p><strong>Info Live: </strong>{laveuse.live?.LocationText}</p>
                                </div>
                            </Popup>
                        </Marker>
                    )
                })

            }
        </MapContainer>
    )
}

export default LaveuseMap;
