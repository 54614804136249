import React from 'react';
import {Field} from 'formik';
import {
    FormControl, FormHelperText,
    Grid, InputLabel, MenuItem, Select,
    TextField,
    Typography
} from "@mui/material";


function LaveuseDataField({...props}) {
    const {values, errors, handleChange, handleBlur, operateurs} = props;

    return (
        <Grid container spacing={1} className={"sectionContainer"}>
            <Grid item xs={12}>
                <Typography variant={"h5"} className={"sectionHeader"}>Informations</Typography>
            </Grid>

            {/*IMMAT FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Immatriculation <span className="asterix">*</span>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        value={values.immatriculation}>
                        {({field, form}) => (
                            <TextField
                                error={
                                    Boolean(form.errors.immatriculation && form.touched.immatriculation)
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.immatriculation}
                                variant="outlined"
                                margin="normal"
                                size="small"
                                fullWidth
                                name="immatriculation"
                                label="immatriculation"
                                autoFocus
                                helperText={
                                    form.errors.immatriculation &&
                                    form.touched.immatriculation &&
                                    String(form.errors.immatriculation)
                                }/>
                        )}
                    </Field>
                </Grid>
            </Grid>

            {/*DESCRIPTION FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Description
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        value={values.description}>
                        {({field, form}) => (
                            <TextField
                                error={
                                    Boolean(form.errors.description && form.touched.description)
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.description}
                                variant="outlined"
                                margin="normal"
                                size="small"
                                fullWidth
                                name="description"
                                label="description"
                                autoFocus
                                helperText={
                                    form.errors.description &&
                                    form.touched.description &&
                                    String(form.errors.description)
                                }/>
                        )}
                    </Field>
                </Grid>
            </Grid>


            {/*QUARTIX ID FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Identifiant Quartix
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        value={values.quartixId}>
                        {({field, form}) => (
                            <TextField
                                error={
                                    Boolean(form.errors.quartixId && form.touched.quartixId)
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.quartixId}
                                variant="outlined"
                                margin="normal"
                                size="small"
                                fullWidth
                                name="quartixId"
                                label="quartixId"
                                autoFocus
                                helperText={
                                    form.errors.quartixId &&
                                    form.touched.quartixId &&
                                    String(form.errors.quartixId)
                                }/>
                        )}
                    </Field>
                </Grid>
            </Grid>

            {/*CO2 EMISSION FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Emission co2 en g/km
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        value={values.emissionCo2}>
                        {({field, form}) => (
                            <TextField
                                error={
                                    Boolean(form.errors.emissionCo2 && form.touched.emissionCo2)
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.emissionCo2}
                                variant="outlined"
                                margin="normal"
                                size="small"
                                fullWidth
                                name="emissionCo2"
                                label="emissionCo2"
                                autoFocus
                                helperText={
                                    form.errors.emissionCo2 &&
                                    form.touched.emissionCo2 &&
                                    String(form.errors.emissionCo2)
                                }/>
                        )}
                    </Field>
                </Grid>
            </Grid>

            {/*OPERATEUR FIELDS*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    <Typography>Affecter à l'opérateur</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        name={"operateur"}
                        value={values.operateur}>
                        {({form, push, remove}) => (
                            <FormControl fullWidth error={Boolean(errors.operateur)}>
                                <InputLabel id="label-operateur">Sélectionner l'opérateur :</InputLabel>
                                <Select
                                    labelId="label-operateur"
                                    label="Sélectionner l'opérateur :"
                                    variant="outlined"
                                    name="operateur"
                                    value={values.operateur}
                                    onChange={handleChange}
                                >
                                    {operateurs && operateurs.map((o) => {
                                        return (
                                            <MenuItem key={o.id} value={o["@id"]}>
                                                {o.libelle ?? o.email}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                                {errors.operateur &&
                                    String(errors.operateur) &&
                                    <FormHelperText error={true}>{errors.operateur}</FormHelperText>}
                            </FormControl>
                        )}
                    </Field>
                </Grid>
            </Grid>

        </Grid>
    );
}

export default LaveuseDataField;
