import {formsConstants} from "./forms.constants";
import {formsService} from "./forms.service";
import {toastHelper} from "../../utils/toast-helper";
import Constants from "../../utils/constants";
import history from "../../utils/history";

export const formsActions = {
    fetchForms,
    fetchForm,
    deleteForm,
    createForm,
    updateForm,
    clearForms
};

function fetchForms(pagination = false) {
    return dispatch => {
        dispatch(request());

        formsService.fetchForms(pagination)
            .then(
                forms => {
                    dispatch(success(forms));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: formsConstants.FORMS_FETCH_REQUEST}
    }

    function success(forms) {
        return {type: formsConstants.FORMS_FETCH_SUCCESS, forms}
    }

    function failure(error) {
        return {type: formsConstants.FORMS_FETCH_FAILURE, error}
    }
}

function fetchForm(id) {
    return dispatch => {
        dispatch(request());
        formsService.fetchForm(id)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: formsConstants.FORMS_FETCH_ONE_REQUEST}
    }

    function success(form) {
        return {type: formsConstants.FORMS_FETCH_ONE_SUCCESS, form}
    }

    function failure(error) {
        return {type: formsConstants.FORMS_FETCH_ONE_FAILURE, error}
    }
}

function deleteForm(id, navigate) {
    return dispatch => {
        dispatch(request());

        formsService.deleteForm(id)
            .then(
                id => {
                    dispatch(success(id));
                    history.replace(Constants.APP_ROUTE_ADMIN_FORMS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };


    function request() {
        return {type: formsConstants.FORMS_DELETE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("Le formulaire a bien été supprimé.")
        return {type: formsConstants.FORMS_DELETE_SUCCESS, id}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: formsConstants.FORMS_DELETE_FAILURE, error}
    }
}

function createForm(values, navigate) {
    return dispatch => {
        dispatch(request());

        formsService.createForm(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_FORMS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: formsConstants.FORMS_CREATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("Le formulaire a bien été créé.")
        return {type: formsConstants.FORMS_CREATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: formsConstants.FORMS_CREATE_FAILURE, error}
    }
}

function updateForm(values, navigate) {
    return dispatch => {
        dispatch(request());
        formsService.updateForm(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_FORMS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: formsConstants.FORMS_UPDATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("Le formulaire a bien été modifié.")
        return {type: formsConstants.FORMS_UPDATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: formsConstants.FORMS_UPDATE_FAILURE, error}
    }
}

function clearForms() {
    return dispatch => {
        dispatch(request());
    };

    function request() {
        return {type: formsConstants.FORMS_CLEAR_REQUEST}
    }
}
