import React from 'react';
import './PhotoCarousel.css';
import Carousel from "react-material-ui-carousel";
import {Paper, Typography} from "@mui/material";

function PhotoCarousel({photoArray, assetsUrl, headerStyle, label, ...props}) {

    return (
        <Paper className={"carousel-wrapper"}>
            <Typography variant={"h6"} className={"carousel-label " + headerStyle}>{label}</Typography>
            {(!photoArray || photoArray?.length === 0) &&
                <Typography variant={"body1"} sx={{textAlign: 'center', padding: '1rem !important'}}>
                    Aucune photo
                </Typography>
            }
            {photoArray?.length > 0 &&
                <Carousel
                    className={"carousel-container"}
                    animation={"slide"}
                    navButtonsProps={{
                        style: {
                            backgroundColor: 'var(--thm-black)',
                            opacity: 1
                        }
                    }}
                    indicatorIconButtonProps={{
                        style: {}
                    }}
                >
                    {
                        photoArray?.map((photo, i) => {
                            return (
                                <img key={i} src={assetsUrl + photo} alt={"Intervention photo"}
                                     className={"intervention_photo"}/>
                            )
                        })
                    }
                </Carousel>
            }

        </Paper>

    );
}

export default PhotoCarousel;
