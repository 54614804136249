import React, {useEffect} from 'react';
import {Container} from "@mui/material";
import {connect} from "react-redux";
import Loader from "./layout/loading/loader";
import ButtonCreate from "./layout/ButtonCreate";
import Constants from "../../utils/constants";
import {problemsActions} from "../../modules/problems/problems.actions";
import ProblemsTable from "./problems/ProblemsTable";
import {Engineering} from "@mui/icons-material";
import PageTitle from "./layout/PageTitle";

function ProblemsPage({dispatch, ...props}) {

    useEffect(() => {
        if (!props.problems && !props.loading && !props.error) {
            dispatch(problemsActions.fetchProblems())
        }
    }, [dispatch, props.problems, props.loading, props.error]);

    useEffect(() => {
        return () => {
            // dispatch(conteneursActions.clearProblems());
        };
    }, [dispatch]);

    const handleDelete = (problem) => {
        dispatch(problemsActions.deleteProblem(problem.id));
    }

    return (
        <>
            <PageTitle title={"Gestion des problèmes"} icon={<Engineering/>}/>
            <ButtonCreate route={Constants.APP_ROUTE_ADMIN_PROBLEMS_NEW} label="Ajouter un problème"/>
            {props.loading &&
                <Loader />
            }
            {!props.loading &&
                <ProblemsTable problems={props.problems} onDelete={handleDelete}/>
            }
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        problems: state.problems.problems,
        loading: state.problems.loading,
        error: state.problems.error
    }
}

export default connect(mapStateToProps)(ProblemsPage);
