import React from 'react';
import {Field} from 'formik';
import {
    Autocomplete,
    Box, debounce,
    Grid,
    TextField, Typography,
} from "@mui/material";
import parse from 'autosuggest-highlight/parse';
import {LocationOn} from "@mui/icons-material";

const autocompleteService = {current: null};

function PlaceAutocomplete({...props}) {
    const {values, errors, handleChange, handleBlur} = props;


    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const loaded = React.useRef(false);


    const fetch = React.useMemo(
        () =>
            debounce((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 400),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current =
                new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({input: inputValue}, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);


    return (
        <Grid item container alignItems={"center"}>
            <Grid item xs={3}>
                Lieu <span className="asterix">*</span>
            </Grid>
            <Grid item xs={9}>
                <Field
                    type="text"
                    name="lieu"
                    value={values.lieu}
                >
                    {({field, form}) => (
                        <Autocomplete
                            id="lieu"
                            getOptionLabel={(option) =>
                                typeof option === 'string' ? option : option.description
                            }
                            filterOptions={(x) => x}
                            options={options}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            value={value}
                            noOptionsText="Aucun lieu trouvé"
                            onChange={(event, newValue) => {
                                setOptions(newValue ? [newValue, ...options] : options);
                                setValue(newValue);
                                form.setFieldValue('lieu', newValue.description);
                            }}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params}
                                           error={Boolean(form.errors.lieu && form.touched.lieu)}
                                           label="Ajouter un lieu"
                                           variant="outlined"
                                           helperText={
                                               form.errors.lieu &&
                                               form.touched.lieu &&
                                               String(form.errors.lieu)
                                           }
                                           inputProps={{
                                               ...params.inputProps,
                                               autoComplete: 'new-password', // disable autocomplete and autofill
                                           }}
                                           />
                            )}
                            renderOption={(props, option) => {
                                const matches =
                                    option.structured_formatting.main_text_matched_substrings || [];

                                const parts = parse(
                                    option.structured_formatting.main_text,
                                    matches.map((match) => [match.offset, match.offset + match.length]),
                                );

                                return (
                                    <li {...props}>
                                        <Grid container alignItems="center">
                                            <Grid item sx={{display: 'flex', width: 44}}>
                                                <LocationOn sx={{color: 'text.secondary'}}/>
                                            </Grid>
                                            <Grid item sx={{width: 'calc(100% - 44px)', wordWrap: 'break-word'}}>
                                                {parts.map((part, index) => (
                                                    <Box
                                                        key={index}
                                                        component="span"
                                                        sx={{fontWeight: part.highlight ? 'bold' : 'regular'}}
                                                    >
                                                        {/*{part.text}*/}
                                                        {option.description}
                                                    </Box>
                                                ))}

                                                <Typography variant="body2" color="text.secondary">
                                                    {option.structured_formatting.secondary_text}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </li>
                                );
                            }}
                        />
                    )}
                </Field>
            </Grid>
        </Grid>
    );
}

export default PlaceAutocomplete;
