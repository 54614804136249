import React from 'react';
import {Button, Chip, Grid, Paper, Typography} from '@mui/material';
import './PatrimoineDetailItem.css';
import {helper} from "../../../utils/helper";
import {Link} from "react-router-dom";
import Constants from "../../../utils/constants";
import {TravelExplore} from "@mui/icons-material";
import AodIcon from "@mui/icons-material/Aod";

export default function PatrimoineDetailItem({conteneur, ...props}) {

    return (
        <Paper className={"patrimoineDetailItem-container"}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant={'body'}>{conteneur.adresse}</Typography>
                </Grid>
                {/*<Grid item xs={1}>*/}
                {/*{conteneur.status && <img src={conteneur.status} className={"ministatus"} alt={'status'}/>}*/}
                {/*</Grid>*/}

                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={3}>
                            <img src={helper.getConteneurStatusIcon(conteneur.status)}/>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container>
                                <Grid item xs={2}>
                                    <Chip size={'small'} label={conteneur.dechetType}
                                          sx={helper.getColorForDechetType(conteneur.dechetType)}/>
                                    {/*<Typography variant={'caption'}>{conteneur.dechetType}</Typography>*/}
                                </Grid>
                                <Grid item xs={2}>
                                    <Chip size={'small'} label={conteneur.conteneurType}/>
                                </Grid>
                                <Grid item xs={12} sx={{padding: '.5rem'}}>
                                    <Typography>Nombre
                                        de {helper.getConteneurTypeLibelle(conteneur.conteneurType)} : {conteneur.quantite}</Typography>
                                </Grid>
                                <Grid item xs={12} sx={{padding: '.5rem'}}>
                                    Status : {helper.getConteneurStatusName(conteneur.status)}
                                </Grid>
                                <Grid item xs={12} sx={{padding: '.5rem'}}>
                                    {helper.formatDate(conteneur.lastInterventionDate) !== '' &&
                                        <Typography>Dernier lavage
                                            le {helper.formatDate(conteneur.lastInterventionDate)}</Typography>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    {/*{conteneur.status !== Constants.CONTENEURS_STATUS_TODO &&*/}
                                    <Link
                                        to={Constants.APP_ROUTE_INTERVENTIONS.replace(":id", conteneur["@id"].split("/").pop())}>
                                        <Button color="primary" size="small" variant={"contained"}
                                                startIcon={<AodIcon/>}>
                                            Rapport d'intervention
                                        </Button>
                                    </Link>
                                    {/*}*/}

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Paper>);
}
