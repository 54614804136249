import {synthesesConstants} from "./syntheses.constants";
import {synthesesService} from "./syntheses.service";
import {toastHelper} from "../../utils/toast-helper";
import Constants from "../../utils/constants";
import history from "../../utils/history";
import {helper} from "../../utils/helper";

export const synthesesActions = {
    fetchInterventionPDF,
    fetchSynthese
};

function fetchInterventionPDF(interventionId, interventionDate) {
    return dispatch => {
        dispatch(request());

        synthesesService.fetchInterventionPDF(interventionId);
    };

    function request() {
        return {type: synthesesConstants.SYNTHESE_FICHE_INTERVENTION_FETCH_REQUEST}
    }

    function failure(error) {
        return {type: synthesesConstants.SYNTHESE_FICHE_INTERVENTION_FETCH_FAILURE, error}
    }
}

function fetchSynthese(values) {
    return dispatch => {
        synthesesService.fetchSynthese(values)
    };

}