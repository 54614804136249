import React, {useEffect} from 'react';
import {Container} from "@mui/material";
import {connect} from "react-redux";
import Loader from "./layout/loading/loader";
import ButtonCreate from "./layout/ButtonCreate";
import Constants from "../../utils/constants";
import {formsActions} from "../../modules/forms/forms.actions";
import FormsTable from "./forms/FormsTable";
import {Article} from "@mui/icons-material";
import PageTitle from "./layout/PageTitle";

function FormsPage({dispatch, ...props}) {

    useEffect(() => {
        if (!props.forms && !props.loading && !props.error) {
            dispatch(formsActions.fetchForms())
        }
    }, [dispatch, props.forms, props.loading, props.error]);

    useEffect(() => {
        return () => {
            // dispatch(conteneursActions.clearProblems());
        };
    }, [dispatch]);

    const handleDelete = (form) => {
        dispatch(formsActions.deleteForm(form.id));
    }

    return (
        <>
            <PageTitle title={"Gestion des formulaires"} icon={<Article/>}/>
            <ButtonCreate route={Constants.APP_ROUTE_ADMIN_FORMS_NEW} label="Ajouter un formulaire"/>
            {props.loading &&
                <Loader />
            }
            {!props.loading &&
                <FormsTable forms={props.forms} onDelete={handleDelete}/>
            }
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        forms: state.forms.forms,
        loading: state.forms.loading,
        error: state.forms.error
    }
}

export default connect(mapStateToProps)(FormsPage);
