import React from 'react';
import {
    Button,
    Chip,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import './PatrimoineDetailItem.css';
import {helper} from "../../../utils/helper";
import TableHeader from "../layout/table/TableHeader";
import Constants from "../../../utils/constants";
import {TravelExplore} from "@mui/icons-material";
import {Link, useNavigate} from "react-router-dom";
import AodIcon from "@mui/icons-material/Aod";

export default function PatrimoineDetailList({conteneurs, patrimoine, ...props}) {
    const navigate = useNavigate();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('ordreCsv');

    // Table head cells
    const headCells = patrimoine?.isUnipatrimoine ? [
        {id: 'nomenclature', label: 'Nomenclature'},
        {id: 'adresse', label: 'Adresse'},
        {id: 'nombreDeBacs', label: 'Nombre de bacs'},
        {id: 'lastInterventionDate', label: 'Dernier lavage'},
        {id: 'status', label: "Status"},
    ] : [
        {id: 'ordreCsv', label: 'Ordre'},
        {id: 'nomenclature', label: 'Nomenclature'},
        {id: 'adresse', label: 'Adresse'},
        {id: 'commune', label: 'Commune'},
        {id: 'quantite', label: 'Nombre de bacs'},
        {id: 'type', label: 'Type'},
        {id: 'dechetType', label: 'Type déchet'},
        {id: 'lastInterventionDate', label: 'Dernier lavage'},
        {id: 'status', label: "Status"},
    ];



    const getItemComparator = (item, comparator) => {
        if (comparator === "type") {
            return item.conteneurType
        }
        if (comparator === "lastInterventionDate") {
            return new Date(item.lastInterventionDate).getTime();
        }
        if (comparator === "ordreCsv") {
            // Utilise parseInt pour convertir l'ID en nombre
            return parseInt(item[comparator], 10);
        }
        return item[comparator];
    }
    const sortedData = () => {
        return conteneurs.sort((a, b) => {
            if (order === 'asc') {
                return (getItemComparator(a, orderBy) > getItemComparator(b, orderBy)) ? 1 : (getItemComparator(b, orderBy) > getItemComparator(a, orderBy)) ? -1 : 0;
            }
            return (getItemComparator(a, orderBy) < getItemComparator(b, orderBy)) ? 1 : (getItemComparator(b, orderBy) < getItemComparator(a, orderBy)) ? -1 : 0
        });
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleRowClick = (conteneurId) => {
        navigate(Constants.APP_ROUTE_INTERVENTIONS.replace(":id", conteneurId));
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHeader headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort}/>
                <TableBody>
                    {conteneurs && sortedData().map((c, i) => {
                        let cId = c['@id'].split("/").pop()
                        return (
                            <TableRow
                                hover
                                key={i}
                                sx={{cursor: 'pointer', '&:last-child td, &:last-child th': {border: 0}}}
                                onClick={() => handleRowClick(cId)}
                            >
                                <TableCell component="th" scope="row">{c.ordreCsv}</TableCell>
                                <TableCell component="th" scope="row">{c.nomenclature}</TableCell>
                                <TableCell component="th" scope="row">{c.adresse}</TableCell>
                                <TableCell component="th" scope="row">{c.commune}</TableCell>
                                <TableCell component="th" scope="row">{c.quantite}</TableCell>
                                <TableCell component="th" scope="row">{c.conteneurType}</TableCell>
                                <TableCell align="center">
                                    <Chip size={'small'} label={c.dechetType}
                                          sx={helper.getColorForDechetType(c.dechetType)}/>
                                </TableCell>
                                <TableCell>
                                    {helper.formatDate(c.lastInterventionDate)}
                                </TableCell>
                                <TableCell align="center">
                                    <Chip size={'small'} label={helper.getConteneurStatusName(c.status)}
                                          sx={helper.getColorForStatus(c.status)}/>
                                </TableCell>
                                <TableCell>
                                    {/*{c.status !== Constants.CONTENEURS_STATUS_TODO &&*/}
                                    <Link
                                        to={Constants.APP_ROUTE_INTERVENTIONS.replace(":id", cId)}
                                        onClick={(e) => e.stopPropagation()}>
                                        <Button color="primary" size="small" variant={"contained"}
                                                startIcon={<TravelExplore/>}>
                                            Détail
                                        </Button>
                                    </Link>
                                    {/*}*/}
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        )
                    })}
                    {patrimoine?.isUnipatrimoine &&
                        <TableRow>
                            <TableCell component="th" scope="row">{patrimoine.id}</TableCell>
                            <TableCell component="th" scope="row">{patrimoine.lieu}</TableCell>
                            <TableCell align="center">{patrimoine.nombreDeBacs}</TableCell>
                            <TableCell> {helper.formatDate(patrimoine.lastInterventionDate)}</TableCell>
                            <TableCell align="center">
                                <Chip size={'small'} label={helper.getConteneurStatusName(patrimoine.status)}
                                      sx={helper.getColorForStatus(patrimoine.status)}/>
                            </TableCell>
                            <TableCell>
                                {patrimoine?.isUnipatrimoine &&
                                    <Link
                                        to={Constants.APP_ROUTE_INTERVENTIONS_UNIPATRIMOINE.replace(":id", patrimoine.id)}
                                        onClick={(e) => e.stopPropagation()}>
                                        <Button color="primary" size="small" variant={"contained"}
                                                startIcon={<AodIcon/>}>
                                            Rapport d'intervention
                                        </Button>
                                    </Link>
                                }
                                {patrimoine?.campagnesLavage?.[0]?.interventions?.[0]?.conteneurId &&
                                    <Link
                                        to={Constants.APP_ROUTE_INTERVENTIONS.replace(":id", patrimoine.campagnesLavage[0].interventions[0].conteneurId)}>
                                        <Button color="primary" size="small" variant={"contained"}
                                                startIcon={<AodIcon/>}>
                                            Détail
                                        </Button>
                                    </Link>
                                }
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}
