import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import {usersActions} from "../../modules/users/users.actions";
import Loader from "./layout/loading/loader";
import {Formik} from "formik";
import './UsersNewOrEdit.css'
import {boolean, number, object, string} from 'yup';
import {Container, Grid} from "@mui/material";
import {useParams} from "react-router-dom";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SubmitButton from "./layout/forms/SubmitButton";
import {formsActions} from "../../modules/forms/forms.actions";
import FormDataField from "./forms/form/FormDataField";
import {Article} from "@mui/icons-material";
import PageTitle from "./layout/PageTitle";
import Constants from "../../utils/constants.js";


const formValidationSchema = object({
    libelle: string()
        .required("Le libellé est requis")
        .max(255, 'Le libellé ne doit pas dépasser 255 caractères'),
    type: string()
        .required("Le type de conteneur est requis")
        .max(255, 'Le type ne doit pas dépasser 255 caractères'),
    hasQrcode: boolean(),
    hasBarcode: boolean(),
    hasRFID: boolean(),
    hasVolume: boolean(),
    hasWaste: boolean(),
    client: string()
        .required("Veuillez affecter le formulaire à un client")
        .max(255, 'Le client ne doit pas dépasser 255 caractères'),
});


function FormsNewOrEdit({...props}) {
    const dispatch = useDispatch();
    const params = useParams();
    const [mode, setMode] = useState("create");
    const [formId, setFormId] = useState(null);

    useEffect(() => {
        if (params.hasOwnProperty("id")) {
            setMode("edit");
            setFormId(params.id);
        } else {
            setMode("create");
            setFormId(null);
        }
    }, [params]);

    useEffect(() => {
        if (formId && (props.form?.id !== parseInt(formId)) && !props.loading && !props.error) {
            dispatch(formsActions.fetchForm(formId));
        }
    }, [props, dispatch, formId]);

    useEffect(() => {
        if (!props.clients && !props.loading && !props.error) {
            dispatch(usersActions.fetchClients());
        }
    }, [props, dispatch]);


    const handleSubmit = (values) => {
        if (mode === "create") {
            dispatch(formsActions.createForm(values));
        } else {
            dispatch(formsActions.updateForm(values, values.id));
        }
    }

    const initialValues = {
        id: props.form?.id ?? null,
        libelle: props.form?.libelle || '',
        type: props.form?.type || Constants.TYPOLOGIE_LAVAGE_AB_PLUS_B,
        hasQrcode: props.form?.hasQrcode ?? false,
        hasBarcode: props.form?.hasBarcode ?? false,
        hasRFID: props.form?.hasRFID ?? false,
        hasVolume: props.form?.hasVolume ?? false,
        hasWaste: props.form?.hasWaste ?? false,
        client: props.form?.client['@id'] || null,
        minPhotoAvant: props.form?.minPhotoAvant || 1,
        minPhotoApres: props.form?.minPhotoApres || 1,
        minPhotoMaintenance: props.form?.minPhotoMaintenance || 0,
        minPhotoLavageImpossible: props.form?.minPhotoLavageImpossible || 0,
    };

    if (props.loading) {
        return <Loader/>;
    }

    return (
        <>
            <PageTitle title={mode === "create" ? "Nouveau Formulaire" : "Modifier le formulaire"} icon={<Article/>}/>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={formValidationSchema}
                onSubmit={(values, {setSubmitting}) => {
                    handleSubmit(values)
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <FormDataField clients={props.clients} values={values} errors={errors}
                                       handleChange={handleChange}
                                       handleBlur={handleBlur}/>

                        <Grid container justifyContent="center" style={{marginTop: '2rem'}}>
                            <Grid item xs={4}>
                                <SubmitButton submitting={isSubmitting && !props.error}/>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        clients: state.users.clients,
        form: state.forms.form,
        loading: state.forms.loading || state.users.loading,
        error: state.forms.error || state.users.error
    }
}

export default connect(mapStateToProps)(FormsNewOrEdit);
