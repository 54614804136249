import {authenticationConstants} from './auth.constants';
import {authService} from './auth.service';
import {helper} from "../../utils/helper";
import {toastHelper} from "../../utils/toast-helper";
import Constants from "../../utils/constants";
import history from "../../utils/history";

export const authActions = {
    login,
    logout,
    askResetPassword,
    resetPassword
};

function login(username, password) {
    return dispatch => {
        dispatch(request({username}));


        authService.login(username, password)
            .then(
                user => {
                    let data = helper.storeJwt(user);
                    dispatch(success(data));
                    history.replace(Constants.APP_ROUTE_HOME);
                },
                error => {
                    // toastHelper.handleError(error, "Erreur, identifiants invalides")
                    toastHelper.handleError(error)
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(user) {
        return {type: authenticationConstants.LOGIN_REQUEST, user}
    }

    function success(user) {
        return {type: authenticationConstants.LOGIN_SUCCESS, user}
    }

    function failure(error) {
        return {type: authenticationConstants.LOGIN_FAILURE, error}
    }
}

function logout() {
    return dispatch => {
        return new Promise(resolve => {

            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('clientSelected');

            dispatch({type: authenticationConstants.LOGOUT});

            resolve();
        })
    }
}

/**
 * Forgot password function
 * @param username
 * @returns {function(...[*]=)}
 */
function askResetPassword(username) {
    return dispatch => {
        dispatch(request({username}));

        authService.askResetPassword(username)
            .then(
                user => {
                    dispatch(success(user));
                    history.replace(Constants.APP_ROUTE_HOME);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(user) {
        return {type: authenticationConstants.ASK_RESET_PASSWORD_REQUEST, user}
    }

    function success(user) {
        toastHelper.displaySuccess("Un email vous a été envoyé pour réinitialiser votre mot de passe!")
        return {type: authenticationConstants.ASK_RESET_PASSWORD_SUCCESS, user}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: authenticationConstants.ASK_RESET_PASSWORD_FAILURE, error}
    }
}

/**
 * Reset password function
 * @param password
 * @param token
 * @param password
 * @param token
 * @param password
 */
function resetPassword(token, password) {
    return dispatch => {
        dispatch(request());

        authService.resetPassword(token, password)
            .then(
                user => {
                    dispatch(success(user));
                    history.replace(Constants.APP_ROUTE_LOGIN);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: authenticationConstants.RESET_PASSWORD_REQUEST}
    }

    function success() {
        toastHelper.displaySuccess("Votre mot de passe a été correctement réinitialisé")
        return {type: authenticationConstants.RESET_PASSWORD_SUCCESS}
    }

    function failure(error) {
        return {type: authenticationConstants.RESET_PASSWORD_FAILURE, error}
    }
}

