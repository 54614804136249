import React from 'react';
import {Navigate} from 'react-router-dom';

const RequireAuth = ({loggedIn, role, restrictedRoles, redirectTo, children}) => {
    return loggedIn && checkFirewall(restrictedRoles, role) ? (
        children
    ) : (
        <Navigate to={redirectTo}/>
    );
}

const checkFirewall = (restrictedRoles, role) => {
    if (restrictedRoles.length === 0) return true;

    for (let r of role) {
        if (restrictedRoles.includes(r)) return true;
    }

    return false;
}

export default RequireAuth;
