import React, {useEffect} from 'react';
import {
    Card,
    CardActions,
    CardContent,
    Chip,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select
} from '@mui/material';
import {helper} from "../../../utils/helper";

export default function ClientSelector({
                                           clients,
                                           exploitants,
                                           isAdmin,
                                           onClientSelected,
                                           clientSelected,
                                           exploitantSelected,
                                           onExploitantSelected,
                                           ...props
                                       }) {
    return (
        <React.Fragment>
            <Grid item container alignItems={"center"} className={"mt-2"} spacing={3}>
                {isAdmin &&
                    <>
                        <Grid item xs={3} className={"mb-2"}>
                            Choix de l'exploitant :
                        </Grid>
                        <Grid item xs={9}>
                            <FormControl fullWidth>
                                <InputLabel id="label-exploitant">Sélectionner l'exploitant :</InputLabel>
                                <Select
                                    labelId="label-exploitant"
                                    label="Sélectionner l'exploitant :"
                                    variant="outlined"
                                    value={exploitantSelected ?? ""}
                                    onChange={(event) => {
                                        onExploitantSelected(event.target.value)
                                    }}
                                >
                                    {exploitants && exploitants.map((e) => {
                                        return (
                                            <MenuItem key={e.id} value={e.id}>
                                                {e.libelle}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </>
                }

                <Grid item xs={3}>
                    Choix du client :
                </Grid>
                <Grid item xs={9}>
                    <FormControl fullWidth>
                        <InputLabel id="label-exploitant">Sélectionner le client :</InputLabel>
                        <Select
                            labelId="label-exploitant"
                            label="Sélectionner l'exploitant :"
                            variant="outlined"
                            value={clientSelected ?? ""}
                            onChange={(event) => {
                                onClientSelected(event.target.value)
                            }}
                        >
                            {clients && clients.map((e) => {

                                return (
                                    <MenuItem key={e.id} value={e.id}>
                                        <>
                                            {e.libelle} &nbsp;
                                            {e.typologieConteneurs &&
                                                e.typologieConteneurs.map((type, i) => (
                                                    <Chip key={i} size={'small'} label={type} sx={{
                                                        marginRight: "1rem"
                                                    }}/>
                                                ))
                                            }
                                        </>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
