import React, {useState} from 'react';
import {connect} from "react-redux";
import {authActions} from '../../../modules/authentication/auth.actions'
import {useForm} from "react-hook-form";
import {makeStyles} from "@mui/styles";
import {
    Button,
    Paper,
    CircularProgress,
    Container,
    CssBaseline,
    TextField,
    Typography,
    Grid,
    InputAdornment, IconButton
} from "@mui/material";
import {useParams} from "react-router-dom";
import {Visibility, VisibilityOff} from "@mui/icons-material";

let useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function ResetPassword({dispatch, ...props}) {
    const [isSubmitting, setIsSubmitting] = React.useState()
    const params = useParams();
    const userToken = params?.token
    const classes = useStyles();
    const {register, handleSubmit, watch, errors} = useForm();
    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const handleClickShowPasswordConfirm = () => {
        setShowPasswordConfirm(!showPasswordConfirm);
    };

    const onSubmit = data => {
        setIsSubmitting(true)
        dispatch(authActions.resetPassword(userToken, data.password))
    }
    return (
        <Container component="main" maxWidth="xs" className={"loginContainer"}
                   sx={{backgroundImage: `url(/images/laveuse_background.webp)`}}>
            <CssBaseline/>
            <Paper elevation={24} className={"loginFormContainer"}>
                <div className={"logoWrapper mt-1"}>
                    <img src={`/images/logo.png`} alt="Logo Les Eugene"/>
                </div>
                <Typography component="h1" variant="h5" sx={{textAlign: 'center', marginTop: '3rem'}}>
                    Nouveau mot de passe.
                </Typography>
                <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        <Grid item md={1}></Grid>
                        <Grid item xs={12} md={10}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Mot de passe"
                                type={showPassword ? "text" : "password"}
                                id="password"
                                name="password"
                                {...register("password", {required: true})}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                            {errors && errors.password && <span className="form-error">ce champ est requis</span>}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={1}></Grid>
                        <Grid item xs={12} md={10}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Confirmer votre mot de passe"
                                type={showPasswordConfirm ? "text" : "password"}
                                id="password"
                                name="passwordConfirm"
                                {...register("passwordConfirm", {
                                    required: true, validate: (value) => {
                                        //Validate that confirm password is equal to password
                                        return value === watch('password');
                                    }
                                })}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPasswordConfirm}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPasswordConfirm ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                            {errors && errors.passwordConfirm &&
                                <span className="form-error">Les mots de passes ne correspondent pas</span>}
                        </Grid>
                    </Grid>

                    <Grid container className={"mt-2"}>
                        <Grid item md={1}></Grid>
                        <Grid item xs={12} md={10}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                disabled={isSubmitting && props.error === false}
                                className={classes.submit}
                            >
                                <Typography sx={{color: 'white', padding: '.5rem'}}>
                                    Valider
                                </Typography>
                                {(isSubmitting && props.error === false) &&
                                    <CircularProgress size={24} className="button-progress"/>}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
    );
}

const mapStateToProps = function (state) {
    return {
        error: state.authentication.error,
    }
}

const ResetPasswordPage = connect(mapStateToProps)(ResetPassword)

export default ResetPasswordPage;
