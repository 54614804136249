import React from 'react';
import {
    Button, Checkbox,
    Chip,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer, TableHead, TablePagination,
    TableRow, TableSortLabel,
} from '@mui/material';
import {helper} from "../../../../utils/helper";
import TableHeader from "../../layout/table/TableHeader";
import Constants from "../../../../utils/constants";
import {Link} from "react-router-dom";
import AodIcon from "@mui/icons-material/Aod";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export default function CampaignPatrimoineTable({
                                                    conteneurs,
                                                    selectedConteneurs,
                                                    patrimoine,
                                                    onConteneursSelected,
                                                    ...props
                                                }) {

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('nomenclature');
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [page, setPage] = React.useState(0);

    // Table head cells
    const headCells = patrimoine?.isUnipatrimoine ? [
        {id: 'nomenclature', label: 'Nomenclature'},
        {id: 'adresse', label: 'Adresse'},
        {id: 'nombreDeBacs', label: 'Nombre de bacs'},
        {id: 'lastInterventionDate', label: 'Dernier lavage'},
    ] : [
        {id: 'ordreCsv', label: 'Ordre'},
        {id: 'nomenclature', label: 'Nomenclature'},
        {id: 'adresse', label: 'Adresse'},
        {id: 'commune', label: 'Commune'},
        {id: 'quantite', label: 'Nombre de bacs'},
        {id: 'lastInterventionDate', label: 'Dernier lavage'},
    ];

    const getItemComparator = (item, comparator) => {
        if (comparator === "type") {
            return item.conteneurType
        }
        if (comparator === "lastInterventionDate") {
            return new Date(item.lastInterventionDate).getTime();
        }
        if (comparator === "ordreCsv") {
            // Utilise parseInt pour convertir l'ID en nombre
            return parseInt(item[comparator], 10);
        }
        return item[comparator];
    }
    const sortedData = () => {
        return conteneurs?.sort((a, b) => {
            if (order === 'asc') {
                return (getItemComparator(a, orderBy) > getItemComparator(b, orderBy)) ? 1 : (getItemComparator(b, orderBy) > getItemComparator(a, orderBy)) ? -1 : 0;
            }
            return (getItemComparator(a, orderBy) < getItemComparator(b, orderBy)) ? 1 : (getItemComparator(b, orderBy) < getItemComparator(a, orderBy)) ? -1 : 0
        });
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const onSelectAllClick = () => {
        if (selectedConteneurs?.length === conteneurs?.length) {
            onConteneursSelected([])
        } else {
            onConteneursSelected(conteneurs);
        }
    }

    const startRow = page * rowsPerPage;
    const endRow = startRow + rowsPerPage;

    const paginatedConteneurs = sortedData()?.slice(startRow, endRow);

    const handleCheckboxClick = (conteneur) => {
        const selectedIndex = selectedConteneurs.indexOf(conteneur);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedConteneurs, conteneur);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedConteneurs.slice(1));
        } else if (selectedIndex === selectedConteneurs?.length - 1) {
            newSelected = newSelected.concat(selectedConteneurs.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedConteneurs.slice(0, selectedIndex),
                selectedConteneurs.slice(selectedIndex + 1),
            );
        }

        onConteneursSelected(newSelected);
    }


    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table" size={"small"}>
                    <TableHead>
                        <TableRow className={"tableHeader-row"}>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    indeterminate={selectedConteneurs?.length > 0 && selectedConteneurs?.length < conteneurs?.length}
                                    checked={conteneurs?.length > 0 && selectedConteneurs?.length === conteneurs?.length}
                                    onChange={() => onSelectAllClick()}
                                    inputProps={{
                                        'aria-label': 'select all desserts',
                                    }}
                                />
                            </TableCell>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    sortDirection={orderBy === headCell.id ? order : false}
                                    className={headCell.className}
                                >
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        {headCell.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {conteneurs && paginatedConteneurs?.length > 0 && paginatedConteneurs.map((c, i) => {
                            return (
                                <TableRow
                                    key={i}
                                    sx={{cursor: 'pointer', '&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell>
                                        <Checkbox checked={selectedConteneurs?.includes(c) ?? false}
                                                  onClick={() => handleCheckboxClick(c)}
                                        />
                                    </TableCell>
                                    <TableCell scope="row">{c.ordreCsv}</TableCell>
                                    <TableCell scope="row">{c.nomenclature}</TableCell>
                                    <TableCell scope="row">{c.adresse}</TableCell>
                                    <TableCell scope="row">{c.commune}</TableCell>
                                    <TableCell scope="row">{c.quantite}</TableCell>
                                    <TableCell scope="row">{helper.formatDate(c.lastInterventionDate)}</TableCell>
                                </TableRow>
                            )
                        })}
                        {patrimoine?.isUnipatrimoine &&
                            <TableRow>
                                <TableCell component="th" scope="row">{patrimoine.id}</TableCell>
                                <TableCell component="th" scope="row">{patrimoine.lieu}</TableCell>
                                <TableCell align="center">{patrimoine.nombreDeBacs}</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {conteneurs &&
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100, 200]}
                    component="div"
                    count={conteneurs?.length ?? 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage={"Conteneur par page"}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            }

        </>
    )
}
