import React, {useEffect, useMemo} from 'react';
import Constants from "../../../utils/constants";
import './CampaignMap.css'
import '../../../../node_modules/leaflet/dist/leaflet.css'
import '../../../../node_modules/leaflet-draw/dist/leaflet.draw.css'
import {helper} from "../../../utils/helper";
import {FeatureGroup, MapContainer, Marker, Polygon, TileLayer} from 'react-leaflet';
import {EditControl} from "react-leaflet-draw";
import {divIcon, Icon} from "leaflet";
import Button from "@mui/material/Button";
import L from 'leaflet';
import '../../../utils/libs/leafletPointInPolygon'
import CustomTileLayer from "../layout/map/CustomTileLayer.js";

function CampaignMap({patrimoine, conteneurs, onConteneurClick, onConteneursSelected, ...props}) {
    const [conteneursData, setConteneurData] = React.useState(null)
    const [expandedMarkers, setExpandedMarkers] = React.useState({});

    const [center, setCenter] = React.useState([0.0, 0.0]);
    const mapRef = React.useRef(null);

    const calculateCenter = (conteneurs) => {
        const totalLat = conteneurs.reduce((acc, c) => acc + parseFloat(c.latitude), 0);
        const totalLng = conteneurs.reduce((acc, c) => acc + parseFloat(c.longitude), 0);
        const avgLat = totalLat / conteneurs.length;
        const avgLng = totalLng / conteneurs.length;
        return [avgLat, avgLng];
    };

    useEffect(() => {
        if (conteneurs && conteneurs.length > 0) {
            const newCenter = calculateCenter(conteneurs);
            setCenter(newCenter);
        } else if (patrimoine.isUnipatrimoine) {
            setCenter([patrimoine.latitude, patrimoine.longitude])
        }
    }, [conteneurs]);

    useEffect(() => {
        if (mapRef.current) {
            const mapInstance = mapRef.current;
            mapInstance.setView(center, 13);
        }
    }, [center]);

    useEffect(() => {
        if (conteneurs) {
            let data = {}
            let len = conteneurs.length;
            while (len--) {
                let key = conteneurs[len].latitude + "/" + conteneurs[len].longitude
                if (data[key]) {
                    data[key].push(conteneurs[len])
                } else {
                    data[key] = [conteneurs[len]]
                }
            }

            setConteneurData(data)
        }
    }, [conteneurs])


    const getMarkersInsidePolygon = (polygonPoints) => {
        if (polygonPoints && polygonPoints.length > 0) {
            // On s'assure que le polygon est fermé (source de bug courante)
            if (polygonPoints[0] !== polygonPoints[polygonPoints.length - 1]) {
                polygonPoints.push(polygonPoints[0]);
            }

            let leafletPolygon = L.polygon(polygonPoints);

            return conteneurs.filter(c => {
                let point = L.latLng(parseFloat(c.latitude), parseFloat(c.longitude));
                return leafletPolygon.contains(point);
            });
        } else {
            return [];
        }
    };

    const onPolygonCreate = (e) => {
        const {layerType, layer} = e;
        if (layerType === 'polygon') {
            const latlngs = layer.getLatLngs();
            onConteneursSelected(getMarkersInsidePolygon(latlngs))
        }
    };

    const onPolygonEdited = (e) => {
        const {layers} = e;
        layers.eachLayer((layer) => {
            if (layer instanceof L.Polygon) {
                const latlngs = layer.getLatLngs();
                onConteneursSelected(getMarkersInsidePolygon(latlngs))

            }
        });
    };

    const onPolygonDelete = () => {
        onConteneursSelected([])
    };

    const onAddAll = () => {
        if (patrimoine.isUnipatrimoine) {

        } else {
            onConteneursSelected(conteneurs)
        }
    }

    const getConteneurStatusIcon = () => {
        return helper.getConteneurStatusIcon()
    }

    function getIcon(c, count = 1) {
        let imgSrc = helper.getConteneurStatusIcon();
        return divIcon({
            className: 'my-div-icon',
            html: `<img class="marker-image" src="${imgSrc}"/>` +
                `<span class="marker-number-div">${count}</span>`,
            iconSize: [(56 / 1.5), (76 / 1.5)]
        });
    }

    return (
        <>
            <MapContainer
                ref={mapRef}
                center={center}
                zoom={13}
                style={{height: "85vh", width: "100%"}}
            >
                <CustomTileLayer
                    maxZoom={19}
                />
                {
                    conteneursData &&
                    Object.keys(conteneursData).map((key, i) => {
                        return conteneursData[key].map((c, j) => {
                            const baseLat = parseFloat(c.latitude);
                            const baseLng = parseFloat(c.longitude);

                            const isExpanded = expandedMarkers[key];
                            const numMarkers = conteneursData[key].length;

                            let lat = baseLat;
                            let lng = baseLng;

                            if (isExpanded) {
                                // calculer l'angle en radians
                                const angle = 2 * Math.PI * j / numMarkers;

                                // convertir les coordonnées polaires en coordonnées géographiques
                                lat = baseLat + Constants.MAP_RADIUS * Math.cos(angle);
                                lng = baseLng + Constants.MAP_RADIUS * Math.sin(angle) / Math.cos(baseLat / Constants.DEGREES_PER_RADIAN);
                            }

                            return (
                                <Marker
                                    key={`${i}-${j}-${lat}-${lng}`}
                                    position={[lat, lng]}
                                    icon={getIcon(conteneursData[key], numMarkers)}
                                    eventHandlers={{
                                        click: () => {

                                        },
                                    }}
                                />
                            )
                        })
                    })
                }

                {patrimoine.isUnipatrimoine &&
                    <Marker
                        icon={new Icon({
                            iconUrl: getConteneurStatusIcon(patrimoine),
                            iconSize: [(56 / 1.5), (76 / 1.5)],
                        })}
                        position={[patrimoine.latitude, patrimoine.longitude]}/>
                }Z
                <FeatureGroup>
                    <EditControl
                        position='topright'
                        onCreated={onPolygonCreate}
                        onEdited={onPolygonEdited}
                        onDeleted={onPolygonDelete}
                        draw={{
                            rectangle: false,
                            circle: false,
                            polyline: false,
                            circlemarker: false,
                            marker: false,
                        }}
                    />
                </FeatureGroup>
                <div className={"campaignMapButtons-wrapper"}>
                    <Button variant={"contained"} color={"primary"} onClick={onAddAll}>Ajouter tout</Button>
                    {/*{polygonPoints.length > 0 && (*/}
                    {/*    <>*/}
                    {/*        <Button onClick={onPolygonFinish} variant={"contained"}>Ajouter le polygone</Button>*/}
                    {/*        <Button onClick={onPolygonReset} variant={"contained"}>Réinitialiser le polygone</Button>*/}
                    {/*    </>*/}
                    {/*)}*/}
                </div>
            </MapContainer>
        </>
    )
}


export default CampaignMap;
