import React from 'react';
import {Container, Grid} from "@mui/material";
import {helper} from "../../utils/helper";
import Routes from '../routes';
import Tile from "./home/Tile";
import {isExploitant} from "../../utils/roles";
import DashboardView from "./dashboard/DashboardView";

const DashboardPage = () => {
    return (
        <>
            <Grid container spacing={4} className={""}>
                {isExploitant() &&
                    <Grid item xs={12}>
                        <DashboardView/>
                    </Grid>
                }

            </Grid>
        </>
    )
}

export default DashboardPage;
