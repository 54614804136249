import React from 'react';
import "./Loader.css";
import {Container, Paper, Typography} from '@mui/material';

export default function Loader({label = 'Patience, nous chargeons vos données !'}) {
    return (
        <div className="loaderContainer">
            <Typography variant={"h4"} className={"loader-label"}>{label}</Typography>
            <div className="container mt-2">
                {/*<img src={`/images/mini_laveuse.png`} className={"loading-box loading-box-laveuse"}*/}
                {/*     alt={"image loading"}/>*/}
                <img src={`/images/picto_loader.webp`} className={"loading-box"}
                     alt={"loading"}/>
                <img src={`/images/picto_loader.webp`} className={"loading-box"}
                     alt={"loading"}/>
                <img src={`/images/picto_loader.webp`} className={"loading-box"}
                     alt={"loading"}/>
                <img src={`/images/picto_loader.webp`} className={"loading-box"}
                     alt={"loading"}/>
                <img src={`/images/picto_loader.webp`} className={"loading-box"}
                     alt={"loading"}/>
                {/*<div className="loading-box" ></div>*/}
                {/*<div className="loading-box"></div>*/}
                {/*<div className="loading-box"></div>*/}
                {/*<div className="loading-box"></div>*/}
                {/*<div className="loading-box"></div>*/}
            </div>
        </div>
    );
}
