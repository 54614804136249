import {
    deleteHeaders,
    fetchHeaders,
    patchHeaders,
    postHeaders,
} from "../../utils/headers";
import Constants from "../../utils/constants";

export const formsService = {
    fetchForms,
    fetchForm,
    deleteForm,
    createForm,
    updateForm,
};

function fetchForms() {
    const requestOptions = fetchHeaders()

    return fetch(Constants.API_ROUTE_FORMS, requestOptions)
        .then(handleResponse)
        .then(forms => {
            return forms["hydra:member"]
        });
}

function fetchForm(id) {
    const requestOptions = fetchHeaders()

    let url = Constants.API_ROUTE_FORM.replace(':id', id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(form => {
            return form
        });
}

function createForm(values) {
    const requestOptions = postHeaders(values)

    let url = Constants.API_ROUTE_FORMS;

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function updateForm(values) {
    const requestOptions = patchHeaders(values)
    let url = Constants.API_ROUTE_FORM.replace(':id', values.id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function deleteForm(id) {
    const requestOptions = deleteHeaders()

    let url = Constants.API_ROUTE_FORM.replace(':id', id);
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return id
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {

            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
