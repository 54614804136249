import React, {useEffect, useState} from 'react';
import {Container, Grid} from "@mui/material";
import {connect} from "react-redux";
import {usersActions} from "../../modules/users/users.actions";
import {object, string, boolean, number} from 'yup';
import {AssuredWorkload, FolderDelete} from "@mui/icons-material";
import {useParams} from "react-router-dom";
import {Formik} from "formik";
import PatrimoineDataField from "./patrimoine/form/PatrimoineDataField";
import {patrimoinesActions} from "../../modules/patrimoines/patrimoines.actions";
import SubmitButton from "./layout/forms/SubmitButton";
import {helper} from "../../utils/helper";
import PageTitle from "./layout/PageTitle";
import Loader from "./layout/loading/loader";

const patrimoineValidationSchema = object({
    libelle: string()
        .required('Le libellé est requis'),
    isUnipatrimoine: boolean()
        .required('Le type est requis'),
    lieu: string()
        .when('isUnipatrimoine', {
            is: true,
            then: () => string().required('Le lieu est requis'),
            otherwise: () => string().nullable(),
        }),
    nombreDeBacs: number()
        .when('isUnipatrimoine', {
            is: true,
            then: () => number().min(1, 'Le nombre de bacs doit être supérieur à 0').required('Le nombre de bacs est requis'),
            otherwise: () => number().nullable(),
        }),
    commentaire: string().nullable(),
});

function PatrimoineNewOrEdit({dispatch, ...props}) {
    const params = useParams();
    const [userId, setUserId] = React.useState(null)
    const [patrimoineId, setPatrimoineId] = React.useState(null)
    const [mode, setMode] = useState("create");

    useEffect(() => {
        if (params.hasOwnProperty("id")) {
            setUserId(params.id);
        } else if (params.hasOwnProperty("patrimoineId")) {
            setPatrimoineId(params.patrimoineId)
            setMode("edit")
        }
    }, [params]);

    useEffect(() => {
        if (userId && !props.client && !props.loading && !props.error) {
            dispatch(usersActions.fetchUser('client', userId))
        } else {

            if (patrimoineId && (props.patrimoine?.id !== parseInt(patrimoineId)) && !props.loading && !props.error) {
                dispatch(patrimoinesActions.fetchPatrimoine(patrimoineId))
            }
        }

        if (patrimoineId && !props.client && !props.loading && !props.error) {
            dispatch(usersActions.fetchClients());
        }
    }, [props, dispatch])

    const initialValues = {
        libelle: props.patrimoine?.libelle || '',
        client: props.patrimoine?.client || null,
        isUnipatrimoine: false,
        lieu: '',
        nombreDeBacs: '',
        commentaire: ''
    }

    const handleSubmit = (values) => {

        if (!values.isUnipatrimoine) {
            delete values.lieu;
            delete values.nombreDeBacs;
            delete values.commentaire;
            dispatch(usersActions.clearUser());
            if (mode === "create") {
                values.client = props.client['@id'];
                dispatch(patrimoinesActions.createPatrimoine(values));
            } else {
                dispatch(patrimoinesActions.updatePatrimoine(values, patrimoineId))
            }
        } else {
            values.nombreDeBacs = parseInt(values.nombreDeBacs);
            //Geocode lieu to retrieve lat/lng.
            helper.geocodeAddress(values.lieu).then((geometry) => {
                values.latitude = geometry.latitude.toString();
                values.longitude = geometry.longitude.toString();
            }).catch((e) => {
                delete values.latitude;
                delete values.longitude
            }).finally(() => {
                dispatch(usersActions.clearUser());
                dispatch(patrimoinesActions.createPatrimoine(values));
            })
        }
    }


    return (
        <>
            <PageTitle
                title={mode === "create" ? `Nouveau patrimoine pour ${props.client?.libelle}` : `Modifier le patrimoine ${props.patrimoine?.libelle}`}
                icon={<FolderDelete/>}/>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={patrimoineValidationSchema}
                onSubmit={(values, {setSubmitting}) => {
                    handleSubmit(values)
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        {props.loading &&
                            <Loader/>
                        }
                        {!props.loading &&
                            <>
                                <PatrimoineDataField values={values} errors={errors} handleChange={handleChange}
                                                     handleBlur={handleBlur} mode={mode} clients={props.clients}/>
                                <Grid container justifyContent="center" style={{marginTop: '2rem'}}>
                                    <Grid item xs={4}>
                                        <SubmitButton submitting={isSubmitting && !props.error}/>
                                    </Grid>
                                </Grid>
                            </>
                        }
                    </form>
                )}
            </Formik>
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        client: state.users.user,
        clients: state.users.clients,
        patrimoine: state.patrimoines.patrimoine,
        loading: state.users.loading || state.patrimoines.loading,
        error: state.users.error || state.patrimoines.error
    }
}

export default connect(mapStateToProps)(PatrimoineNewOrEdit);
