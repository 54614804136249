import React, {useEffect} from 'react';
import {Container} from "@mui/material";
import {connect} from "react-redux";
import Loader from "./layout/loading/loader";
import ButtonCreate from "./layout/ButtonCreate";
import Constants from "../../utils/constants";
import {campaignsActions} from "../../modules/campaigns/campaigns.actions";
import CampaignsTable from "./campaigns/CampaignsTable";
import {TravelExplore} from "@mui/icons-material";
import PageTitle from "./layout/PageTitle";

function CampaignsPage({dispatch, ...props}) {

    useEffect(() => {
        if (!props.campaigns && !props.loading && !props.error) {
            dispatch(campaignsActions.fetchCampaigns())
        }
    }, [dispatch, props.campaigns, props.loading, props.error]);

    useEffect(() => {
        return () => {
            // dispatch(conteneursActions.clearCampaigns());
        };
    }, [dispatch]);

    const handleDelete = (campaign) => {
        dispatch(campaignsActions.deleteCampaign(campaign.id));
    }

    return (
        <>
            <PageTitle title={"Gestion des tournées"} icon={<TravelExplore/>}/>
            <ButtonCreate route={Constants.APP_ROUTE_ADMIN_CAMPAIGNS_NEW} label="Créer une tournée"/>
            {props.loading &&
                <Loader />
            }
            {!props.loading &&
                <CampaignsTable campaigns={props.campaigns} onDelete={handleDelete}/>
            }
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        campaigns: state.campaigns.campaigns,
        loading: state.campaigns.loading,
        error: state.campaigns.error
    }
}

export default connect(mapStateToProps)(CampaignsPage);
