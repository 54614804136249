import React from 'react';
import {Container, Grid} from "@mui/material";
import {helper} from "../../utils/helper";
import Routes from '../routes';
import Tile from "./home/Tile";
import {isExploitant} from "../../utils/roles";
import DashboardView from "./dashboard/DashboardView";

const HomePage = () => {
    const currentUser = helper.getCurrentUser();

    const getTilesForCurrentUser = () => {
        if (currentUser) {
            let userRoles = currentUser.roles;
            let tiles = []
            for (let route of Routes) {
                if (route.tiles) {
                    for (let r of userRoles) {
                        if (route.roles.includes(r)) {
                            tiles.push({
                                path: route.path,
                                label: route.tilesLabel,
                                enabled: route.tilesEnabled
                            })
                            break;
                        }
                    }
                }
            }
            return tiles;
        }
        return []
    }

    return (
        <>
            <Grid container spacing={4} className={"mt-2"}>
                <Grid item xs={12} container spacing={4}>
                    {getTilesForCurrentUser().map((t, index) => (
                        <Grid item xs={12} md={3} key={index}>
                            <Tile colorVariant={index % 7} label={t.label} path={t.path} enabled={t.enabled}/>
                        </Grid>
                    ))}
                </Grid>

            </Grid>
        </>
    )
}

export default HomePage;
