import React from 'react';
import {Chip} from "@mui/material";
import {helper} from "../../../utils/helper.js";

const BadgeTypeLavage = ({type, ...props}) => {
    return (
        <Chip
            label={type}
            className={helper.getTypeLavageColor(type)}
        />
    )
}

export default BadgeTypeLavage;