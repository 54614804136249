import React, {useEffect} from 'react';
import {Container} from "@mui/material";
import {connect} from "react-redux";
import {usersActions} from "../../modules/users/users.actions";
import Loader from "./layout/loading/loader";
import UsersTable from "./users/UsersTable";
import ButtonCreate from "./layout/ButtonCreate";
import Constants from "../../utils/constants";
import PageTitle from "./layout/PageTitle";
import {PeopleAlt} from "@mui/icons-material";

function UsersPage({dispatch, ...props}) {

    useEffect(() => {
        if (!props.users && !props.loading && !props.error) {
            dispatch(usersActions.fetchUsers())
        }
    });

    useEffect(() => {
        return () => {
            dispatch(usersActions.clearUser());
        };
    }, [dispatch]);

    const handleDeleteUser = (user) => {
        dispatch(usersActions.deleteUser(user.id));
    }

    return (
        <>
            <PageTitle title={"Gestion des utilisateurs"} icon={<PeopleAlt/>}/>
            <ButtonCreate route={Constants.APP_ROUTE_ADMIN_USERS_NEW} label="Ajouter un utilisateur"/>
            {props.loading &&
                <Loader/>
            }
            {!props.loading &&
                <UsersTable users={props.users} onDelete={handleDeleteUser}/>
            }
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        users: state.users.users,
        loading: state.users.loading,
        error: state.users.error
    }
}

export default connect(mapStateToProps)(UsersPage);
