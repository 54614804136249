import React from 'react';
import {Button} from "@mui/material";
import "./ButtonCreate.css"
import {Link} from "react-router-dom/";
import {Add} from "@mui/icons-material";

function ButtonCreate({...props}) {
    const {label, route, disabled} = props;
    return (
        <div className="buttonCreate">
            <Link to={disabled ? null : route}>
                <Button color="secondary" size="large" variant="contained"
                        disabled={disabled} sx={{color: 'white'}}
                        startIcon={<Add/>}>
                    {label}
                </Button>
            </Link>
        </div>
    )
}

export default ButtonCreate;
