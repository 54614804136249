import React from 'react';
import './Tile.css';
import {Paper, Typography} from "@mui/material";
import {Link} from "react-router-dom/";

function Tile({...props}) {
    const {path, label, enabled, colorVariant, onClick} = props;

    const formattedPath = (str) => {
        const parts = str.split('/');
        return parts[parts.length - 1];
    }

    return (
        <>
            {enabled &&
                <Link to={path}>
                    <div onClick={onClick} className={"tile-container"}>
                        <Paper elevation={0} variant={"outlined"} square sx={{backgroundImage: "url('/images/home/" + formattedPath(path) + ".webp')"}}
                               className={"tile"}>

                            <Typography className={"tile-label bg-" + path.substr(1)}>{label.toUpperCase()}</Typography>
                        </Paper>
                    </div>
                </Link>
            }
            {!enabled &&
                <div className={"tile-container"}>
                    <Paper elevation={0} variant={"outlined"} square
                           className={"tile bg-" + path.substr(1)}>
                        <img src={"/images/home/" + formattedPath(path) + ".jpg"}
                             className="home-image"/>
                        <Typography className={"tile-label tile-" + colorVariant + " bg-" + path.substr(1)}>{label.toUpperCase()}</Typography>
                    </Paper>
                </div>
            }
        </>


    );
}

export default Tile;
