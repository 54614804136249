import React from 'react';
import {Box, Button, CircularProgress, Typography} from "@mui/material";

function SubmitButton({...props}) {
    const {label = "Enregistrer", submitting, color = "primary"} = props;
    return (
        <Box sx={{m: 1, position: 'relative'}}>
            <Button
                type="submit"
                variant="contained"
                fullWidth
                color={color}
                disabled={submitting}>
                <Typography color={"white"} variant={"body"}>
                    {label}
                </Typography>

            </Button>
            {submitting &&
                <CircularProgress size={24}
                                  sx={{
                                      position: 'absolute',
                                      top: '50%',
                                      left: '50%',
                                      marginTop: '-12px',
                                      marginLeft: '-12px',
                                  }}
                />
            }
        </Box>
    )
}

export default SubmitButton;
