import React from 'react';
import {Typography} from "@mui/material";
import {Delete} from "@mui/icons-material";

const PageTitle = ({title, icon, ...props}) => {
    return (
        <header className={"pageHeader"}>
            <Typography variant={"h3"} component={"h1"} gutterBottom className={"pageTitle"}>
                {icon}
                {title}
            </Typography>
        </header>
    );
};

export default PageTitle;
