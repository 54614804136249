import React from 'react';
import {
    Grid,
    Typography
} from "@mui/material";
import {format, parseISO} from 'date-fns';
import {RRuleHelper} from "../../../../utils/RRuleHelper";
import {RRule, rrulestr} from "rrule";


function CampaignSummary({...props}) {
    const {values, errors} = props;

    const getFormattedDate = (date) => {
        return parseISO(date).toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    }

    return (
        <Grid container spacing={1} className={"sectionContainer"}>
            <Grid item xs={12}>
                <Typography variant={"h5"} className={"sectionHeader"}>Récapitulatif</Typography>
            </Grid>

            {/*LIBELLE FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    <Typography>Libellé</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>{values.libelle}</Typography>
                </Grid>
            </Grid>
            {/*DATE FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    <Typography>Date</Typography>
                </Grid>
                <Grid item xs={9}>
                    {/*<Typography>{format(parseISO(values.dateDebut), 'dd/MM/yyyy')} au {format(parseISO(values.dateFin), 'dd/MM/yyyy')}</Typography>*/}
                    <Typography>Démarrage le {getFormattedDate(values.dateDebut)}</Typography>
                    <Typography>{RRuleHelper.rruleToText(values.rrule)}</Typography>
                </Grid>
            </Grid>
            {/*CLIENT FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    <Typography>Client</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>{values.client.libelle ?? values.client.email}</Typography>
                </Grid>
            </Grid>
            {/*FORMULAIRE FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    <Typography>Formulaire</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>{values.formulaire.libelle}</Typography>
                </Grid>
            </Grid>
            {/*PATRIMOINE FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    <Typography>Patrimoine</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>{values.patrimoine.libelle}</Typography>
                </Grid>
            </Grid>
            {/*CONTENEURS FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    <Typography>Nombre de conteneurs</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>{values.patrimoine.isUnipatrimoine ? values.patrimoine.nombreDeBacs + " bacs" : values.conteneurs.length}</Typography>
                </Grid>
            </Grid>
            {/*CONTENEURS FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    <Typography>Opérateurs affectés</Typography>
                </Grid>
                <Grid item xs={9}>
                    {values.operateurs && values.operateurs.map((o, i) => {
                        return (
                            <Typography key={i}>{o.libelle ?? o.email}</Typography>
                        )
                    })}
                </Grid>
            </Grid>


        </Grid>
    );
}

export default CampaignSummary;
