import {laveusesConstants} from './laveuses.constants';
import {authenticationConstants} from "../authentication/auth.constants";

const initialState = {laveuses: null, laveuse: null, loading: false, error: null}

export function laveuses(state = initialState, action) {
    switch (action.type) {
        case laveusesConstants.LAVEUSES_FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case laveusesConstants.LAVEUSES_FETCH_SUCCESS:
            return {
                ...state,
                laveuses: action.laveuses,
                laveuse: null,
                loading: false,
                error: null
            };
        case laveusesConstants.LAVEUSES_FETCH_ONE_REQUEST:
            return {
                ...state,
                laveuse: null,
                loading: true,
                error: null
            };
        case laveusesConstants.LAVEUSES_FETCH_ONE_SUCCESS:
            return {
                ...state,
                laveuse: action.laveuse,
                laveuses: null,
                loading: false,
                error: null
            };
        case laveusesConstants.LAVEUSES_FETCH_ONE_FAILURE:
            return {
                ...state,
                laveuse: null,
                loading: false,
                error: action.error
            };
        case laveusesConstants.LAVEUSES_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case laveusesConstants.LAVEUSES_DELETE_SUCCESS:
        case laveusesConstants.LAVEUSES_CREATE_SUCCESS:
        case laveusesConstants.LAVEUSES_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                laveuses: null,
                laveuse: null
            };
        case laveusesConstants.LAVEUSES_FETCH_FAILURE:
        case laveusesConstants.LAVEUSES_CREATE_FAILURE:
        case laveusesConstants.LAVEUSES_UPDATE_FAILURE:
        case laveusesConstants.LAVEUSES_DELETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case laveusesConstants.LAVEUSES_CLEAR_REQUEST:
            return {
                ...initialState
            }
        case authenticationConstants.LOGOUT:
            return {
                initialState
            }
        default:
            return state
    }
}
