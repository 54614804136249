import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import {usersActions} from "../../modules/users/users.actions";
import Loader from "./layout/loading/loader";
import {Formik} from "formik";
import UserDataField from "./users/form/UserDataField";
import './UsersNewOrEdit.css'
import {boolean, object, string} from 'yup';
import {Container, Grid} from "@mui/material";
import {isExploitant, Role} from "../../utils/roles";
import {useParams} from "react-router-dom";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SubmitButton from "./layout/forms/SubmitButton";
import {PeopleAlt} from "@mui/icons-material";
import PageTitle from "./layout/PageTitle";
import {helper} from "../../utils/helper";


const userValidationSchema = object({
    email: string()
        .email('L\'email doit être valide')
        .required('L\'email est requis'),
    password: string()
        .when('isCreating', {
            is: true,
            then: () => string().required('Le mot de passe est requis'),
            otherwise: () => string().nullable(),
        }),
    libelle: string()
        .nullable()
        .max(255, 'Le libellé ne doit pas dépasser 255 caractères'),
    rfid: string()
        .nullable()
        .max(255, 'Le rfid ne doit pas dépasser 255 caractères'),
    isAccredited: boolean().nullable(),
    exploitant: string()
        .when('roles', {
            is: (roles) => roles.includes(Role.ROLE_CLIENT) || roles.includes(Role.ROLE_OPERATEUR),
            then: () => string().required('L\'exploitant est requis'),
            otherwise: () => string().nullable(),
        }),
});

function UsersNewOrEdit({...props}) {
    const dispatch = useDispatch();
    const params = useParams();
    const [mode, setMode] = useState("create");
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        if (params.hasOwnProperty("id")) {
            setMode("edit");
            setUserId(params.id);
        } else {
            setMode("create");
            setUserId(null);
        }
    }, [params]);

    useEffect(() => {
        if (userId && !props.user && !props.loading && !props.error) {
            let userType = params.type;
            dispatch(usersActions.fetchUser(userType, userId));
        }
        if (!props.exploitants && !props.loading && !props.error && mode === "create") {
            dispatch(usersActions.fetchExploitants())
        }
    }, [props, dispatch, params.type, userId]);

    useEffect(() => {
        return () => {
            dispatch(usersActions.clearUser());
        };
    }, [dispatch]);

    const handleLogoChanged = (event, form) => {
        const formData = new FormData();
        formData.append("file", event.target.files[0]);

        dispatch(usersActions.createLogo(formData)).then((logo) => {
            form.setFieldValue("logo", logo['@id'])
            form.setFieldValue("logoUrl", logo.contentUrl)
        }).catch((error) => {
            console.error(error)
        })
    }

    const handleSubmit = (values) => {
        if (values.logo?.hasOwnProperty("@id")) {
            values.logoUrl = values.logo?.contentUrl
            values.logo = values.logo["@id"];
        }
        if (values.roles.includes(Role.ROLE_CLIENT)) {
            if (mode === "create") {
                dispatch(usersActions.createClient(values));
            } else {
                dispatch(usersActions.updateClient(values, values.id));
            }

        } else if (values.roles.includes(Role.ROLE_OPERATEUR)) {
            if (mode === "create") {
                dispatch(usersActions.createOperateur(values));
            } else {
                dispatch(usersActions.updateOperateur(values, values.id));
            }

        } else if (values.roles.includes(Role.ROLE_EXPLOITANT)) {
            if (values.roles.includes(Role.ROLE_EXPLOITANT)) {
                delete values.exploitant
            }
            if (mode === "create") {
                dispatch(usersActions.createExploitant(values));
            } else {
                dispatch(usersActions.updateExploitant(values, values.id));
            }
        } else if (values.roles.includes(Role.ROLE_ADMIN)) {
            if (values.roles.includes(Role.ROLE_EXPLOITANT)) {
                delete values.exploitant
            }
            if (mode === "create") {
                dispatch(usersActions.createUser(values));
            } else {
                dispatch(usersActions.updateUser(values, values.id));
            }
        }


    }

    const initialValues = {
        id: props.user?.id ?? null,
        libelle: props.user?.libelle ?? '',
        email: props.user?.email ?? '',
        password: '',
        logo: props.user?.logo,
        logoUrl: props.user?.logo?.contentUrl,
        roles: props.user?.roles ?? [Role.ROLE_EXPLOITANT],
        isAccredited: props.user?.isAccredited ?? false,
        rfid: props.user?.rfid ?? '',
        exploitant: props.user?.exploitant?.hasOwnProperty('@id') ? props.user?.exploitant['@id'] : '',
        emailNotificationsLavageImpossible: props.user?.emailNotificationsLavageImpossible ?? false,
        emailNotificationsMaintenance: props.user?.emailNotificationsMaintenance ?? false,
        emailNotificationsLavageDone: props.user?.emailNotificationsLavageDone ?? false,
        isCreating: mode === "create"
    }

    if (isExploitant()) {
        let currentUser = helper.getCurrentUser();
        initialValues.exploitant = currentUser.iri;
    }

    if (props.loading) {
        return <Loader/>;
    }

    return (
        <>
            <PageTitle title={mode === "create" ? "Nouvel Utilisateur" : "Modifier l'utilisateur"} icon={<PeopleAlt/>}/>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={userValidationSchema}
                onSubmit={(values, {setSubmitting}) => {
                    handleSubmit(values)
                    setTimeout(() => {
                        setSubmitting(false)
                    }, 500)
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <UserDataField values={values} mode={mode} exploitants={props.exploitants}
                                       errors={errors} handleChange={handleChange} user={props.user}
                                       handleLogoChanged={handleLogoChanged}
                                       handleBlur={handleBlur}/>
                        <Grid container justifyContent="center" style={{marginTop: '2rem'}}>
                            <Grid item xs={4}>
                                <SubmitButton submitting={isSubmitting}/>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        user: state.users.user,
        exploitants: state.users.exploitants,
        loading: state.users.loading,
        error: state.users.error
    }
}

export default connect(mapStateToProps)(UsersNewOrEdit);
