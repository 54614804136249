import React from "react";
import L from "leaflet";
import {useMap} from "react-leaflet";
import localforage from "localforage";
import Constants from "../../../../utils/constants";
import {debounce} from "@mui/material";

const CustomTileLayer = ({url = Constants.MAP_TILE_OSM, ...props}) => {
    const map = useMap();

    React.useEffect(() => {
        const tileLayer = L.tileLayer(url, {
            ...props,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        });

        tileLayer.getTileUrl = function (coords) {
            const url = this._url.replace('{z}', coords.z)
                .replace('{x}', coords.x)
                .replace('{y}', coords.y);
            return url;
        };

        tileLayer.addTo(map);

        // On debounce le zoom poru éviter les surcharges de call aux tuiles.
        const handleZoomEnd = debounce(() => {

        }, 400); // 300 ms de délai

        map.on('zoomend', handleZoomEnd);

        return () => {
            map.removeLayer(tileLayer);
        };
    }, [map, props]);

};

export default CustomTileLayer;
