import React, {useEffect} from 'react';
import {Container, Grid} from "@mui/material";
import {connect} from "react-redux";
import {usersActions} from "../../modules/users/users.actions";
import Loader from "./layout/loading/loader";
import ButtonCreate from "./layout/ButtonCreate";
import Constants from "../../utils/constants";
import ClientSelector from "./patrimoine/ClientSelector";
import PatrimoineTable from "./patrimoine/PatrimoineTable";
import {isAdmin} from "../../utils/roles";
import PageTitle from "./layout/PageTitle";
import {FolderDelete} from "@mui/icons-material";
import {helper} from "../../utils/helper";
import {campaignsActions} from "../../modules/campaigns/campaigns.actions";
import {patrimoinesActions} from "../../modules/patrimoines/patrimoines.actions";
import CSVHandler from "../../utils/CSVHandler.js";

function PatrimoinePage({dispatch, ...props}) {
    const [admin, setAdmin] = React.useState(isAdmin())
    const [clientSelected, setClientSelected] = React.useState(null);
    const [exploitantSelected, setExploitantSelected] = React.useState(window.localStorage.getItem('exploitantSelected'));
    const [exploitants, setExploitants] = React.useState([]);

    useEffect(() => {
        if (!props.clients && !props.loading && !props.error) {
            dispatch(usersActions.fetchClients())
        }

        if (props.clients && Array.isArray(props.clients)) {
            const uniqueExploitants = {};
            const extractedExploitants = [];

            for (const client of props.clients) {
                const exploitant = client.exploitant;
                if (exploitant && !uniqueExploitants[exploitant.id]) {
                    uniqueExploitants[exploitant.id] = true;
                    extractedExploitants.push(exploitant);
                }
            }

            setExploitants(extractedExploitants);

            const selectedClient = window.localStorage.getItem('clientSelected')

            // Mettre à jour clientSelected si nécessaire
            const clientIds = props.clients.map(client => client.id);
            if (!clientIds.includes(Number(selectedClient))) {
                setClientSelected(null);
                window.localStorage.removeItem('clientSelected');
            } else {
                setClientSelected(selectedClient)
            }
        }
    }, [dispatch, props.clients, props.error, props.loading]);

    useEffect(() => {
        if (clientSelected && !props.client && !props.error) {
            dispatch(usersActions.fetchUser("client", clientSelected));
        }

    }, [clientSelected, dispatch, props.client]);

    useEffect(() => {
        return () => {
            // dispatch(usersActions.clearUser());
        };
    }, []);

    const filteredClients = React.useMemo(() => {
        let data = [];
        if (admin) {
            if (exploitantSelected && Array.isArray(props.clients)) {
                for (const client of props.clients) {
                    if (client.exploitant.id == exploitantSelected) {
                        data.push(client);
                    }
                }
            }
        } else {
            data = props.clients;
        }
        data?.sort((a, b) => a.libelle.localeCompare(b.libelle));
        return data;
    }, [admin, exploitantSelected, props.clients]);


    const onClientSelected = (clientId) => {
        window.localStorage.setItem('clientSelected', clientId);
        setClientSelected(clientId)
        dispatch(usersActions.fetchUser("client", clientId));
    }
    const onExploitantSelected = (exploitantId) => {
        window.localStorage.setItem('exploitantSelected', exploitantId);
        setExploitantSelected(exploitantId)
    }

    const handleDelete = (p) => {
        dispatch(usersActions.clearUser());
        dispatch(patrimoinesActions.deletePatrimoine(p.id));
    }

    const sortedPatrimoine = (data) => {
        data.sort((a, b) => a.libelle.localeCompare(b.libelle));
        return data;
    }

    const handleExportCSV = (patrimoine) => {
        dispatch(patrimoinesActions.fetchPatrimoine(patrimoine.id, (patrimoineWithConteneur) => {
            CSVHandler.exportPatrimoineToCSV(patrimoineWithConteneur)
        }))
    }

    return (
        <>
            <PageTitle title={"Gestion des patrimoines"} icon={<FolderDelete/>}/>

            <ClientSelector clients={filteredClients} isAdmin={admin} onClientSelected={onClientSelected}
                            clientSelected={clientSelected} exploitants={exploitants}
                            exploitantSelected={exploitantSelected}
                            onExploitantSelected={onExploitantSelected}/>

            {props.loading &&
                <Loader/>
            }

            {(clientSelected && props.client) &&
                <Grid container>
                    {isAdmin() &&
                        <ButtonCreate route={Constants.APP_ROUTE_PATRIMOINE_NEW.replace(":id", clientSelected)}
                                      label="Ajouter un patrimoine"/>
                    }
                    <Grid item xs={12} style={{position: 'relative'}} className={""}>
                        <PatrimoineTable patrimoines={sortedPatrimoine(props.client.patrimoines)}
                                         handleExportCSV={handleExportCSV}
                                         onDelete={handleDelete}/>
                    </Grid>

                </Grid>
            }

            {/*<ButtonCreate route={Constants.APP_ROUTE_ADMIN_USERS_NEW} label="Ajouter un utilisateur"/>*/}
            {/*<UsersTable users={props.users} onDelete={handleDeleteUser}/>*/}
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        clients: state.users.clients,
        client: state.users.user,
        loading: state.users.loading || state.patrimoines.loading,
        error: state.users.error || state.patrimoines.error
    }
}

export default connect(mapStateToProps)(PatrimoinePage);
