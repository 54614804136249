import {
    deleteHeaders,
    fetchHeaders,
    patchHeaders, postFormDataHeaders,
    postHeaders,
} from "../../utils/headers";
import Constants from "../../utils/constants";

export const usersService = {
    fetchUsers,
    fetchUser,
    deleteUser,
    createUser,
    updateUser,
    fetchExploitants,
    createExploitant,
    updateExploitant,
    fetchClient,
    fetchClients,
    createClient,
    updateClient,
    fetchOperateur,
    fetchOperateurs,
    createOperateur,
    updateOperateur,
    createLogo,
};

function fetchUsers() {
    const requestOptions = fetchHeaders()

    return fetch(Constants.API_ROUTE_USERS, requestOptions)
        .then(handleResponse)
        .then(users => {
            return users["hydra:member"]
        });
}

function fetchExploitants() {
    const requestOptions = fetchHeaders()

    return fetch(Constants.API_ROUTE_EXPLOITANTS, requestOptions)
        .then(handleResponse)
        .then(users => {
            return users["hydra:member"]
        });
}

function fetchUser(id) {
    const requestOptions = fetchHeaders()

    let url = Constants.API_ROUTE_USER.replace(':id', id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        });
}

function createUser(values) {
    const requestOptions = postHeaders(values)

    let url = Constants.API_ROUTE_USERS;

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function updateUser(values) {
    const requestOptions = patchHeaders(values)
    let url = Constants.API_ROUTE_USER.replace(':id', values.id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function deleteUser(id) {
    const requestOptions = deleteHeaders()

    let url = Constants.API_ROUTE_USER.replace(':id', id);
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return id
        });
}

function createExploitant(values) {
    const requestOptions = postHeaders(values)

    let url = Constants.API_ROUTE_EXPLOITANTS;

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function updateExploitant(values, id) {
    const requestOptions = patchHeaders(values)
    let url = Constants.API_ROUTE_EXPLOITANT.replace(':id', id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function fetchClient(id) {
    const requestOptions = fetchHeaders()

    let url = Constants.API_ROUTE_CLIENT.replace(':id', id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        });
}

function fetchClients() {
    const requestOptions = fetchHeaders()

    return fetch(Constants.API_ROUTE_CLIENTS, requestOptions)
        .then(handleResponse)
        .then(users => {
            return users["hydra:member"]
        });
}

function createClient(values) {
    const requestOptions = postHeaders(values)

    let url = Constants.API_ROUTE_CLIENTS;

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function updateClient(values, id) {
    const requestOptions = patchHeaders(values)
    let url = Constants.API_ROUTE_CLIENT.replace(':id', id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function fetchOperateur(id) {
    const requestOptions = fetchHeaders()

    let url = Constants.API_ROUTE_OPERATEUR.replace(':id', id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        });
}
function fetchOperateurs() {
    const requestOptions = fetchHeaders()

    let url = Constants.API_ROUTE_OPERATEURS;

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user["hydra:member"]
        });
}

function createOperateur(values) {
    const requestOptions = postHeaders(values)

    let url = Constants.API_ROUTE_OPERATEURS;

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function updateOperateur(values, id) {
    const requestOptions = patchHeaders(values)
    let url = Constants.API_ROUTE_OPERATEUR.replace(':id', id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}


function createLogo(values) {
    const requestOptions = postFormDataHeaders(values)
    let url = Constants.API_ROUTE_USERS_LOGO;

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then((logo) => {
            return logo;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {

            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
