import {toast} from 'react-toastify';

export const toastHelper = {

    /**
     * Display a success toast
     * @param message : {string} the message to display
     */
    displaySuccess: (message) => {
        toast.success(message)
    },

    /**
     * Handle error
     * Display toast showing relevant message to the user
     * @param error : {string} the technical error
     * @param message : {string} the message to display (if any)
     */
    handleError: (error, message = '') => {
        try {
            if (message !== '') {
                toast.error(message)
                return
            }
            toast.error(error)
        } catch (e) {
            toast.error("Une erreur est survenue")
        }
    }
}
