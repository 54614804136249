export const authenticationConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    ASK_RESET_PASSWORD_REQUEST: 'ASK_RESET_PASSWORD_REQUEST',
    ASK_RESET_PASSWORD_SUCCESS: 'ASK_RESET_PASSWORD_SUCCESS',
    ASK_RESET_PASSWORD_FAILURE: 'ASK_RESET_PASSWORD_FAILURE',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

    LOGOUT: 'USERS_LOGOUT',
};
