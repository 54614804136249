import React, {useEffect} from 'react';
import {connect} from "react-redux";
import Loader from "./layout/loading/loader";
import {Assessment} from "@mui/icons-material";
import PageTitle from "./layout/PageTitle";
import {helper} from "../../utils/helper";
import {isAdmin, isExploitant} from "../../utils/roles";
import {usersActions} from "../../modules/users/users.actions";
import SyntheseFilter from "./synthese/SyntheseFilter";
import {Grid, Paper} from "@mui/material";
import SubmitButton from "./layout/forms/SubmitButton";
import {Formik} from "formik";
import * as Yup from 'yup';
import {synthesesActions} from "../../modules/syntheses/syntheses.actions";
import {format} from "date-fns";
import Constants from "../../utils/constants";

// const syntheseFilterValidationSchema = Yup.object({
//     exploitant: Yup.string()
//         .required('L\'exploitant est requis'),
//     client: Yup.string()
//         .required('Le client est requis'),
//     patrimoine: Yup.string()
//         .required('Le patrimoine est requis'),
//     campagneLavage: Yup.string()
//         .required('La campagne de lavage est requise'),
//     occurence: Yup.string()
//         .required('L\'occurrence est requise'),
// });
const syntheseFilterValidationSchema = Yup.object({
    exploitant: Yup.string()
        .required('L\'exploitant est requis'),
    client: Yup.string()
        .required('Le client est requis'),

});

function SynthesePage({dispatch, ...props}) {
    const currentUser = helper.getCurrentUser();
    const [admin, setAdmin] = React.useState(isAdmin())
    const [exploitant, setExploitant] = React.useState(isExploitant())
    const [formComplete, isFormComplete] = React.useState(false)


    useEffect(() => {
        if (admin) {
            // Fetch exploitants if the user is an admin
            if (!props.exploitants && !props.loading && !props.error) {
                dispatch(usersActions.fetchExploitants());
            }
        } else {
            // Fetch clients if the user is an exploitant
            if (!props.clients && !props.loading && !props.error) {
                dispatch(usersActions.fetchClients());
            }
        }
        // Do nothing if the user is a client
    }, [props.loading, props.error, props.exploitants, props.clients]);

    const getInitialValues = () => {
        const currentDate = new Date();
        const tomorrowDate = new Date();
        tomorrowDate.setDate(currentDate.getDate() + 1);

        return {
            exploitant: exploitant ? currentUser.id : admin ? '' : '0',
            client: (!exploitant && !admin) ? currentUser.id : '',
            bilanType: Constants.BILAN_TYPE_GLOBAL,
            patrimoine: '',
            byPAV: false,
            conteneur: '',
            dateDebut: format(currentDate, 'yyyy-MM-dd'),
            dateFin: format(tomorrowDate, 'yyyy-MM-dd'),
            format: 'CSV'
        }
    }

    const handleSubmit = (values) => {
        dispatch(synthesesActions.fetchSynthese(values))
    }

    return (
        <>
            <PageTitle title={"Synthèse"} icon={<Assessment/>}/>
            {props.loading &&
                <Loader />
            }
            {!props.loading &&
                <Paper className={"synthese-content"}>
                    <Formik
                        initialValues={getInitialValues()}
                        enableReinitialize={true}
                        validationSchema={syntheseFilterValidationSchema}
                        onSubmit={(values, {setSubmitting}) => {
                            handleSubmit(values)
                            setSubmitting(true)
                            setTimeout(() => {
                                setSubmitting(false)
                            }, 1500)
                        }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              /* and other goodies */
                          }) => (
                            <form onSubmit={handleSubmit}>

                                <SyntheseFilter isAdmin={admin} isExploitant={exploitant}
                                                exploitants={props.exploitants} clients={props.clients}
                                                values={values}
                                                errors={errors} handleChange={handleChange}/>

                                <Grid container justifyContent="center" style={{marginTop: '2rem'}}>
                                    <Grid item xs={4}>
                                        <SubmitButton submitting={isSubmitting} label={"Télecharger la synthèse"}/>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>

                </Paper>
            }
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        exploitants: state.users.exploitants,
        clients: state.users.clients,
        patrimoines: state.patrimoines.patrimoines,
        loading: state.users.loading || state.patrimoines.loading,
        error: state.users.err || state.patrimoines.error
    }
}

export default connect(mapStateToProps)(SynthesePage);
