import React from 'react';
import theme from "../../utils/ui/CustomTheme";
import {connect} from 'react-redux';
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import routes from "../routes";
import RequireAuth from "./route/RequireAuth"
import {Route, Routes, unstable_HistoryRouter as HistoryRouter, BrowserRouter} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Layout.css';
import history from '../../utils/history';
import {Box, Container, Drawer, ThemeProvider} from "@mui/material";
import AppDrawer from "./layout/AppDrawer";
import ContentHeader from "./layout/ContentHeader";


function Layout({dispatch, ...props}) {
    return (
        <ThemeProvider theme={theme}>
            <HistoryRouter history={history}>
                {props.loggedIn &&
                    <Box className={"appDrawer"}>
                        <AppDrawer/>
                    </Box>
                }
                <Box className={props.loggedIn ? "app" : "appLogin"}>
                    {/*{props.loggedIn && <Header user={props.currentUser}/>}*/}
                    <ToastContainer position={'top-center'} closeOnClick/>
                    <Routes>
                        {routes.map((route) =>
                            !route.protected ? (
                                <Route key={route.path} {...route} />
                            ) : (

                                <Route
                                    key={route.path}
                                    path={route.path}
                                    element={
                                        <>
                                            <ContentHeader/>
                                            <Container className="pageContainer" maxWidth={'xl'}>
                                                <RequireAuth
                                                    loggedIn={props.loggedIn}
                                                    role={props.currentUser ? props.currentUser.roles : ''}
                                                    restrictedRoles={route.roles}
                                                    redirectTo="/login"
                                                >
                                                    {route.element}
                                                </RequireAuth>
                                            </Container>
                                        </>

                                    }
                                />
                            )
                        )}
                    </Routes>
                    {/*</div>*/}
                    <Footer/>
                </Box>
            </HistoryRouter>
        </ThemeProvider>
    );
}

const mapStateToProps = function (state) {
    return {
        loggedIn: state.authentication.loggedIn,
        currentUser: state.authentication.user
    }
}

export default connect(mapStateToProps)(Layout);
