import React, {useState} from 'react';
import {
    Button, Chip,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import "./PatrimoineTable.css"
import TableHeader from "../layout/table/TableHeader";
import NoDataLabel from "../layout/NoDataLabel";
import {format} from 'date-fns';
import {
    DownloadForOffline,
    FileUpload,
    FileUploadSharp,
    SaveAltSharp,
    TravelExplore,
    UploadFileSharp
} from "@mui/icons-material";
import Constants from "../../../utils/constants";
import {Link} from "react-router-dom/";
import {isAdmin, isExploitant} from "../../../utils/roles";
import CSVHandler from "../../../utils/CSVHandler";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ModalDelete from "../layout/ModalDelete";
import {useNavigate} from "react-router-dom";
import {RRuleHelper} from "../../../utils/RRuleHelper";
import {helper} from "../../../utils/helper";


// Fonction de tri personnalisée pour les libellés
const customLabelSort = (a, b) => {
    const extractNumber = (str) => {
        const match = str.match(/^(\d+)/);
        return match ? parseInt(match[1]) : null;
    };

    const numA = extractNumber(a);
    const numB = extractNumber(b);

    if (numA !== null && numB !== null) {
        if (numA !== numB) return numA - numB;
        return a.localeCompare(b);
    }
    if (numA !== null) return -1;
    if (numB !== null) return 1;
    return a.localeCompare(b);
};

const useCustomSort = (initialOrderBy = 'libelle', initialOrder = 'asc') => {
    const [order, setOrder] = useState(initialOrder);
    const [orderBy, setOrderBy] = useState(initialOrderBy);

    const getItemComparator = (item, comparator) => {
        return item[comparator];
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedData = (data) => {
        return data?.sort((a, b) => {
            let comparison;

            if (orderBy === 'libelle') {
                // Utiliser le tri personnalisé pour la colonne 'libelle'
                comparison = customLabelSort(a.libelle, b.libelle);
            } else {
                // Utiliser le tri standard pour les autres colonnes
                const aValue = getItemComparator(a, orderBy);
                const bValue = getItemComparator(b, orderBy);
                comparison = aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
            }

            return order === 'asc' ? comparison : -comparison;
        });
    };

    return {order, orderBy, handleRequestSort, sortedData};
};


function PatrimoineTable({patrimoines, handleExportCSV, ...props}) {
    const navigate = useNavigate();
    const [label, setLabel] = useState("")
    const [objectToDelete, setObjectToDelete] = useState(null)
    const [open, setOpen] = useState(false);
    const [currentPatrimoine, setCurrentPatrimoine] = useState(null);
    const {order, orderBy, handleRequestSort, sortedData} = useCustomSort();

    // Table head cells
    const headCells = [
        {id: 'libelle', label: 'Libellé'},
        {id: 'next', label: 'Prochain lavage'},
        {id: 'typologieFlux', label: 'Flux'},
        {id: 'typologieConteneurs', label: 'Conteneurs'},
        {id: 'pav', label: 'PAV'},
        {id: 'nbBacs', label: 'Bacs'},
    ];

    const getCreatedLabel = (created) => {
        if (created) return format(new Date(created), 'dd/MM/yyyy');
        return "00/00/0000"
    }

    const handleEditClick = (id) => {
        navigate(Constants.APP_ROUTE_PATRIMOINE_EDIT.replace(':patrimoineId', id))
    }

    const openDeleteConfirmModal = (p) => {
        setCurrentPatrimoine(p)
        setLabel("Etes vous sûr de vouloir supprimer le patrimoine : ")
        setObjectToDelete(p.libelle)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setCurrentPatrimoine(null)
    };

    const handleDelete = () => {
        props.onDelete(currentPatrimoine)
        handleClose()
    }

    return (
        <React.Fragment>
            <TableContainer component={Paper} className={"mt-2"}>
                <Table aria-label="patrimoine table" size="small">
                    <TableHeader headCells={headCells} order={order} orderBy={orderBy}
                                 onRequestSort={handleRequestSort}/>
                    <TableBody>
                        {patrimoines && patrimoines.length === 0 &&
                            <TableRow>
                                <TableCell colSpan={4} align={"center"}>
                                    <NoDataLabel label={"Ce client ne possède pas encore de patrimoine"}/>
                                </TableCell>
                            </TableRow>
                        }
                        {patrimoines && sortedData(patrimoines)?.map((patrimoine) => {
                            return (
                                <React.Fragment key={patrimoine.id}>
                                    <TableRow hover
                                              onClick={() => navigate(Constants.APP_ROUTE_PATRIMOINE_DETAIL.replace(":id", patrimoine.id))}
                                              style={{cursor: 'pointer'}}>
                                        <TableCell size={"small"}>
                                            {patrimoine.libelle}
                                        </TableCell>
                                        <TableCell size={"small"}>
                                            {patrimoine?.nextLavage}
                                        </TableCell>
                                        <TableCell size={"small"}>
                                            {!patrimoine.isUnipatrimoine && patrimoine?.typologieFlux?.map((item, i) => (
                                                <Chip key={i} size={'small'} label={item}
                                                      sx={helper.getColorForDechetType(item)}/>
                                            ))}
                                        </TableCell>
                                        <TableCell size={"small"}>
                                            {!patrimoine.isUnipatrimoine && patrimoine.typologieConteneurs?.map((item, i) => (
                                                <Chip key={i} size={'small'} label={item}
                                                      sx={helper.getColorForDechetType(item)}/>
                                            ))}
                                        </TableCell>
                                        <TableCell>{patrimoine?.nombrePAV}</TableCell>
                                        <TableCell>{patrimoine?.nombreBacs}</TableCell>
                                        <TableCell>
                                            <Grid container justifyContent={"end"} spacing={2}>
                                                <Grid item>
                                                    <Button color="primary" size="small"
                                                            startIcon={<TravelExplore/>}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                navigate(Constants.APP_ROUTE_PATRIMOINE_DETAIL.replace(":id", patrimoine.id))
                                                            }}>
                                                        Détail
                                                    </Button>
                                                </Grid>
                                                {(isAdmin() || isExploitant()) && !patrimoine.isUnipatrimoine &&
                                                    <Grid item>
                                                        <Button color="primary" size="small"
                                                                startIcon={<FileUploadSharp/>}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    navigate(Constants.APP_ROUTE_PATRIMOINE_IMPORT.replace(":id", patrimoine.id))
                                                                }}>
                                                            Import
                                                        </Button>
                                                    </Grid>
                                                }
                                                {(isAdmin() || isExploitant()) && !patrimoine.isUnipatrimoine &&
                                                    <Grid item>
                                                        <Button color="primary" size="small"
                                                                startIcon={<SaveAltSharp/>}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    if (handleExportCSV) {
                                                                        handleExportCSV(patrimoine);
                                                                    }
                                                                }}>
                                                            Export
                                                        </Button>
                                                    </Grid>
                                                }
                                                {isAdmin() &&
                                                    <>
                                                        <Grid item>
                                                            <Button color="secondary" size="small"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handleEditClick(patrimoine.id)
                                                                    }}
                                                                    startIcon={<ModeEditOutlineOutlinedIcon/>}>
                                                            </Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button color="error" size="small"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        openDeleteConfirmModal(patrimoine)
                                                                    }}
                                                                    startIcon={<CloseOutlinedIcon/>}>

                                                            </Button>
                                                        </Grid>
                                                    </>
                                                }

                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <ModalDelete label={label} onClose={handleClose} open={open} objectToDelete={objectToDelete}
                         selectedValue={currentPatrimoine} onDelete={handleDelete}/>
        </React.Fragment>
    )
}

export default PatrimoineTable;
