import {
    deleteHeaders, fetchHeaders, fetchPDFHeaders, patchHeaders, postHeaders,
} from "../../utils/headers";
import Constants from "../../utils/constants";

export const synthesesService = {
    fetchInterventionPDF,
    fetchSynthese
};

function fetchInterventionPDF(interventionId) {
    let url = Constants.API_ROUTE_SYNTHESE_INTERVENTION.replace(':id', interventionId);
    window.open(url + '?token=' + Constants.API_ROUTE_SYNTHESE_INTERVENTION_TOKEN, '_blank');
}

function fetchSynthese(values) {
    const requestOptions = postHeaders(values);

    if (values?.format === "CSV") {
        return fetch(Constants.API_ROUTE_SYNTHESE, requestOptions)
            .then((response) => {
                // Extraire le nom du fichier de l'en-tête Content-Disposition
                const contentDisposition = response.headers.get('Content-Disposition');
                let filename = '';
                if (contentDisposition) {
                    const match = contentDisposition.match(/filename="(.+)"/);
                    if (match && match[1]) filename = match[1];
                }

                return response.text().then((csvData) => {
                    // console.log(csvData)
                    // Créer un Blob à partir des données CSV
                    const blob = new Blob([csvData], {type: 'text/csv'});

                    // Créer un URL pour le Blob
                    const url = URL.createObjectURL(blob);


                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = filename;

                    document.body.appendChild(a);
                    a.click();

                    // Nettoyer
                    document.body.removeChild(a);
                    URL.revokeObjectURL(url);
                });
            });
    } else if (values?.format === "PDF") {
        return fetch(Constants.API_ROUTE_SYNTHESE, requestOptions)
            .then(handleResponse)
            .then((data) => {
                if (data.pdfUrl) {
                    window.open(Constants.API_ASSETS_PDF + "/pdf/" + data.pdfUrl, '_blank');
                }
            });
    }


}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {

            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
