import React, {useEffect, useState} from 'react';
import moment from "moment-timezone";
import {Typography} from "@mui/material";

const LastUpdatedTimer = ({lastUpdated}) => {
    const [timeSinceUpdate, setTimeSinceUpdate] = useState('');

    useEffect(() => {
        const updateTimeSince = () => {
            const lastUpdatedMoment = moment(lastUpdated).tz('Europe/Paris');
            const now = moment().tz('Europe/Paris');

            const duration = moment.duration(now.diff(lastUpdatedMoment));
            const hours = duration.hours();
            const minutes = duration.minutes();

            let result = "";
            if (hours > 0) {
                result += hours + (hours > 1 ? " heures " : " heure ");
            }
            if (minutes > 0) {
                result += minutes + (minutes > 1 ? " minutes" : " minute");
            }

            setTimeSinceUpdate(result || "à l'instant");
        };

        updateTimeSince();
        const interval = setInterval(updateTimeSince, 60000); //chaque minute

        return () => clearInterval(interval);
    }, [lastUpdated]);

    return <Typography variant={"subtitle2"} sx={{display: "inline"}}>Mis à jour il y a {timeSinceUpdate}</Typography>;
};

export default LastUpdatedTimer;