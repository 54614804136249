import React from 'react';
import {Field} from 'formik';
import {
    FormControl, FormHelperText,
    Grid, InputLabel, MenuItem, Select,
    Typography
} from "@mui/material";


function CampaignOperateursField({...props}) {
    const {values, errors, operateurs} = props;


    return (
        <Grid container spacing={1} className={"sectionContainer"}>
            <Grid item xs={12}>
                <Typography variant={"h5"} className={"sectionHeader"}>Sélection des opérateurs</Typography>
            </Grid>

            {/* OPERATEURS FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    <Typography>Sélectionner les opérateurs *</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        name={"operateurs"}
                        value={values.operateurs}>
                        {({form, push, remove}) => (
                            <FormControl fullWidth error={Boolean(errors.operateurs)}>
                                <InputLabel id="label-client">Sélectionner les opérateurs :</InputLabel>
                                <Select
                                    labelId="label-client"
                                    label="Sélectionner les opérateurs :"
                                    variant="outlined"
                                    name="operateurs"
                                    value={values.operateurs}
                                    onChange={(e) => {
                                        form.setFieldValue(
                                            "operateurs",
                                            [e.target.value]
                                        );
                                    }}
                                >
                                    {operateurs && operateurs.map((o) => {
                                        return (
                                            <MenuItem key={o.id} value={o["@id"]}>
                                                {o.libelle ?? o.email}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                                {errors.client &&
                                    String(errors.client) &&
                                    <FormHelperText error={true}>{errors.client}</FormHelperText>}
                            </FormControl>
                        )}
                    </Field>
                </Grid>
            </Grid>

        </Grid>
    );
}

export default CampaignOperateursField;
