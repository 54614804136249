import {usersConstants} from './users.constants';
import {authenticationConstants} from "../authentication/auth.constants";

const initialState = {
    users: null,
    user: null,
    exploitants: null,
    operateurs: null,
    clients: null,
    loading: false,
    error: null
}

export function users(state = initialState, action) {
    switch (action.type) {
        case usersConstants.USERS_FETCH_REQUEST:
        case usersConstants.EXPLOITANTS_FETCH_REQUEST:
        case usersConstants.CLIENTS_FETCH_REQUEST:
        case usersConstants.OPERATEURS_FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case usersConstants.USERS_FETCH_SUCCESS:
            return {
                ...state,
                users: action.users,
                loading: false,
                error: null
            };
        case usersConstants.EXPLOITANTS_FETCH_SUCCESS:
            return {
                ...state,
                exploitants: action.users,
                loading: false,
                error: null
            };
        case usersConstants.CLIENTS_FETCH_SUCCESS:
            return {
                ...state,
                clients: action.clients,
                loading: false,
                error: null
            };
        case usersConstants.OPERATEURS_FETCH_SUCCESS:
            return {
                ...state,
                operateurs: action.operateurs,
                loading: false,
                error: null
            };
        case usersConstants.USERS_FETCH_ONE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case usersConstants.USERS_FETCH_ONE_SUCCESS:
            return {
                ...state,
                user: action.user,
                loading: false,
                error: null
            };
        case usersConstants.USERS_FETCH_ONE_FAILURE:
            return {
                ...state,
                user: null,
                loading: false,
                error: action.error
            };
        case usersConstants.USERS_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case usersConstants.USERS_DELETE_SUCCESS:
        case usersConstants.USERS_CREATE_SUCCESS:
        case usersConstants.USERS_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                users: null,
                user:null,
            };
        case usersConstants.EXPLOITANTS_FETCH_FAILURE:
        case usersConstants.USERS_FETCH_FAILURE:
        case usersConstants.USERS_CREATE_FAILURE:
        case usersConstants.USERS_UPDATE_FAILURE:
        case usersConstants.USERS_DELETE_FAILURE:
        case usersConstants.LOGO_CREATE_FAILURE:
        case usersConstants.CLIENTS_FETCH_FAILURE:
        case usersConstants.OPERATEURS_FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case usersConstants.USER_CLEAR_REQUEST:
            return {
                ...state,
                exploitants: null,
                clients: null,
                user: null,
                users: null,
                error: null,
            }
        case authenticationConstants.LOGOUT:
            return {
                initialState
            }
        default:
            return state
    }
}
