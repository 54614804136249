import React, {useState} from 'react';
import {Field} from 'formik';
import {
    Card, CardContent,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import CampaignMap from "../CampaignMap";
import Button from "@mui/material/Button";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import MapIcon from "@mui/icons-material/Map";
import Constants from "../../../../utils/constants";
import CampaignPatrimoineTable from "./CampaignPatrimoineTable";


function CampaignPatrimoineField({...props}) {
    const {values, errors, patrimoines, handleChange, handleBlur} = props;
    const [mode, setMode] = React.useState(Constants.MODE_LIST)

    const findContainersByIri = (iris) => {
        if (selectedPatrimoine) {
            let containers = [];
            for (let iri of iris) {
                containers.push(selectedPatrimoine?.conteneurs.find((c) => c['@id'] === iri))
            }

            if (values.ordre?.length > 0 && values.ordre.length === containers.length) {
                containers = [...containers].sort((a, b) => {
                    const indexA = values.ordre.indexOf(a.id);
                    const indexB = values.ordre.indexOf(b.id);
                    return indexA - indexB;
                });
            } else {
                containers = [...containers].sort((a, b) => {
                    return parseInt(a.ordreCsv) - parseInt(b.ordreCsv);
                });
            }

            return containers
        }
        return []
    }

    const findPatrimoine = (patrimoineId) => {
        let data = null
        if (patrimoineId) {
            data = patrimoines?.find((p) => p['@id'].indexOf(patrimoineId) > -1);
        }
        return data;
    }

    const [selectedPatrimoine, setSelectedPatrimoine] = useState(findPatrimoine(values.patrimoine))
    const [selectedConteneurs, setSelectedConteneurs] = useState(values.conteneurs.length > 0 ? findContainersByIri(values.conteneurs) : [])

    React.useEffect(() => {
        if (selectedPatrimoine) {
            const updatedSelectedConteneurs = selectedPatrimoine?.conteneurs.filter(c =>
                values.conteneurs.includes(c['@id'])
            );
            setSelectedConteneurs(updatedSelectedConteneurs);
        } else {
            setSelectedConteneurs([]);
        }
    }, [selectedPatrimoine, values.conteneurs]);

    const handlePatrimoineSelected = (patrimoineId, form) => {
        const patrimoineObject = findPatrimoine(patrimoineId)
        if (patrimoineObject) {
            setSelectedPatrimoine(patrimoineObject);
            form.setFieldValue('conteneurs', [])
            setSelectedConteneurs([])
        }
    }

    const handleConteneursSelected = (data, form, field) => {
        const ids = data.map((item) => item['@id']);
        form.setFieldValue(field.name, ids);
        setSelectedConteneurs(data);
    }

    const handleModeChange = (value) => {
        setMode(value)
    }


    return (
        <Grid container spacing={1} className={"sectionContainer"}>
            <Grid item xs={12}>
                <Typography variant={"h5"} className={"sectionHeader"}>Sélection du patrimoine</Typography>
            </Grid>

            {/*PATRIMOINE FIELD*/}
            <Grid item container alignItems={"center"} className={"mt-2"}>
                <Grid item xs={3}>
                    Patrimoine <span className="asterix">*</span>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        name="client"
                        value={values.patrimoine}>
                        {({field, form}) => (
                            <FormControl fullWidth error={Boolean(errors.patrimoine)}>
                                <InputLabel id="label-patrimoine">Sélectionner le patrimoine :</InputLabel>
                                <Select
                                    labelId="label-patrimoine"
                                    label="Sélectionner le patrimoine :"
                                    variant="outlined"
                                    name="patrimoine"
                                    value={values.patrimoine}
                                    onChange={(e) => {
                                        handlePatrimoineSelected(e.target.value, form)
                                        handleChange(e)
                                    }}
                                >
                                    {patrimoines && patrimoines.map((m) => {
                                        return (
                                            <MenuItem key={m.id} value={m["@id"]}>
                                                {m.libelle} - {m.isUnipatrimoine ? (m.nombreDeBacs + " bacs") : (m.conteneurs.length) + " conteneurs"}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                                {errors.patrimoine &&
                                    String(errors.patrimoine) &&
                                    <FormHelperText error={true}>{errors.patrimoine}</FormHelperText>}
                            </FormControl>
                        )
                        }
                    </Field>
                </Grid>
            </Grid>

            <Grid item container className={"mt-2"}>

                {selectedPatrimoine &&
                    <>
                        <Grid item container justifyContent={"center"} spacing={2} className={"mb-2"}>
                            <Grid item>
                                <Button
                                    variant={mode === Constants.MODE_LIST ? "contained" : "outlined"}
                                    color="primary"
                                    startIcon={<FormatListNumberedIcon/>}
                                    onClick={() => handleModeChange(Constants.MODE_LIST)}
                                >
                                    Affichage liste
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant={mode === Constants.MODE_MAP ? "contained" : "outlined"}
                                    color="primary"
                                    startIcon={<MapIcon/>}
                                    onClick={() => handleModeChange(Constants.MODE_MAP)}
                                >
                                    Affichage carte
                                </Button>
                            </Grid>
                        </Grid>
                        <Typography variant={"h5"}>
                            Conteneurs sélectionnés
                            : {selectedPatrimoine?.isUnipatrimoine ? selectedPatrimoine.nombreDeBacs + ' bacs' : selectedConteneurs.length}
                        </Typography>
                        {mode === Constants.MODE_LIST &&
                            <>
                                <Field name={"conteneurs"}>
                                    {({field, form}) => (
                                        <CampaignPatrimoineTable conteneurs={selectedPatrimoine.conteneurs}
                                                                 patrimoine={selectedPatrimoine}
                                                                 selectedConteneurs={selectedConteneurs}
                                                                 onConteneursSelected={(data) => {
                                                                     handleConteneursSelected(data, form, field);
                                                                 }}/>
                                    )}
                                </Field>
                            </>
                        }


                        {mode === Constants.MODE_MAP &&
                            <>
                                <Grid item xs={12}>
                                    {selectedPatrimoine &&
                                        <Typography sx={{fontStyle: 'italic', textAlign: 'center'}}>
                                            Vous pouvez sélectionner les conteneurs à affecter en les entourant
                                            d'un polygone
                                        </Typography>
                                    }
                                </Grid>
                                <Grid item xs={3}>
                                    <Grid sx={{maxHeight: '650px', overflowY: 'scroll'}}>
                                        {selectedConteneurs && selectedConteneurs.map((c, i) => {
                                            return (
                                                <p key={i}>{c?.adresse}</p>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                                <Grid item xs={9}>
                                    <Field name={"conteneurs"}>
                                        {({field, form}) => (
                                            <CampaignMap conteneurs={selectedPatrimoine.conteneurs}
                                                         selectedConteneurs={selectedConteneurs}
                                                         patrimoine={selectedPatrimoine}
                                                         onConteneursSelected={(data) => {
                                                             handleConteneursSelected(data, form, field);
                                                         }}/>
                                        )}
                                    </Field>
                                </Grid>
                            </>
                        }
                    </>
                }
            </Grid>

        </Grid>
    )
        ;
}

export default CampaignPatrimoineField;
