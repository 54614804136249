export const conteneursConstants = {
    CONTENEURS_FETCH_REQUEST: "CONTENEURS_FETCH_REQUEST",
    CONTENEURS_FETCH_SUCCESS: "CONTENEURS_FETCH_SUCCESS",
    CONTENEURS_FETCH_FAILURE: "CONTENEURS_FETCH_FAILURE",

    CONTENEURS_FETCH_ONE_REQUEST: "CONTENEURS_FETCH_ONE_REQUEST",
    CONTENEURS_FETCH_ONE_SUCCESS: "CONTENEURS_FETCH_ONE_SUCCESS",
    CONTENEURS_FETCH_ONE_FAILURE: "CONTENEURS_FETCH_ONE_FAILURE",

    CONTENEURS_CREATE_REQUEST: "CONTENEURS_CREATE_REQUEST",
    CONTENEURS_CREATE_SUCCESS: "CONTENEURS_CREATE_SUCCESS",
    CONTENEURS_CREATE_FAILURE: "CONTENEURS_CREATE_FAILURE",

    CONTENEURS_UPDATE_REQUEST: "CONTENEURS_UPDATE_REQUEST",
    CONTENEURS_UPDATE_SUCCESS: "CONTENEURS_UPDATE_SUCCESS",
    CONTENEURS_UPDATE_FAILURE: "CONTENEURS_UPDATE_FAILURE",

    CONTENEURS_DELETE_REQUEST: "CONTENEURS_DELETE_REQUEST",
    CONTENEURS_DELETE_SUCCESS: "CONTENEURS_DELETE_SUCCESS",
    CONTENEURS_DELETE_FAILURE: "CONTENEURS_DELETE_FAILURE",



    CONTENEURS_CLEAR_REQUEST: "CONTENEURS_CLEAR_REQUEST",

};
