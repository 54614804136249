import {createTheme} from "@mui/material";


const theme = createTheme({
    palette: {
        primary: {
            main: '#213067',
        },
        secondary: {
            main: '#7bb421',
        },
        tertiary: {
            main: '#B1560F'
        }
    },
    typography: {
        fontFamily: `Open Sans, sans-serif`
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                head: {
                    fontWeight: 'bold',
                    color: '#213067'
                },
            },
        },
    },
});

export default theme
