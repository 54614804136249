import * as React from 'react';
import withWidth from "@mui/material/Hidden/withWidth";
import {NavLink, useNavigate} from "react-router-dom";
import {
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Grid, IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Typography
} from "@mui/material";
import './AppDrawer.css';
import {Role} from "../../../utils/roles";
import Constants from "../../../utils/constants";
import {helper} from "../../../utils/helper";
import {
    Article, Dashboard,
    Engineering,
    HelpOutline,
    LocalShipping,
} from "@mui/icons-material";
import packageEnv from '../../../../package.json';
import ReactMarkdown from "react-markdown";
import {useEffect} from "react";
import {ClipboardCheck} from '@styled-icons/bootstrap/ClipboardCheck'
import {Home} from '@styled-icons/feather/Home'
import {User} from '@styled-icons/feather/User'
import {Trash} from '@styled-icons/feather/Trash'
import {Speedometer2} from '@styled-icons/bootstrap/Speedometer2'
import {TaskListLtr} from '@styled-icons/fluentui-system-regular/TaskListLtr'
import {Gear} from '@styled-icons/evil/Gear'
import {Truck} from '@styled-icons/feather/Truck'
import styled from "styled-components";


function AppDrawer({...props}) {

    const [changelogOpen, setChangelogOpen] = React.useState(false)
    const [changelog, setChangelog] = React.useState()
    const navigate = useNavigate();
    const currentUser = helper.getCurrentUser();


    useEffect(() => {
        import('../../../changelog.md')
            .then((module) => {
                return fetch(module.default);
            })
            .then(response => response.text())
            .then(text => setChangelog(text))
            .catch(error => console.error('Erreur lors du chargement du changelog:', error));
    }, []);


    const userHasLogo = () => {
        return currentUser.logo?.hasOwnProperty("filePath");
    }

    const isActive = (route) => {
        return window.location.href.indexOf(route) > -1;
    }

    const getAppVersion = () => {
        let appVersion = "version du " + packageEnv.versionDate + " " + packageEnv.version
        if (import.meta.REACT_APP_ENV === "beta") {
            appVersion += "-BETA"
        }
        return appVersion
    }

    const WhiteTruckIcon = styled(Truck)`color: white;
        width: 24px`;
    const WhiteClipboard = styled(ClipboardCheck)`color: white;
        width: 24px`;
    const WhiteHome = styled(Home)`color: white;
        width: 24px`;
    const WhiteUsers = styled(User)`color: white;
        width: 24px`;
    const WhiteBin = styled(Trash)`color: white;
        width: 24px`;
    const WhiteDashboard = styled(Speedometer2)`color: white;
        width: 24px`;
    const WhiteForms = styled(TaskListLtr)`color: white;
        width: 24px`;
    const WhiteProblems = styled(Gear)`color: white;
        width: 24px`;
    const WhiteLaveuses = styled(Truck)`color: white;
        width: 24px`;

    return (
        <Grid container direction="row" justifyContent="space-between" style={{height: '100vh', padding: '1rem'}}>
            <Grid item xs={12}>
                <img className={"brandDrawer"}
                     src={"/images/logo_marquedeposee_blanc_vert_small.png"}
                     onClick={() => navigate(Constants.APP_ROUTE_HOME)}></img>
                <div className={import.meta.REACT_APP_ENV === "beta" ? "app-version version-beta" : "app-version"}>
                    {getAppVersion()}
                    <IconButton sx={{color: 'white'}} onClick={() => {
                        setChangelogOpen(true)
                    }}>
                        <HelpOutline/>
                    </IconButton>

                </div>
                <Grid item className={"pt-2 appDrawer-menu-container"}>
                    <MenuList>
                        <NavLink
                            to={Constants.APP_ROUTE_HOME}
                            className={"drawerMenuNavlink"}>
                            <MenuItem>
                                <ListItemIcon>
                                    {/*<TravelExplore sx={{color: 'white'}}/>*/}
                                    <WhiteHome/>
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography sx={{color: 'white', display: 'inline'}}>
                                        Accueil
                                    </Typography>
                                </ListItemText>
                            </MenuItem>
                        </NavLink>

                        {(currentUser.roles.includes(Role.ROLE_EXPLOITANT)) &&
                            <NavLink
                                to={Constants.APP_ROUTE_DASHBOARD}
                                className={"drawerMenuNavlink"}>
                                <MenuItem>
                                    <ListItemIcon>
                                        {/*<TravelExplore sx={{color: 'white'}}/>*/}
                                        <WhiteDashboard/>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography sx={{color: 'white', display: 'inline'}}>
                                            Tableau de bord
                                        </Typography>
                                    </ListItemText>
                                </MenuItem>
                            </NavLink>
                        }

                        {(currentUser.roles.includes(Role.ROLE_EXPLOITANT)) &&
                            <NavLink
                                to={Constants.APP_ROUTE_ADMIN_CAMPAIGNS}
                                className={"drawerMenuNavlink"}>
                                <MenuItem>
                                    <ListItemIcon>
                                        {/*<TravelExplore sx={{color: 'white'}}/>*/}
                                        <WhiteTruckIcon/>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography sx={{color: 'white', display: 'inline'}}>
                                            Tournées de lavage
                                        </Typography>
                                    </ListItemText>
                                </MenuItem>
                            </NavLink>
                        }
                        {(currentUser.roles.includes(Role.ROLE_ADMIN) || currentUser.roles.includes(Role.ROLE_EXPLOITANT)
                                || currentUser.roles.includes(Role.ROLE_CLIENT)) &&
                            <NavLink to={Constants.APP_ROUTE_PATRIMOINE} className={"drawerMenuNavlink"}>
                                <MenuItem>
                                    <ListItemIcon>
                                        {/*<FolderDelete sx={{color: 'white'}}/>*/}
                                        <WhiteBin/>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography sx={{color: 'white', display: 'inline'}}>
                                            Gestion des patrimoines
                                        </Typography>
                                    </ListItemText>
                                </MenuItem>
                            </NavLink>
                        }
                        {(currentUser.roles.includes(Role.ROLE_ADMIN) || currentUser.roles.includes(Role.ROLE_EXPLOITANT)
                                || currentUser.roles.includes(Role.ROLE_CLIENT)) &&
                            <NavLink to={Constants.APP_ROUTE_SYNTHESE} className={"drawerMenuNavlink"}>
                                <MenuItem>
                                    <ListItemIcon>
                                        {/*<Assessment sx={{color: 'white'}}/>*/}
                                        <WhiteClipboard/>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography sx={{color: 'white', display: 'inline'}}>
                                            Synthèse
                                        </Typography>
                                    </ListItemText>
                                </MenuItem>
                            </NavLink>
                        }
                        {(currentUser.roles.includes(Role.ROLE_ADMIN) || currentUser.roles.includes(Role.ROLE_EXPLOITANT)) &&
                            <NavLink to={Constants.APP_ROUTE_ADMIN_USERS} className={"drawerMenuNavlink"}>
                                <MenuItem>
                                    {/*<Button size={"small"} className="drawerButton-nav">*/}
                                    {/*    Utilisateurs*/}
                                    {/*</Button>*/}
                                    <ListItemIcon>
                                        {/*<PeopleAlt sx={{color: 'white'}}/>*/}
                                        <WhiteUsers/>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography sx={{color: 'white', display: 'inline'}}>
                                            Gestion des utilisateurs
                                        </Typography>
                                    </ListItemText>
                                </MenuItem>
                            </NavLink>
                        }

                        {currentUser.roles.includes(Role.ROLE_ADMIN) &&
                            <NavLink to={Constants.APP_ROUTE_ADMIN_FORMS} className={"drawerMenuNavlink"}>
                                <MenuItem>
                                    <ListItemIcon>
                                        <WhiteForms/>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography sx={{color: 'white', display: 'inline'}}>
                                            Gestion des formulaires
                                        </Typography>
                                    </ListItemText>
                                </MenuItem>
                            </NavLink>
                        }
                        {currentUser.roles.includes(Role.ROLE_ADMIN) &&
                            <NavLink to={Constants.APP_ROUTE_ADMIN_PROBLEMS} className={"drawerMenuNavlink"}>
                                <MenuItem>
                                    <ListItemIcon>
                                        <WhiteProblems/>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography sx={{color: 'white', display: 'inline'}}>
                                            Gestion des problèmes
                                        </Typography>
                                    </ListItemText>
                                </MenuItem>
                            </NavLink>
                        }
                        {currentUser.roles.includes(Role.ROLE_EXPLOITANT) &&
                            <NavLink to={Constants.APP_ROUTE_ADMIN_LAVEUSES} className={"drawerMenuNavlink"}>
                                <MenuItem>
                                    <ListItemIcon>
                                        <WhiteLaveuses/>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography sx={{color: 'white', display: 'inline'}}>
                                            Gestion des laveuses
                                        </Typography>
                                    </ListItemText>
                                </MenuItem>
                            </NavLink>
                        }

                    </MenuList>

                </Grid>

                <img src={"/images/poubelle-alpha.png"} alt={"poubelle"}
                     className={"filigrane-poubelle"}/>
            </Grid>

            <Dialog
                open={changelogOpen}
                onClose={() => {
                    setChangelogOpen(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Notes de mise à jour
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <ReactMarkdown children={changelog}/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>
        </Grid>
    );
}

export default withWidth()(AppDrawer);
