import React, {useMemo, useState} from 'react';
import {
    Box,
    Button,
    Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle,
    Grid, MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow, TextField,
} from "@mui/material";
import TableHeader from "../layout/table/TableHeader";
import NoDataLabel from "../layout/NoDataLabel";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import EventIcon from "@mui/icons-material/Event";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ModalDelete from "../layout/ModalDelete";
import {useNavigate} from "react-router-dom";
import Constants from "../../../utils/constants";
import {format, parseISO} from "date-fns";
import {RRuleHelper} from "../../../utils/RRuleHelper";
import {Print} from "@mui/icons-material";
import CSVHandler from "../../../utils/CSVHandler";

function CampaignsTable({campaigns, ...props}) {
    const navigate = useNavigate()
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('client');
    const [selectedClient, setSelectedClient] = useState('');
    const [label, setLabel] = useState("")
    const [objectToDelete, setObjectToDelete] = useState(null)
    const [open, setOpen] = useState(false);
    const [dateModalOpen, setDateModalOpen] = useState(false);
    const [currentCampaign, setCurrentCampaign] = useState(null);
    let currentClient = null;

    if (campaigns) {
        campaigns.forEach(campaign => {
            campaign.totalQuantite = campaign.conteneurs?.reduce((total, conteneur) => total + conteneur.quantite, 0) || campaign.patrimoine?.nombreDeBacs || 0;
        });
    }

    // Table head cells
    const headCells = [
        {id: 'client', label: 'Client'},
        {id: 'libelle', label: 'Libellé'},
        {id: 'dateDebut', label: 'Début'},
        {id: 'dateFin', label: 'Fin'},
        // {id: 'form', label: 'Formulaire'},
        // {id: 'nbConteneur', label: 'Nb conteneurs'},
        {id: 'operateur', label: 'Opérateur'},
        {id: 'conteneurs', label: 'Points à laver'},
        {id: 'totalQuantite', label: 'Nombre de conteneurs à laver'},
        {id: 'active', label: 'Active aujourd\'hui'},
    ];


    const openDeleteConfirmModal = (p) => {
        setCurrentCampaign(p)
        setLabel("Etes vous sûr de vouloir supprimer la tournée : ")
        setObjectToDelete(p.libelle)
        setOpen(true);
    };

    const openDateModal = (c) => {
        setCurrentCampaign(c)
        setDateModalOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
        setDateModalOpen(false)
        setCurrentCampaign(null)
    };

    const handleEditClick = (id) => {
        navigate(Constants.APP_ROUTE_ADMIN_CAMPAIGNS_EDIT.replace(':id', id))
    }

    const handleDelete = () => {
        props.onDelete(currentCampaign)
        handleClose()
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const getItemComparator = (item, comparator) => {
        if (comparator === "type") {
            return item.conteneurType
        }
        return item[comparator];
    }

    const clients = useMemo(() => {
        const uniqueClients = new Set(campaigns?.map(campaign => campaign.client?.libelle).filter(Boolean));
        return Array.from(uniqueClients).sort();
    }, [campaigns]);

    const filteredCampaigns = useMemo(() => {
        return campaigns?.filter(campaign =>
            selectedClient === '' || campaign.client?.libelle === selectedClient
        );
    }, [campaigns, selectedClient]);

    const sortedData = useMemo(() => {
        return filteredCampaigns?.sort((a, b) => {
            if (orderBy === "client") {
                const clientA = a.client ? a.client.libelle : '';
                const clientB = b.client ? b.client.libelle : '';
                return order === "asc"
                    ? clientA.localeCompare(clientB)
                    : clientB.localeCompare(clientA);
            }

            const comparison = getItemComparator(a, orderBy) > getItemComparator(b, orderBy) ? 1 : -1;
            return order === 'asc' ? comparison : -comparison;
        });
    }, [filteredCampaigns, order, orderBy]);

    const isActiveToday = (dateDebut, rrule) => {
        if (dateDebut && rrule) {
            return RRuleHelper.isOccurrenceToday(dateDebut, rrule)
        } else {
            return false
        }
    }

    const getOccurences = () => {
        if (currentCampaign && currentCampaign.rrule) {
            let data = RRuleHelper.getNextOccurences(currentCampaign.dateDebut, currentCampaign.rrule)
            if (data.length > 5) {
                data = data.slice(0, 5);
            }
            return data;
        }
        return []
    }

    const handlePrintClick = (campaign) => {
        let nextOccurence = "";
        if (campaign && campaign.rrule) {
            let occurences = RRuleHelper.getNextOccurences(campaign.dateDebut, campaign.rrule)
            if (occurences.length > 0) {
                nextOccurence = occurences[0]
            }
        }
        CSVHandler.exportTourneeToExcel(campaign, nextOccurence);
    }

    return (
        <React.Fragment>
            <Box sx={{mb: 2, mt: 5}}>
                <TextField
                    select
                    label="Filtrer par client"
                    value={selectedClient}
                    onChange={(e) => setSelectedClient(e.target.value)}
                    fullWidth
                >
                    <MenuItem value="">Tous les clients</MenuItem>
                    {clients.map((client) => (
                        <MenuItem key={client} value={client}>
                            {client}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
            <TableContainer component={Paper} className={"mt-2"} sx={{maxHeight: '75vh'}}>
                <Table aria-label="campaigns table" size="small" stickyHeader>
                    <TableHeader headCells={headCells} order={order} orderBy={orderBy}
                                 onRequestSort={handleRequestSort}/>
                    <TableBody>
                        {sortedData?.length === 0 &&
                            <TableRow>
                                <TableCell colSpan={9} align={"center"}>
                                    <NoDataLabel label={"Il n'y a encore aucune tournée enregistrée"}/>
                                </TableCell>
                            </TableRow>
                        }
                        {campaigns && sortedData.map((campaign) => {
                            let addBorder = false;
                            if (currentClient !== campaign.client?.libelle) {
                                if (orderBy === "client") {
                                    addBorder = true;
                                    currentClient = campaign.client?.libelle
                                }
                            }
                            return (
                                <React.Fragment key={campaign.id}>
                                    <TableRow hover className={addBorder ? 'problemTable-border' : ''}
                                              onClick={() => handleEditClick(campaign.id)} style={{cursor: 'pointer'}}>
                                        <TableCell>
                                            <b>{campaign.client?.libelle}</b>
                                        </TableCell>
                                        <TableCell>
                                            {campaign.libelle}
                                        </TableCell>
                                        <TableCell>
                                            {format(parseISO(campaign.dateDebut), 'dd/MM/yyyy')}
                                        </TableCell>
                                        <TableCell>
                                            {RRuleHelper.rruleToText(campaign.rrule)}
                                        </TableCell>
                                        <TableCell>
                                            {campaign.operateurs[0].libelle ?? campaign.operateurs[0].email}
                                        </TableCell>
                                        <TableCell>
                                            {campaign?.nombrePAV || 0}
                                        </TableCell>
                                        <TableCell>
                                            {campaign?.nombreBacs || 0}
                                        </TableCell>
                                        <TableCell>
                                            {isActiveToday(campaign.dateDebut, campaign.rrule)
                                                ?
                                                <Button size="small" disabled
                                                        className="toggle-button-small button-selected-green">Oui</Button> :
                                                <Button disabled
                                                        className="toggle-button-small button-selected-red">Non</Button>}
                                        </TableCell>
                                        <TableCell>
                                            <Grid container justify={"flex-end"} spacing={2}>
                                                <Grid item xs={3}>
                                                    <Button color="primary" size="small"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                openDateModal(campaign)
                                                            }}
                                                            startIcon={<EventIcon/>}>

                                                    </Button>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Button color="primary" size="small"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handlePrintClick(campaign)
                                                            }}
                                                            startIcon={<Print/>}>
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Button color="secondary" size="small"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleEditClick(campaign.id)
                                                            }}
                                                            startIcon={<ModeEditOutlineOutlinedIcon/>}>

                                                    </Button>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Button color="error" size="small"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                openDeleteConfirmModal(campaign)
                                                            }}
                                                            startIcon={<CloseOutlinedIcon/>}>

                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <ModalDelete label={label} onClose={handleClose} open={open} objectToDelete={objectToDelete}
                         selectedValue={currentCampaign} onDelete={handleDelete}/>
            <Dialog
                open={dateModalOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Prochaines dates tournée {currentCampaign?.libelle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {getOccurences().length == 0 && <p>Pas de prochaine échéance</p>}
                        <ul>
                            {getOccurences().map((occurence) => {
                                let date = new Date(occurence);
                                return (
                                    <li>Le {date.toLocaleDateString("fr-FR")}</li>
                                )
                            })}
                        </ul>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()} color={"error"} variant={"contained"}>Fermer</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default CampaignsTable;