import {deleteHeaders, fetchHeaders, patchHeaders, postHeaders} from "../../utils/headers";
import Constants from "../../utils/constants";

export const patrimoinesService = {
    fetchPatrimoine,
    fetchPatrimoineWithInterventions,
    fetchPatrimoinesForClient,
    createPatrimoine,
    updatePatrimoine,
    deletePatrimoine
}

function fetchPatrimoine(id) {
    const requestOptions = fetchHeaders();

    let url = Constants.API_ROUTE_PATRIMOINE.replace(":id", id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then((patrimoine) => {
            return patrimoine;
        });
}

function fetchPatrimoineWithInterventions(id) {
    const requestOptions = fetchHeaders();

    let url = Constants.API_ROUTE_PATRIMOINE_INTERVENTIONS.replace(":id", id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then((patrimoine) => {
            return patrimoine;
        });
}

function fetchPatrimoinesForClient(idClient) {
    const requestOptions = fetchHeaders();
    let url = Constants.API_ROUTE_PATRIMOINES_CLIENT.replace(":id", idClient);
    url = url+"&groups=patrimoineread"

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then((patrimoines) => {
            return patrimoines['hydra:member'];
        });
}

function createPatrimoine(values) {
    const requestOptions = postHeaders(values)

    let url = Constants.API_ROUTE_PATRIMOINES;

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function updatePatrimoine(values, id) {
    const requestOptions = patchHeaders(values)
    let url = Constants.API_ROUTE_PATRIMOINE.replace(':id', id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function deletePatrimoine(id) {
    const requestOptions = deleteHeaders()
    let url = Constants.API_ROUTE_PATRIMOINE.replace(':id', id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {

            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}