import {authenticationConstants} from './auth.constants';

let user = JSON.parse(localStorage.getItem('user'));

/**
 * Handling thing like token exp
 * @param user
 */
const handleInitialState = function (user) {
    let state
    if (user) {
        if (user.exp < (Date.now() / 1000)) {
            state = {loggedIn: false, user: null};
        } else {
            state = {loggedIn: true, user: user};
        }
    } else {
        state = {loggedIn: false, user: null};
    }

    state.isRefreshing = false;
    state.error = false;
    return state
}

const initialState = handleInitialState(user);

export function authentication(state = initialState, action) {
    switch (action.type) {
        case authenticationConstants.LOGIN_REQUEST:
            return {
                ...state,
                loggingIn: true,
                error: false,
                user: action.user
            };
        case authenticationConstants.LOGIN_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                error: false,
                user: action.user
            };
        case authenticationConstants.LOGIN_FAILURE:
        case authenticationConstants.RESET_PASSWORD_FAILURE:
        case authenticationConstants.ASK_RESET_PASSWORD_FAILURE:
            return {
                ...state,
                loggingIn: false,
                loggedIn: false,
                error: action.error,
            };
        case authenticationConstants.LOGOUT:
            return {
                ...state,
                loggingIn: false,
                loggedIn: false,
            };
        default:
            return state
    }
}
